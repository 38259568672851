import produce from 'immer';
import {
  SET_CATEGORY_QUERY,
  SET_CATEGORY_PAGE,
  SET_CATEGORY_LIMIT,
  SET_CATEGORY_SUPERMARKET,
  LOGOUT,
} from '../actions/categoriesList';

const initialState = {
  categoryQuery: '',
  categorySupermarket: [],
  categoryPage: 0,
  categoryLimit: 10,
};

const categoriesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORY_QUERY: {
      const { categoryQuery } = action.payload;
      return produce(state, (draft) => {
        draft.categoryQuery = categoryQuery;
      });
    }
    case SET_CATEGORY_SUPERMARKET: {
      const { categorySupermarket } = action.payload;
      return produce(state, (draft) => {
        draft.categorySupermarket = categorySupermarket;
      });
    }
    case SET_CATEGORY_PAGE: {
      const { categoryPage } = action.payload;
      return produce(state, (draft) => {
        draft.categoryPage = categoryPage;
      });
    }
    case SET_CATEGORY_LIMIT: {
      const { categoryLimit } = action.payload;
      return produce(state, (draft) => {
        draft.categoryLimit = categoryLimit;
      });
    }
    case LOGOUT: {
      return produce(state, (draft) => {
        draft.categoryQuery = initialState.categoryQuery;
        draft.categorySupermarket = initialState.categorySupermarket;
        draft.categoryPage = initialState.categoryPage;
        draft.categoryLimit = initialState.categoryLimit;
      });
    }
    default: {
      return state;
    }
  }
};

export default categoriesListReducer;
