/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

const numberFormat = new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' });

function HistoricGraphEngelProduct({
  productsAssocciated,
}) {
  const productsAsso = productsAssocciated;
  const seriesProducts = [];
  const seriesProductsSale = [];
  for (let i = 0; i < productsAsso.length; i += 1) {
    const nameInfo = `${productsAsso[i].productInfo.brand}: ${productsAsso[i].productInfo.description} (${productsAsso[i].productInfo.supermarket_name})`;
    seriesProducts.push({
      data: productsAsso[i].productSnapshotsSelected,
      name: nameInfo,
      selected: false,
    });
    seriesProductsSale.push({
      data: productsAsso[i].productSnapshotsSale,
      name: nameInfo,
    });
  }
  const options = {
    title: {
      text: 'Gráfico de precio productos asociados',
    },
    legend: {
      enabled: true,
    },
    series: seriesProducts,
    plotOptions: {
      series: {
        marker: {
          enabled: 'auto',
          radius: 3,
        },
        allowPointSelect: true,
      },
    },
    tooltip: {
      split: false,
      formatter() {
        const s = `${Highcharts.dateFormat('%B %d', this.x)}<br />Precio: ${this.y}<br />Unidades: ${this.point.units_for_sale || 1}`;
        if (this.point.discount !== null) {
          s.concat(`<br />Descuento: ${this.point.discount}`);
        }
        return s;
      },
    },
    yAxis: [{
      offset: 20,
      labels: {
        formatter() {
          return numberFormat.format(this.value);
        },
        x: -15,
        style: {
          color: '#000',
          position: 'absolute',
        },
        align: 'left',
      },
    }],
    xAxis: {
      minRange: 1,
      labels: {
        format: '{value:%d %b}',
        rotation: -50,
      },
    },
    chart: {
      height: 500,
      zoomType: '',
    },
    credits: {
      enabled: false,
    },
    rangeSelector: {
      inputDateFormat: '%b %e, %Y',
      allButtonsEnabled: false,
      buttons: [{
        type: 'month',
        title: 'Muestra los datos en una ventana de un mes',
        count: 1,
        text: '1m',
      },
      {
        type: 'month',
        title: 'Muestra los datos en una ventana de tres meses',
        count: 3,
        text: '3m',
      },
      {
        type: 'month',
        title: 'Muestra los datos en una ventana de seis meses',
        count: 6,
        text: '6m',
      },
      {
        type: 'all',
        title: 'Muestra todos los datos disponibles',
        text: 'Todo',
      }],
      buttonTheme: {
        width: 80,
      },
      selected: 1,
    },
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={'stockChart'}
      options={options}
    />
  );
}

HistoricGraphEngelProduct.propTypes = {
  normalPrices: PropTypes.array,
  productsAssocciated: PropTypes.array,
  productsAssocciatedSelected: PropTypes.array,
  selectedPrices: PropTypes.array,
};

export default HistoricGraphEngelProduct;
