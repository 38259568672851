import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../constants/http';

const getAllProducts = async (
  userRole, userId, engelFilter,
) => {
  try {
    const { data } = await axios.get(`/api/products/all?userRole=${userRole}&userId=${userId}&engelFilter=${engelFilter}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    return err.response.data;
  }
};

const getProductsPerPage = async (
  page, limit, searchValue,
  order, supermarkets, category,
  subcategory, brand, userRole, userId, engelFilter,
) => {
  try {
    const { data } = await axios.get(`/api/products?page=${page}&limit=${limit}&searchValue=${searchValue}&order=${order}&supermarket=${supermarkets}&category=${category}&subcategory=${subcategory}&brand=${brand}&userRole=${userRole}&userId=${userId}&engelFilter=${engelFilter}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    return err.response.data;
  }
};

const getProductInfo = async (productId, userId, userRole, edit) => {
  try {
    const { data } = await axios.get(`/api/product/${productId}/info/${userId}/${userRole}?edit=${edit}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getProductSnapshots = async (productId) => {
  try {
    const { data } = await axios.get(`/api/product/${productId}/productSnapshots`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const updateProductScrape = async (productId) => {
  try {
    const { data } = await axios.put(`/api/product/${productId}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putEditProduct = async (newData) => {
  try {
    const { data } = await axios.put(`/api/product/edit/${newData.id}`, newData);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export {
  getAllProducts,
  getProductsPerPage,
  getProductInfo,
  getProductSnapshots,
  updateProductScrape,
  putEditProduct,
};
