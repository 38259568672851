import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Redirect } from 'react-router';
import { putEditCategory, deleteCategory } from '../../../requests/api/categories';

import useStyles from './styles';

function CategoryEditForm({
  className,
  supermarkets,
  category,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [open, setOpen] = React.useState(false);

  if (submitSuccess) {
    return <Redirect to="/config/categories" />;
  }

  const handleDelete = async () => {
    setOpen(false);
    try {
      const data = {
        id: category.id,
      };
      const response = await deleteCategory(data);
      if (response.success) {
        setSubmitSuccess(true);
        enqueueSnackbar(response.message, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(response.message, {
          variant: 'warning',
          action: <Button href="/config/categories">Volver atrás</Button>,
        });
      }
    } catch (error) {
      setSubmitSuccess(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Formik
      initialValues={{
        name: category.name || '',
        supermarket: category.supermarket_id || '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        supermarket: Yup.number().required('Debe seleccionar un supermercado').positive().integer(),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            id: category.id,
            name: values.name,
            supermarket_id: values.supermarket,
          };

          const response = await putEditCategory(data);

          if (response.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar(response.message, {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: response.message });
            enqueueSnackbar(response.message, {
              variant: 'warning',
              action: <Button href="/config/categories">Volver atrás</Button>,
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Nombre"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  { supermarkets.length > 0
                    ? <TextField
                        fullWidth
                        label="Supermercado"
                        name="supermarket"
                        onChange={handleChange}
                        select
                        value={values.supermarket}
                        variant="outlined"
                        required
                      >
                        {supermarkets.map((supermarket) => (
                          <MenuItem
                            value={supermarket.id}
                            key={supermarket.id}
                          >
                            {supermarket.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    : <CircularProgress style={{ color: 'var(--brand-blue' }} />
                  }
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={2}
                >
                  <Box mt={1} xs={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Guardar
                  </Button>
                </Box>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={2}
                >
                  <Box mt={1} xs={2}>
                  <Button
                      style={{
                        backgroundColor: '#f44336',
                        color: 'white',
                      }}
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={handleClickOpen}
                  >
                    Eliminar
                  </Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {'¿Desea eliminar esta categoría?'}
                    </DialogTitle>
                    <DialogActions>
                      <Button onClick={handleDelete}>Si</Button>
                      <Button onClick={handleClose}>No</Button>
                    </DialogActions>
                  </Dialog>
                </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

CategoryEditForm.propTypes = {
  className: PropTypes.string,
  supermarkets: PropTypes.array,
  category: PropTypes.object,
};

export default CategoryEditForm;
