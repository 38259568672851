import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import ReportCopyComparativeForm from './ReportCopyComparativeForm';
import { getReportDetail } from '../../../requests/api/reports';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useStyles from './styles';

function ReportCopyComparativeView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [report, setReport] = useState();
  const [dataCompProducts, setDataCompProducts] = useState({});
  const [engelProductSelected, setEngelProductSelected] = useState();
  const [totalComparation, setTotalComparation] = useState([]);
  const [comparations, setComparations] = useState(0);

  useEffect(() => {
    const getReport = async () => {
      const res = await getReportDetail(props.match.params.id);
      const response = res.data;
      if (isMountedRef.current) {
        const reportObject = response.data;
        const reportContent = reportObject.content;
        const content = [];
        const contentObject = JSON.parse(reportContent);
        const engelSelected = contentObject.map((product) => product.engel_product);
        const comps = contentObject.length;
        setEngelProductSelected(engelSelected);
        setReport(reportObject);
        setDataCompProducts(content);
        const compArray = [];
        for (let i = 0; i < comps; i += 1) { compArray.push(i); }
        setTotalComparation(compArray);
        setComparations(comps);
      }
    };
    getReport();
  }, []);

  if (!report) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="FH Engel | Crear Reporte"
    >
      <Container maxWidth={false}>
        <Header />
        <ReportCopyComparativeForm
          report={report}
          dataCompProducts={dataCompProducts}
          engelProductSelected={engelProductSelected}
          totalComparation={totalComparation}
          setDataCompProducts={setDataCompProducts}
          setEngelProductSelected={setEngelProductSelected}
          setTotalComparation={setTotalComparation}
          comparations={comparations}
          setComparations={setComparations}
        />
      </Container>
    </Page>
  );
}
ReportCopyComparativeView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(ReportCopyComparativeView);
