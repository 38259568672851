import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../constants/http';

const getAllEngelProducts = async (
  userRole, userId, engelFilter,
) => {
  try {
    const { data } = await axios.get(`/api/engelproducts/all?userRole=${userRole}&userId=${userId}&engelFilter=${engelFilter}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    return err.response.data;
  }
};

const getEngelProductsPerPage = async (
  page, limit, searchValue, order, userRole,
) => {
  try {
    const { data } = await axios.get(`/api/engelproducts?page=${page}&limit=${limit}&searchValue=${searchValue}&order=${order}&userRole=${userRole}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    return err.response.data;
  }
};

const getEngelProductInfo = async (productId, userId, userRole) => {
  try {
    const { data } = await axios.get(`/api/engelproduct/${productId}/info/${userId}/${userRole}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putEditEngelProduct = async (newData) => {
  try {
    const { data } = await axios.put(`/api/engelproduct/edit/${newData.id}`, newData);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postCreateEngelProduct = async ({
  description, sku, unitsPerProduct, productIds,
}) => {
  try {
    const response = await axios.post('/api/engelproduct/create', {
      description, sku, units_per_product: unitsPerProduct, productIds,
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getProductsByEngelProductId = async (productId, userId, userRole) => {
  try {
    const { data } = await axios.get(`/api/engelproduct/${productId}/associated/${userId}/${userRole}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteEngelProduct = async (newData) => {
  try {
    const { data } = await axios.delete(`/api/engelproduct/${newData.id}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export {
  getAllEngelProducts,
  getEngelProductsPerPage,
  getEngelProductInfo,
  putEditEngelProduct,
  postCreateEngelProduct,
  getProductsByEngelProductId,
  deleteEngelProduct,
};
