import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { getProductsPerPage, getAllProducts } from '../../../requests/api/products';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import EngelProductEditForm from './EngelProductEditForm';
import { getEngelProductInfo, getProductsByEngelProductId } from '../../../requests/api/engelproducts';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import TabSelection from './TabSelection';
import EngelProductsAssociation from './ProductsAssociationEdit';
import { getAllBrands } from '../../../requests/api/brands';
import { getAllSupermarkets } from '../../../requests/api/supermarkets';

import useStyles from './styles';
import alphabeticSort from '../../../utils/alphabeticSort';

const sortOptions = [
  {
    value: 'ALPH',
    label: 'Alfabético',
  },
  {
    value: 'DESC',
    label: 'Más nuevo primero',
  },
  {
    value: 'ASC',
    label: 'Más antiguo primero',
  },
];
function EngelProductEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [engelProduct, setEngelProduct] = useState();
  const [brands, setBrands] = useState([]);
  const [supermarkets, setSupermarkets] = useState([]);
  const [productsAssociatedEngel, setProductsAssociatedEngel] = useState([]);
  const [newEngelProduct, setNewEngelProduct] = useState();
  const account = useSelector((state) => state.account);
  const [engelProductPageEdit, setEngelProductPageEdit] = useState(0);
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [numberProducts, setNumberProducts] = useState(0);
  const [limit, setLimitProduct] = useState(5);
  const [page, setPageProduct] = useState(0);
  const [searchValue, setSearchValueProduct] = useState('');
  const [searchBrand, setBrandProduct] = useState([]);
  const [searchSupermarkets, setSupermarketProduct] = useState([]);
  const sort = sortOptions[0].value;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getEngelProductAssociated = async () => {
      const res = await getProductsByEngelProductId(props.match.params.id,
        account.user.id, account.user.role);
      const response = res.data;
      if (isMountedRef.current) {
        setProductsAssociatedEngel(response.data);
      }
    };
    getEngelProductAssociated();
  }, []);

  useEffect(() => {
    const getEngelProduct = async () => {
      const res = await getEngelProductInfo(props.match.params.id,
        account.user.id, account.user.role);
      const response = res.data;
      if (isMountedRef.current) {
        setEngelProduct(response.data);
      }
    };
    getEngelProduct();
  }, []);

  const getProducts = async () => {
    const userRole = account.user.role;
    const userId = account.user.id;
    const brandIdes = searchBrand.map((val) => val.id);
    const supermarketsIdes = searchSupermarkets.map((val) => val.id);
    const response = await getProductsPerPage(
      page,
      limit,
      searchValue,
      sort,
      supermarketsIdes, '',
      '', brandIdes,
      userRole,
      userId,
      props.match.params.id,
    );
    if (response.success) {
      const data = await response?.data?.data;
      if (isMountedRef.current) {
        setProducts(data.products);
        setNumberProducts(data.totalProducts);
      }
    } else {
      enqueueSnackbar('Error al cargar la información de los productos.', {
        variant: 'error',
      });
    }
  };

  const getAllDatabaseProducts = async () => {
    const userRole = account.user.role;
    const userId = account.user.id;
    const response = await getAllProducts(
      userRole,
      userId,
      props.match.params.id,
    );
    if (response.success) {
      const data = await response?.data?.data;
      if (isMountedRef.current) {
        setAllProducts(data.products);
      }
    } else {
      enqueueSnackbar('Error al cargar la información de todos los productos.', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    try {
      const getBrands = async () => {
        const response = await getAllBrands();
        if (response.success) {
          const data = await response?.data?.data;
          setBrands(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getBrands();
    } catch {
      setBrands([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getSupermarkets = async () => {
        const response = await getAllSupermarkets();
        if (response.success) {
          const data = await response?.data?.data;
          setSupermarkets(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las marcas.', {
            variant: 'error',
          });
        }
      };
      getSupermarkets();
    } catch {
      setSupermarkets([]);
    }
  }, []);

  useEffect(() => {
    try {
      getProducts();
      getAllDatabaseProducts();
    } catch {
      setProducts([]);
      setNumberProducts(0);
    }
  }, [props]);

  useEffect(() => {
    getProducts();
  }, [page, limit, searchValue, searchBrand, searchSupermarkets]);

  if (!engelProduct) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="FH Engel | Editar Producto"
    >
      <Container maxWidth={false}>
        <Header />
        <TabSelection
          engelProductPageEdit={engelProductPageEdit}
        />
        {engelProductPageEdit === 0 && (
          <EngelProductEditForm
            engelProduct={engelProduct}
            setEngelProductPageEdit={setEngelProductPageEdit}
            newEngelProduct={newEngelProduct}
            setNewEngelProduct={setNewEngelProduct}
          />
        )}
        {engelProductPageEdit === 1 && (
          <EngelProductsAssociation
            products={products}
            allProducts={allProducts}
            brands={brands}
            supermarkets={supermarkets}
            searchBrand={searchBrand}
            setEngelProductBrand={setBrandProduct}
            setEngelProductSupermarket={setSupermarketProduct}
            productsAssociatedEngel={productsAssociatedEngel}
            totalProducts={numberProducts}
            page={page}
            limit={limit}
            setPage={setPageProduct}
            setLimit={setLimitProduct}
            setSearchValueProduct={setSearchValueProduct}
            setEngelProductPageEdit={setEngelProductPageEdit}
            newEngelProduct={newEngelProduct}
          />
        )}
      </Container>
    </Page>
  );
}

EngelProductEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(EngelProductEditView);
