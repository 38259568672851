import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';

import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';

import { Redirect } from 'react-router-dom';

// eslint-disable-next-line max-len
import { editCategoryUserRoles, getCategoryUserRoles } from '../../../../requests/api/users';

import useStyles from './styles';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';

function CategoryUserRolesForm({
  className,
  user,
  stores,
  subcategories,
  supermarkets,
  categories,
  setCategories,
  originalCategories,
  count,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [currentCategories, setCurrentCategories] = useState([{ id: 0, name: 'Todas' }].concat(categories));
  const [categoriesSelected, setCategoriesSelected] = useState([]);

  const SUPERMARKETS = ['Jumbo', 'Lider', 'Unimarc'];

  useEffect(() => {
    setCurrentCategories(categories);
  }, [categories]);

  useEffect(() => {
    try {
      const getActualCategoryUserRoles = async () => {
        const res = await getCategoryUserRoles(user.id);
        const response = res.data;
        if (isMountedRef.current) {
          const idCategory = response.data.rows.map((value) => value.category_id);
          const selectedCategory = [];
          for (let i = 0; i < categories.length; i += 1) {
            if (idCategory.includes(categories[i].id)) {
              selectedCategory.push(categories[i]);
            }
          }
          setCategoriesSelected(selectedCategory);
        }
      };
      getActualCategoryUserRoles();
    } catch {
      setCategoriesSelected([]);
    }
  }, [categories]);

  const handleChange = (type, newVal) => {
    setCategoriesSelected(newVal);
  };

  if (submitSuccess) {
    return <Redirect to="/administracion/users" />;
  }

  return (
    <Formik
      initialValues={{
        name: user.name || '',
        lastname: user.lastname || '',
        role: user.role || '',
        email: user.email || '',
        active: user.active || false,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255).required('Debe ingresar un nombre'),
        lastname: Yup.string().max(255).required('Debe ingresar un apellido'),
        email: Yup.string().email('Email inválido').required('Debe ingresar email'),
        active: Yup.boolean().required('Debe seleccionar un estado'),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            user_id: user.id,
            categories: categoriesSelected,
          };

          const response = await editCategoryUserRoles(user.id, data);

          const res = response.data;
          if (res.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar('Cambios guardados', {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: res.data.errorMessage });
            enqueueSnackbar(res.data.errorMessage, {
              variant: 'warning',
              action: <Button href="/administracion/users">Volver a usuarios</Button>,
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
            <Grid
                container
                spacing={3}
              >
              <Grid item xs={6} md={12} >
                {currentCategories.length > 0 && <Autocomplete
                  id="categories-autocomplete"
                  options={currentCategories}
                  multiple
                  name='subcategory'
                  value={categoriesSelected}
                  getOptionLabel={(option) => `${option.name} - ${SUPERMARKETS[option.supermarket_id - 1]}`}
                  onChange={(e, v) => handleChange('category', v)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.queryField}
                      label='Categorías permitidas'
                      variant='outlined'
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name} - {SUPERMARKETS[option.supermarket_id - 1]}
                    </li>
                  )}
                />
                }
              </Grid>
              </Grid>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Actualizar subcategorias permitidas
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

CategoryUserRolesForm.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  stores: PropTypes.array,
  subcategories: PropTypes.array,
  count: PropTypes.number,
  supermarkets: PropTypes.array,
  originalCategories: PropTypes.array,
  categories: PropTypes.array,
  setCategories: PropTypes.func,
};

export default CategoryUserRolesForm;
