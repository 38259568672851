import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
} from '@material-ui/core';

import { useSelector } from 'react-redux';

// import { useSelector } from 'react-redux';
import useStyles from './styles';

function TabSelection() {
  const classes = useStyles();
  const {
    engelProductPage, engelProductAllProductsCheck,
  } = useSelector((state) => state.engelProduct);

  const handleStyles = (sourcePage, source) => {
    if ((engelProductPage === 0 && source) || (engelProductPage !== sourcePage && source)) {
      return classes.tabSelectionItemDisabled;
    }
    if (engelProductPage === sourcePage && !source) {
      return classes.tabSelectionItemSelected;
    }
    return classes.tabSelectionItem;
  };
  return (
    <Paper
      elevation={3}
      className={classes.tabSelectionPaper}
    >
      <Box
          // eslint-disable-next-line max-len
          className={engelProductPage === 0 ? classes.tabSelectionItemSelected : classes.tabSelectionItem}
      >
        <Typography
          variant="h4"
          className={classes.tabSelectionItemSelectedText}
        >
          Información general
        </Typography>
      </Box>
      <Box
        className={handleStyles(1, engelProductAllProductsCheck)}
      >
        <Typography
          variant="h4"
        >
          Asociar productos
        </Typography>
      </Box>
    </Paper>
  );
}

TabSelection.propTypes = {
  className: PropTypes.string,
};

export default TabSelection;
