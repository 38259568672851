import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    marginBottom: '1%',
  },
  cardNewComparation: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginBottom: '1%',
  },
  subtitle: {
    marginBottom: '1%',
    marginTop: '2%',
  },
  checkbox: {
    marginRight: '7%',
  },
  ction: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
    width: '100%',
  },
  actionIcon: {
    paddingRight: theme.spacing(1),
    height: '100%',
  },
  searchAction: {
    width: '50px',
    height: '100%',
    borderRadius: '80%',
  },
  options: {
    justifySelf: 'end',
    width: '60%',
  },
  boxDownTable: {
    display: 'flex',
    flexDirection: 'row',
  },
  createDialog: {
    width: '100%',
    maxWidth: '470px',
    maxHeight: '500px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    boxSizing: 'border-box',
    // width: '670px',
    alignSelf: 'center',
    height: '625px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',

  },
  createDialogSaveReport: {
    width: '100%',
    maxWidth: '470px',
    maxHeight: '450px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    boxSizing: 'border-box',
    // width: '670px',
    alignSelf: 'center',
    height: '450px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',

  },
  createDialogTitle: {
    align: 'left',
    color: '#24335E',
    fontSize: '24px',
    fontStyle: 'normal',
    lineHeight: '29px',
    marginBottom: '30px',
    marginTop: '10px',
  },
  createDialogOptions: {
    height: '115px',
    width: '510px',
  },
  createDialogButtons: {
    height: '55px',
    width: '240px',
    background: '#24335E',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    textAlign: 'center',
    color: 'white',
  },
  createDialogTexfield: {
    marginBottom: '5%',
    marginLeft: '7%',
    width: '510px',
  },
}));

export default useStyles;
