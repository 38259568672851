/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useHistory /* Redirect */ } from 'react-router';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import {
  Search as SearchIcon,
  XCircle as XCircleIcon,
  Edit as EditIcon,
  Copy as CopyIcon,
  Download as DownloadIcon,
  Trash as TrashIcon,
  PlusSquare as PlusSquareIcon,
} from 'react-feather';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import { deleteComparativeReport } from '../../../requests/api/reports';

import {
  setReportQuery,
  setReportPage,
  setReportLimit,
} from '../../../actions/reportsList';

import useStyles from './styles';

import {
  exportReportComparation,
  exportReportIndex,
} from '../../../requests/api/reportinstances';

function Results({
  className,
  reports,
  count,
  sort,
  setSort,
  sortOptions,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [currentQuery, setCurrentQuery] = useState('');
  const [currentReports, setCurrentReports] = useState(reports);
  const [reportType, setReportType] = useState('');
  const [loadingExport, setLoadingExport] = useState(false);
  const [downloadFrom, setDownloadFrom] = useState('');
  const [downloadTo, setDownloadTo] = useState('');
  const [downloadPeriod, setDownloadPeriod] = useState('');

  const [selectedReports, setSelectedReports] = useState([]);
  const [reportToDownload, setReportToDownload] = useState('');
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [rawReport, setRawReport] = React.useState(false);
  const [openDownload, setOpenDownload] = React.useState(false);
  const [availableDelete, setAvalaibleDelete] = useState(false);
  const [submitDeleteSuccess, setSubmitDeleteSuccess] = useState(false);
  const account = useSelector((state) => state.account);
  const history = useHistory();

  const {
    reportPage,
    reportLimit,
  } = useSelector((state) => state.reportsList);

  useEffect(() => { setCurrentReports(reports); }, [reports]);

  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  const handleQueryChange = (event) => {
    event.persist();
    setCurrentQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    dispatch(setReportPage(newPage));
  };

  const handleLimitChange = (event) => {
    dispatch(setReportLimit(event.target.value));
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const handleOpenDownload = (report) => {
    setRawReport(false);
    setReportToDownload(report);
    setDownloadFrom('');
    setDownloadTo('');
    setDownloadPeriod('');
    setOpenDownload(true);
  };

  const handleOpenRawDownload = (report) => {
    setReportToDownload(report);
    setDownloadFrom('');
    setDownloadTo('');
    setDownloadPeriod('');
    setOpenDownload(true);
    setRawReport(true);
  };

  const handleCloseDownload = () => {
    setOpenDownload(false);
    setRawReport(false);
  };

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSelectReportType = async (value) => {
    const type = value.props.value;
    setReportType(type);
  };

  const handleExport = async () => {
    setLoadingExport(true);
    enqueueSnackbar('Los datos se están preparando para ser descargados', {
      variant: 'success',
      autoHideDuration: 5000,
    });
    let data;
    if (reportToDownload.type === 'Comparativo') {
      if (rawReport) {
        const dataExport = await exportReportComparation(reportToDownload.id, downloadFrom, downloadTo, downloadPeriod, true);
        data = dataExport.data;
      } else {
        const dataExport = await exportReportComparation(reportToDownload.id, downloadFrom, downloadTo, downloadPeriod, null);
        data = dataExport.data;
      }
    } else if (reportToDownload.type !== 'Comparativo') {
      if (rawReport) {
        const dataExport = await exportReportIndex(reportToDownload.id, downloadFrom, downloadTo, downloadPeriod, true);
        data = dataExport.data;
      } else {
        const dataExport = await exportReportIndex(reportToDownload.id, downloadFrom, downloadTo, downloadPeriod, null);
        data = dataExport.data;
      }
    }
    if (data?.data?.file) {
      const fileData = data.data.file;
      const { fileName } = data.data;
      const uri = `data:${'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'};charset=utf-8${';base64'},${fileData}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = uri;
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
    setLoadingExport(false);
  };

  const handleDownloadFrom = (event) => {
    setDownloadFrom(event.target.value);
  };

  const handleDownloadTo = (event) => {
    setDownloadTo(event.target.value);
  };

  const handleDownloadPeriod = (event) => {
    setDownloadPeriod(event.target.value);
  };

  const frequencies = {
    1: 'Diaria',
    7: 'Semanal',
    30: 'Mensual',
  };

  const ranges = {
    7: 'Una semana',
  };

  const handleSelectOneReport = (event, report) => {
    if (!selectedReports.includes(report.id)) {
      setSelectedReports((prevSelected) => [...prevSelected, report.id]);
      setAvalaibleDelete(true);
    } else {
      setSelectedReports((prevSelected) => prevSelected.filter((id) => id !== report.id));
      if (selectedReports.length <= 1) {
        setAvalaibleDelete(false);
      }
    }
  };

  const handleCreate = async () => {
    setOpenCreate(false);
    if (reportType === 'Comparativo') {
      history.push('/administracion/reportes/crear/comparativo');
    } else if (reportType === 'Index') {
      history.push('/administracion/reportes/crear/index');
    } else {
      history.push('/administracion/reportes/crear/historico');
    }
  };

  const handleDelete = async () => {
    const response = await deleteComparativeReport(selectedReports);
    if (response.success) {
      setSubmitDeleteSuccess(true);
      enqueueSnackbar(response.message, {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(response.message, {
        variant: 'warning',
        action: <Button href="/administracion/reportes">Volver atrás</Button>,
      });
    }
    setSubmitDeleteSuccess(true);
    enqueueSnackbar('Reportes eliminados correctamente', {
      variant: 'success',
    });
    setOpenDelete(false);
  };

  if (submitDeleteSuccess) {
    window.location.reload();
  }

  return (
    <Card
      className={clsx(classes.rootResults, className)}
      {...rest}
    >
      <Divider />
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
      >
        <Grid container spacing={1} >
          <Grid item xs={2} md={4}>
            <TextField
              className={classes.queryField}
              onChange={handleQueryChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
                endAdornment: currentQuery ? (
                  <IconButton size="small" onClick={() => {
                    setCurrentQuery('');
                    dispatch(setReportQuery(''));
                  }}>
                    <XCircleIcon />
                  </IconButton>
                ) : null,
              }}
              placeholder="Buscar por sku o por descripción"
              value={currentQuery}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={0.5} md={6} >
            <Button
              color="secondary"
              variant="contained"
              className={classes.searchAction}
              onClick={() => dispatch(setReportQuery(currentQuery))}
            >
              <SvgIcon >
                <SearchIcon />
              </SvgIcon>
            </Button>
          </Grid>
          <Grid item xs={3} md={2}>
            <TextField
              className={classes.queryField}
              label="Orden"
              name="order"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={sort}
              variant="outlined"
            >
              {sortOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Box>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                </TableCell>
                <TableCell align='center'>
                  Título del reporte
                </TableCell>
                <TableCell align='center'>
                  Fecha de creación
                </TableCell>
                <TableCell align='center'>
                  Tipo de reporte
                </TableCell>
                <TableCell align='center'>
                  Frecuencia
                </TableCell>
                <TableCell align='center'>
                  Rango
                </TableCell>
                <TableCell align='center'>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentReports.map((report) => (
                <TableRow
                  hover
                  key={report.id}
                >
                  <TableCell>
                    <Checkbox
                      onChange={(event) => handleSelectOneReport(event, report)}
                      disabled={!(account.user.id === report.user_id)}
                    />
                  </TableCell>
                  <TableCell align='center'>{report.title}</TableCell>
                  <TableCell align='center'>{report.created_at.split('T')[0]}</TableCell>
                  <TableCell align='center'>{report.type}</TableCell>
                  <TableCell align='center'>{frequencies[report.frequency]}</TableCell>
                  <TableCell align='center'>{report.frequency ? ranges[report.range] : ''}</TableCell>
                  <TableCell align='center' width={'13%'}>
                    {account.user.role === 'admin' && report.type === 'Comparativo'
                      ? <Tooltip title="Editar Reporte Comparativo" disabled={!(account.user.id === report.user_id)}>
                        <IconButton
                          component={RouterLink}
                          to={`/administracion/reportes/${report.id}/editar/comparativo`}
                        >
                          <SvgIcon fontSize="small">
                            <EditIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                      : null}
                    {account.user.role === 'admin' && report.type === 'Index'
                      ? <Tooltip title="Editar Reporte Index" disabled={!(account.user.id === report.user_id)}>
                        <IconButton
                          component={RouterLink}
                          to={`/administracion/reportes/${report.id}/editar/index`}
                        >
                          <SvgIcon fontSize="small">
                            <EditIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                      : null}
                    <Tooltip title="Copiar reporte">
                      <IconButton
                        component={RouterLink}
                        to={`/administracion/reportes/${report.id}/copiar/comparativo`}
                        disabled
                      >
                        <SvgIcon fontSize="small">
                          <CopyIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Descargar reporte">
                      <IconButton
                        color="secondary"
                        disabled={loadingExport}
                        variant="contained"
                        onClick={() => handleOpenDownload(report)}
                      >
                        <SvgIcon fontSize="small">
                          <DownloadIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Descargar reporte en bruto">
                      <IconButton
                        color="secondary"
                        disabled={loadingExport}
                        variant="contained"
                        onClick={() => handleOpenRawDownload(report)}
                      >
                        <SvgIcon fontSize="small">
                          <DownloadIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        mt={2}
        className={classes.boxDownTable}
        display='flex'
      >
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={(reportPage > 0 && currentReports.length < reportLimit) ? 0 : reportPage}
          rowsPerPage={reportLimit}
          rowsPerPageOptions={[5, 10, 25, 50]}
          labelRowsPerPage="Filas por página"
        />
        <Box display='flex' paddingLeft='39%'>
          <Box
            mr={2}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleClickOpenDelete}
              disabled={!availableDelete}
            >
              <SvgIcon fontSize="medium" className={classes.actionIcon}>
                <TrashIcon />
              </SvgIcon>
              Eliminar reportes
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.orangeButton}
              onClick={handleOpenCreate}
            >
              <SvgIcon fontSize="medium" className={classes.actionIcon}>
                <PlusSquareIcon />
              </SvgIcon>
              Crear Reporte
            </Button>
            <Dialog
              open={openDelete}
              onClose={handleCloseDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{ paper: classes.createDialogDelete }}
            >
              <DialogTitle disableTypography>
                <Typography className={classes.createDialogTitle}>Eliminar reportes</Typography>
              </DialogTitle>
              <Typography
                className={classes.deleteDialogTypeBold}
              >
                ¿Estás seguro que quieres eliminar estos reportes?
              </Typography>
              <Typography className={classes.deleteDialogType} >
                Una vez eliminados, no podrás recuperarlos.
              </Typography>
              <DialogActions>
                <Button onClick={handleDelete} className={classes.createDialogButtons}>Eliminar</Button>
                <Button onClick={handleCloseDelete} className={classes.createDialogButtons}>Cancelar</Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openCreate}
              onClose={handleCloseCreate}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{ paper: classes.createDialog }}
              disableTypography
            >
              <DialogTitle disableTypography>
                <Typography className={classes.createDialogTitle}>Crear Reporte</Typography>
              </DialogTitle>
              <Grid
                item
                className={classes.createDialogOptions}
              >
                <TextField
                  fullWidth
                  label="Tipo de reporte"
                  name="type"
                  value={reportType}
                  onChange={(e, v) => handleSelectReportType(v)}
                  select
                  variant="outlined"
                  required
                >
                  <MenuItem
                    value="Comparativo"
                  >
                    Comparativo
                  </MenuItem>
                  <MenuItem
                    value="Index"
                  >
                    Index de precio
                  </MenuItem>
                </TextField>
              </Grid>
              <DialogActions>
                <Button className={classes.createDialogButtons} onClick={handleCloseCreate}>Cancelar</Button>
                <Button className={classes.createDialogButtons} onClick={handleCreate}>Siguiente</Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDownload}
              onClose={handleCloseDownload}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{ paper: classes.createDialogDownload }}
              disableTypography
            >
              <DialogTitle disableTypography>
                <Typography className={classes.createDialogTitle}>Descargar reporte</Typography>
              </DialogTitle>
              <Grid
                className={classes.createDialogOptions}
              >
                <Grid
                  className={classes.createDialogTexfieldDownload}
                >
                  <TextField
                    name="type"
                    label='Considerar desde'
                    value={downloadFrom}
                    onChange={handleDownloadFrom}
                    type='date'
                    variant="outlined"
                    required
                    className={classes.createDialogTexfieldDownload}
                    InputLabelProps={{ shrink: true }}
                  >
                  </TextField>
                </Grid>
                <Grid
                  className={classes.createDialogTexfieldDownload}
                >
                  <TextField
                    fullWidth
                    name="type"
                    label='Hasta'
                    value={downloadTo}
                    onChange={handleDownloadTo}
                    type='date'
                    variant="outlined"
                    required
                    className={classes.createDialogTexfieldDownload}
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </Grid>
                <Grid
                  className={classes.createDialogTexfieldDownload}
                >
                  <TextField
                    fullWidth
                    label="Período"
                    name="type"
                    value={downloadPeriod}
                    onChange={handleDownloadPeriod}
                    select
                    variant="outlined"
                    required
                  >
                    <MenuItem
                      value="7"
                    >
                      Semanal
                    </MenuItem>
                    <MenuItem
                      value="30"
                    >
                      Mensual
                    </MenuItem>
                  </TextField>
                </Grid>
                <Button
                  className={classes.createDialogButtonsDownload}
                  onClick={handleCloseDownload}>Cancelar</Button>
                <Button
                  className={classes.createDialogButtonsDownload}
                  variant="contained"
                  color="primary"
                  onClick={handleExport}
                  disabled={!(downloadFrom !== '' && downloadTo !== '' && downloadPeriod !== '')}
                >
                  Descargar
                </Button>
              </Grid>
            </Dialog>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  reports: PropTypes.array,
  originalReports: PropTypes.array,
  count: PropTypes.number,
  sort: PropTypes.string,
  setSort: PropTypes.func,
  sortOptions: PropTypes.array,
};

Results.defaultProps = {
  products: [],
};

export default Results;
