import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getCategoriesPerPage } from '../../../requests/api/categories';
import { getAllSupermarkets } from '../../../requests/api/supermarkets';

import useStyles from './styles';
import alphabeticSort from '../../../utils/alphabeticSort';

import {
  setCategoryQuery,
  setCategoryPage,
  setCategoryLimit,
  setCategorySupermarket,
} from '../../../actions/categoriesList';

function CategoriesListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [count, setCount] = useState(0);
  const [supermarkets, setSupermarkets] = useState([]);
  const [categories, setCategories] = useState([]);

  const {
    categoryQuery,
    categorySupermarket,
    categoryPage,
    categoryLimit,
  } = useSelector((state) => state.categoriesList);

  const dispatch = useDispatch();

  const cleanFilters = () => {
    dispatch(setCategoryPage(0));
    dispatch(setCategoryLimit(10));
    dispatch(setCategoryQuery(''));
    dispatch(setCategorySupermarket([]));
  };

  useEffect(() => {
    try {
      const getSupermarkets = async () => {
        const response = await getAllSupermarkets();
        if (response.success) {
          const data = await response?.data?.data;
          setSupermarkets(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getSupermarkets();
    } catch {
      setSupermarkets([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getCategories = async () => {
        const supIdes = categorySupermarket.map((val) => val.id);
        const response = await getCategoriesPerPage(
          categoryPage,
          categoryLimit,
          categoryQuery,
          supIdes,
        );
        if (response.success) {
          const data = await response?.data?.data;
          const arrayData = data?.categories ? Object.values(data?.categories) : null;
          setCategories(arrayData);
          setCount(data?.totalCategories);
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getCategories();
    } catch (err) {
      setCategories([]);
    }
  }, [
    categoryPage,
    categoryLimit,
    categoryQuery,
    categorySupermarket,
  ]);

  return (
    <Page
      className={classes.root}
      title="FH Engel | Categorias"
    >
      <Container maxWidth={false}>
        <Header cleanFilters={cleanFilters}/>
        {categories && (
          <Box mt={3}>
            <Results
              supermarkets={supermarkets}
              categories={categories}
              count={count}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default CategoriesListView;
