import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getSubcategoriesPerPage } from '../../../requests/api/subcategories';
import { getAllSupermarkets } from '../../../requests/api/supermarkets';
import { getAllCategories } from '../../../requests/api/categories';

import useStyles from './styles';
import alphabeticSort from '../../../utils/alphabeticSort';

import {
  setSubcategoryQuery,
  setSubcategoryCategory,
  setSubcategoryPage,
  setSubcategoryLimit,
  setSubcategorySupermarket,
} from '../../../actions/subcategoriesList';

function SubcategoriesListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [count, setCount] = useState(0);
  const [subcategories, setSubcategories] = useState([]);
  const [supermarkets, setSupermarkets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [originalCategories, setOriginalCategories] = useState([]);
  const {
    subcategoryQuery,
    subcategoryCategory,
    subcategorySupermarket,
    subcategoryPage,
    subcategoryLimit,
  } = useSelector((state) => state.subcategoriesList);

  const dispatch = useDispatch();

  const cleanFilters = () => {
    dispatch(setSubcategoryPage(0));
    dispatch(setSubcategoryLimit(10));
    dispatch(setSubcategoryQuery(''));
    dispatch(setSubcategorySupermarket([]));
    dispatch(setSubcategoryCategory([]));
  };

  useEffect(() => {
    try {
      const getSupermarkets = async () => {
        const response = await getAllSupermarkets();
        if (response.success) {
          const data = await response?.data?.data;
          setSupermarkets(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getSupermarkets();
    } catch {
      setSupermarkets([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getCategories = async () => {
        const response = await getAllCategories();
        if (response.success) {
          const data = await response?.data?.data;
          setOriginalCategories(data.sort((a, b) => alphabeticSort(a, b)));
          if (subcategorySupermarket.length > 0) {
            const supIdes = subcategorySupermarket.map((val) => val.id);
            setCategories(data.filter(
              (item) => supIdes.includes(item.supermarket_id),
            ).sort(
              (a, b) => alphabeticSort(a, b),
            ));
          } else {
            setCategories(data.sort((a, b) => alphabeticSort(a, b)));
          }
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getCategories();
    } catch {
      setCategories([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getSubcategories = async () => {
        const catIdes = subcategoryCategory.map((val) => val.id);
        const supIdes = subcategorySupermarket.map((val) => val.id);
        const response = await getSubcategoriesPerPage(
          subcategoryPage,
          subcategoryLimit,
          subcategoryQuery,
          supIdes,
          catIdes,
        );
        if (response.success) {
          const data = await response?.data?.data;
          const arrayData = data?.subcategories ? Object.values(data?.subcategories) : null;
          setSubcategories(arrayData);
          setCount(data?.totalSubcategories);
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getSubcategories();
    } catch {
      setSubcategories([]);
    }
  }, [
    subcategoryPage,
    subcategoryLimit,
    subcategoryQuery,
    subcategorySupermarket,
    subcategoryCategory,
  ]);

  return (
    <Page
      className={classes.root}
      title="FH Engel | Subcategorias"
    >
      <Container maxWidth={false}>
        <Header cleanFilters={cleanFilters}/>
        {subcategories && (
          <Box mt={3}>
            <Results
              subcategories={subcategories}
              supermarkets={supermarkets}
              originalCategories={originalCategories}
              categories={categories}
              setCategories={setCategories}
              count={count}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default SubcategoriesListView;
