import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
  CircularProgress,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getReportsPerPage } from '../../../requests/api/reports';

import useStyles from './styles';

import {
  setReportQuery,
  setReportPage,
  setReportLimit,
} from '../../../actions/reportsList';

const sortOptions = [
  {
    value: 'ALPH',
    label: 'Alfabético',
  },
  {
    value: 'DESC',
    label: 'Más nuevo primero',
  },
  {
    value: 'ASC',
    label: 'Más antiguo primero',
  },
];

function ReportListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const {
    reportQuery,
    reportPage,
    reportLimit,
  } = useSelector((state) => state.reportsList);

  const account = useSelector((state) => state.account);

  const cleanFilters = () => {
    dispatch(setReportPage(0));
    dispatch(setReportLimit(10));
    dispatch(setReportQuery(''));
    setSort(sortOptions[0].value);
  };

  useEffect(() => {
    try {
      const getReports = async () => {
        setLoading(true);
        const userRole = account.user.role;
        const userId = account.user.id;
        const response = await getReportsPerPage(
          reportPage,
          reportLimit,
          reportQuery,
          sort,
          userRole,
          userId,
          false,
        );
        if (response.success) {
          const data = await response?.data?.data;
          const arrayData = data?.reports ? Object.values(data?.reports) : null;
          setReports(arrayData);
          setCount(data?.totalReports);
        } else {
          enqueueSnackbar('Error al cargar la información de los reportes.', {
            variant: 'error',
          });
        }
      };
      getReports();
    } catch {
      setReports([]);
    }
    setLoading(false);
  }, [
    reportPage,
    reportLimit,
    reportQuery,
    sort]);

  if (loading) {
    return (<CircularProgress style={{ color: 'var(--brand-blue' }} />);
  }

  return (
    <Page
      className={classes.root}
      title="FH Engel | Reportes"
    >
      <Container maxWidth={false}>
        <Header cleanFilters={cleanFilters}/>
          <Box mt={3}>
            <Results
              reports={reports}
              sort={sort}
              setSort={setSort}
              sortOptions={sortOptions}
              count={count}
            />
          </Box>
      </Container>
    </Page>
  );
}

export default ReportListView;
