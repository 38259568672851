/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  SvgIcon,
  MenuItem,
} from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PlusCircle as PlusCircleIcon } from 'react-feather';
import { getAllEngelProducts, getProductsByEngelProductId } from '../../../requests/api/engelproducts';
import { getProductInfo } from '../../../requests/api/products';
// import { postCreateReportComparative } from '../../../requests/api/reports';

import useStyles from './styles';

function ReportCreateFormHistorical() {
  const classes = useStyles();
  // const { enqueueSnackbar } = useSnackbar();
  // const [submitSuccess, setSubmitSuccess] = useState(false);
  const account = useSelector((state) => state.account);

  const [openPeriodicSend, setOpenPeriodicSend] = useState(false);
  const [openSaveReport, setOpenSaveReport] = useState(false);

  const [engelProducts, setEngelProducts] = useState([]);

  const [reportTitle, setReportTitle] = useState(['']);
  const [emails, setEmails] = useState('');
  const [reportFrom, setReportFrom] = useState('');
  const [reportTo, setReportTo] = useState('');

  const [engelProductSelected, setEngelProductSelected] = useState('');
  const [selected, setSelected] = useState(false);

  const [engelProductSelectedAssociateBrands,
    setEngelProductSelectedAssociateBrands] = useState([]);

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedSupermarkets, setSelectedSupermarkets] = useState([]);

  const getEngelProducts = async () => {
    const userRole = account.user.role;
    const userId = account.user.id;
    const response = await getAllEngelProducts(userRole,
      userId, false);
    const data = await response?.data?.data;
    if (data.totalProducts > 0) {
      setEngelProducts(data.products);
    }
  };

  const getEngelProductSelectedAssociateBrands = async () => {
    if (engelProductSelected) {
      const productEngelSelectedId = engelProductSelected.id;
      const productsId = await getProductsByEngelProductId(productEngelSelectedId);
      const data = await productsId?.data?.data;
      if (data) {
        const productInfoBrand = [];
        for (let i = 0; i < data.length; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          const resP = await getProductInfo(data[i],
            account.user.id, account.user.role);
          const product = resP?.data?.data;
          if (!productInfoBrand.includes(product.brand)) {
            productInfoBrand.push(product.brand);
          }
        }
        setEngelProductSelectedAssociateBrands(productInfoBrand);
      }
    }
  };
  const handleChangeTitleReport = (event) => {
    setReportTitle(event.target.value);
  };
  const handleChangeEmails = (event) => {
    setEmails(event.target.value);
  };
  const handleChangeFrom = (event) => {
    setReportFrom(event.target.value);
  };
  const handleChangeTo = (event) => {
    setReportTo(event.target.value);
  };

  const handleChangeEngelProduct = (event) => {
    setEngelProductSelected(event.target.value);
    setSelected(true);
  };

  const handleSelectOneSupermarket = (event, supermarket) => {
    if (!selectedSupermarkets.includes(supermarket)) {
      setSelectedSupermarkets((prevSelected) => [...prevSelected, supermarket]);
    } else {
      setSelectedSupermarkets((prevSelected) => prevSelected.filter((s) => s !== supermarket));
    }
  };

  const handleSelectOneBrand = (event, brand) => {
    if (!selectedBrands.includes(brand)) {
      setSelectedBrands((prevSelected) => [...prevSelected, brand]);
    } else {
      setSelectedBrands((prevSelected) => prevSelected.filter((s) => s !== brand));
    }
  };

  const handleOpenPeriodicSend = () => {
    setOpenPeriodicSend(true);
  };

  const handleClosePeriodicSend = () => {
    setOpenPeriodicSend(false);
  };

  const handleSaveReport = () => {
    setOpenSaveReport(true);
  };
  const handleCloseSaveReport = () => {
    setOpenSaveReport(false);
  };

  const handleSubmitSaveReport = () => {
    const objectContent = {
      name: engelProductSelected.description,
      id: engelProductSelected.id,
      started_date: reportFrom,
      ending_date: reportTo,
    };
    const objectReport = {
      title: reportTitle,
      content: objectContent,
      type: 'Histórico',
    };
  };

  useEffect(() => {
    try {
      getEngelProducts();
    } catch {
      setEngelProducts([]);
    }
  }, []);

  useEffect(() => {
    try {
      getEngelProductSelectedAssociateBrands();
    } catch {
      setEngelProductSelectedAssociateBrands([]);
    }
  }, [engelProductSelected]);

  return (
    <Box>
      <Typography
        variant="h4"
        className={classes.title}
      >
        Título del reporte
      </Typography>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              fullWidth
              name="title"
              onChange={handleChangeTitleReport}
              required
              value={reportTitle}
              variant="outlined"
            />
          </Grid>
          <Grid item />
        </CardContent>
      </Card>
      <Typography
        variant="h4"
        className={classes.title}
      >
        Comparación 1
      </Typography>
      <Card className={classes.card}>
        <CardContent>
        <Typography
          variant="h5"
          className={classes.subtitle}
        >
        Producto homologado
        </Typography>
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            fullWidth
            name="engelProducts"
            value={engelProductSelected}
            onChange={handleChangeEngelProduct}
            select
            variant="outlined"
            required
          >
            {engelProducts.map((product) => (
              <MenuItem
                value={product}
                key={product.id}
              >
                {product.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Typography
          variant="h5"
          className={classes.subtitle}
        >
        Supermercados
        </Typography>
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value="1"
            control={<Checkbox />}
            label="Jumbo"
            labelPlacement="end"
            className={classes.checkbox}
            onChange={(event) => handleSelectOneSupermarket(event, 'Jumbo')}
          />
          <FormControlLabel
            value="2"
            control={<Checkbox />}
            label="Lider"
            labelPlacement="end"
            className={classes.checkbox}
            onChange={(event) => handleSelectOneSupermarket(event, 'Lider')}
          />
          <FormControlLabel
            value="3"
            control={<Checkbox />}
            label="Unimarc"
            labelPlacement="end"
            className={classes.checkbox}
            onChange={(event) => handleSelectOneSupermarket(event, 'Unimarc')}
          />
        </FormGroup>
        <Typography
          variant="h5"
          className={classes.subtitle}
        >
        Marcas consideradas
        </Typography>
        <FormGroup aria-label="position" row>
          {engelProductSelectedAssociateBrands.map((product) => (
            <FormControlLabel
              key={product}
              value={product}
              control={<Checkbox />}
              label={product}
              labelPlacement="end"
              className={classes.checkbox}
              onChange={(event) => handleSelectOneBrand(event, product)}
            />
          ))}
        </FormGroup>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
          >
          <IconButton
            className={classes.menuButton}
            color="inherit"
            // onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="large">
              <PlusCircleIcon className={classes.menuIcon} />
            </SvgIcon>
          </IconButton>
          <Typography
            variant="h5"
          >
            Crear nueva comparación
          </Typography>
          </Grid>
        </CardContent>
      </Card>
      <Box display='flex' paddingLeft='65%'>
        <Box
          mr={2}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleOpenPeriodicSend}
          >
            Programar envío periódico
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.orangeButton}
            onClick={handleSaveReport}
            disabled={!selected}
          >
            Guardar reporte
          </Button>
        </Box>
      </Box>
      <Dialog
        open={openPeriodicSend}
        onClose={handleClosePeriodicSend}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.createDialog }}
        disableTypography
      >
        <DialogTitle disableTypography>
          <Typography className={classes.createDialogTitle}>Programar envío periódico</Typography>
        </DialogTitle>
        <Grid
            item
          >
            <TextField
              fullWidth
              label="Frecuencia de envío"
              name="type"
              // value={reportType}
              // onChange={(e, v) => handleSelectReportType(v)}
              select
              variant="outlined"
              required
              className={classes.createDialogTexfield}
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem
                value="Diariamente"
              >
                Comparativo
              </MenuItem>
              <MenuItem
                value="Semanalmente"
              >
                Precio histórico
              </MenuItem>
              <MenuItem
                value="Mensualmente"
              >
                Precio histórico
              </MenuItem>
            </TextField>
            <TextField
              fullWidth
              label="Correos electrónicos"
              name="type"
              // value={reportType}
              // onChange={(e, v) => handleSelectReportType(v)}
              variant="outlined"
              required
              className={classes.createDialogTexfield}
              InputLabelProps={{ shrink: true }}
            >
            </TextField>
            <TextField
              fullWidth
              label="Tiempo considerado en el reporte"
              name="type"
              // value={reportType}
              // onChange={(e, v) => handleSelectReportType(v)}
              select
              variant="outlined"
              required
              className={classes.createDialogTexfield}
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem
                value="Comparativo"
              >
                Comparativo
              </MenuItem>
              <MenuItem
                value="Historico"
              >
                Precio histórico
              </MenuItem>
            </TextField>
            <Grid>
              <TextField
                fullWidth
                name="type"
                label='Enviar a partir del'
                // value={reportType}
                // onChange={(e, v) => handleSelectReportType(v)}
                type='date'
                variant="outlined"
                required
                className={classes.createDialogTexfield}
                InputLabelProps={{ shrink: true }}
              >
              </TextField>
            </Grid>
          </Grid>
        <DialogActions>
          <Button
            className={classes.createDialogButtons}
            onClick={handleClosePeriodicSend}>Cancelar
          </Button>
          <Button className={classes.createDialogButtons} >Siguiente</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSaveReport}
        onClose={handleCloseSaveReport}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.createDialogSaveReport }}
        disableTypography
      >
        <DialogTitle disableTypography>
          <Typography className={classes.createDialogTitle}>Guardar reporte</Typography>
        </DialogTitle>
        <Grid
            item
          >
            <Grid>
              <TextField
                fullWidth
                name="type"
                label='Considerar desde'
                value={reportFrom}
                onChange={(e) => handleChangeFrom(e.valueOf())}
                type='date'
                variant="outlined"
                required
                className={classes.createDialogTexfield}
                InputLabelProps={{ shrink: true }}
              >
              </TextField>
              <TextField
                fullWidth
                name="type"
                label='Hasta'
                value={reportTo}
                onChange={(e) => handleChangeTo(e.valueOf())}
                type='date'
                variant="outlined"
                required
                className={classes.createDialogTexfield}
                InputLabelProps={{ shrink: true }}
              >
              </TextField>
                <TextField
                fullWidth
                label="Correos electrónicos"
                name="type"
                value={emails}
                onChange={handleChangeEmails}
                variant="outlined"
                required
                className={classes.createDialogTexfield}
                InputLabelProps={{ shrink: true }}
              >
            </TextField>
            </Grid>
          </Grid>
        <DialogActions>
          <Button
            className={classes.createDialogButtons}
            onClick={handleCloseSaveReport}
          >
              Cancelar
          </Button>
          <Button
            className={classes.createDialogButtons}
            onClick={handleSubmitSaveReport}
          >
            Enviar y guardar reporte
          </Button>
        </DialogActions>
      </Dialog>
    </Box>

  );
}

ReportCreateFormHistorical.propTypes = {
  className: PropTypes.string,
  stores: PropTypes.array,
};

export default ReportCreateFormHistorical;
