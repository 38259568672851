const SET_PRODUCT_ENGEL_QUERY = 'SET_PRODUCT_ENGEL_QUERY';
const SET_PRODUCT_ENGEL_PAGE = 'SET_PRODUCT_ENGEL_PAGE';
const SET_PRODUCT_ENGEL_LIMIT = 'SET_PRODUCT_ENGEL_LIMIT';
const SET_PRODUCT_ENGEL_BRAND = 'SET_PRODUCT_ENGEL_BRAND';
const LOGOUT = 'LOGOUT';

function setEngelProductQuery(engelProductQuery) {
  return async (dispatch) => dispatch({
    type: SET_PRODUCT_ENGEL_QUERY,
    payload: {
      engelProductQuery,
    },
  });
}

function setEngelProductPage(engelProductPage) {
  return async (dispatch) => dispatch({
    type: SET_PRODUCT_ENGEL_PAGE,
    payload: {
      engelProductPage,
    },
  });
}

function setEngelProductLimit(engelProductLimit) {
  return async (dispatch) => dispatch({
    type: SET_PRODUCT_ENGEL_LIMIT,
    payload: {
      engelProductLimit,
    },
  });
}

function setEngelProductBrand(engelProductBrand) {
  return async (dispatch) => dispatch({
    type: SET_PRODUCT_ENGEL_BRAND,
    payload: {
      engelProductBrand,
    },
  });
}

function logoutEngelProduct() {
  return async (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
}

export {
  SET_PRODUCT_ENGEL_QUERY,
  SET_PRODUCT_ENGEL_PAGE,
  SET_PRODUCT_ENGEL_LIMIT,
  SET_PRODUCT_ENGEL_BRAND,
  LOGOUT,
  setEngelProductQuery,
  setEngelProductPage,
  setEngelProductLimit,
  setEngelProductBrand,
  logoutEngelProduct,
};
