import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
  CircularProgress,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getEngelProductsPerPage } from '../../../requests/api/engelproducts';

import useStyles from './styles';

import {
  setEngelProductQuery,
  setEngelProductPage,
  setEngelProductLimit,
} from '../../../actions/engelProductsList';

const sortOptions = [
  {
    value: 'ALPH',
    label: 'Alfabético',
  },
  {
    value: 'DESC',
    label: 'Más nuevo primero',
  },
  {
    value: 'ASC',
    label: 'Más antiguo primero',
  },
];

function EngelProductConfigView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [count, setCount] = useState(0);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [engelProducts, setEngelProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const {
    engelProductQuery,
    engelProductPage,
    engelProductLimit,
  } = useSelector((state) => state.engelProductsList);

  const account = useSelector((state) => state.account);

  const cleanFilters = () => {
    dispatch(setEngelProductPage(0));
    dispatch(setEngelProductLimit(10));
    dispatch(setEngelProductQuery(''));
    setSort(sortOptions[0].value);
  };

  useEffect(() => {
    try {
      const getEngelProducts = async () => {
        setLoading(true);
        const userRole = account.user.role;
        const userId = account.user.id;
        const response = await getEngelProductsPerPage(
          engelProductPage,
          engelProductLimit,
          engelProductQuery,
          sort,
          userRole,
          userId,
        );
        if (response.success) {
          const data = await response?.data?.data;
          const arrayData = data?.products ? Object.values(data?.products) : null;
          setEngelProducts(arrayData);
          setCount(data?.totalProducts);
        } else {
          enqueueSnackbar('Error al cargar la información de los productos.', {
            variant: 'error',
          });
        }
      };
      getEngelProducts();
    } catch {
      setEngelProducts([]);
    }
    setLoading(false);
  }, [
    engelProductPage,
    engelProductLimit,
    engelProductQuery,
    sort,
  ]);

  if (loading) {
    return (<CircularProgress style={{ color: 'var(--brand-blue' }} />);
  }

  return (
    <Page
      className={classes.root}
      title="FH Engel | Products Engel"
    >
      <Container maxWidth={false}>
        <Header cleanFilters={cleanFilters}/>
        {engelProducts && (
          <Box mt={3}>
            <Results
              engelProducts={engelProducts}
              sort={sort}
              setSort={setSort}
              sortOptions={sortOptions}
              count={count}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default EngelProductConfigView;
