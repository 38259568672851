import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import SubcategoryEditForm from './SubcategoryEditForm';

import { getAllCategories } from '../../../requests/api/categories';
import { getAllSupermarkets } from '../../../requests/api/supermarkets';
import { getSubcategoryDetail } from '../../../requests/api/subcategories';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

import alphabeticSort from '../../../utils/alphabeticSort';
import useStyles from './styles';

function SubcategoryEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [supermarkets, setSupermarkets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategory, setSubcategory] = useState();

  useEffect(() => {
    const getSubcategory = async () => {
      const res = await getSubcategoryDetail(props.match.params.id);
      const response = res.data;
      if (isMountedRef.current) {
        setSubcategory(response.data);
      }
    };
    getSubcategory();
  }, [props]);

  useEffect(() => {
    try {
      const getSupermarkets = async () => {
        const response = await getAllSupermarkets();
        if (response.success) {
          const data = await response?.data?.data;
          setSupermarkets(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getSupermarkets();
    } catch {
      setSupermarkets([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getCategories = async () => {
        const response = await getAllCategories();
        if (response.success) {
          const data = await response?.data?.data;
          setCategories(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getCategories();
    } catch {
      setCategories([]);
    }
  }, []);

  if (!subcategory) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="FH Engel | Editar Subcategoría"
    >
      <Container maxWidth={false}>
        <Header />
        <SubcategoryEditForm
          supermarkets={supermarkets}
          categories={categories}
          subcategory={subcategory}
        />
      </Container>
    </Page>
  );
}

SubcategoryEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(SubcategoryEditView);
