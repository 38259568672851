import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  linechart: {
    padding: '8px',
  },
  linechart_path: {
    strokeWidth: 3,
    fill: null,
  },
  linechart_axis: {
    stroke: '#bdc3c7',
  },
  linechart_point: {
    fill: '#fff',
    strokeWidth: 2,
  },
  linechart_area: {
    padding: '8px',
    fill: '#64B5F6',
    stroke: null,
    opacity: 0.4,
  },
  linechart_label: {
    fill: '#64B5F6',
    fontWeight: 700,
  },
  hoverLine: {
    stroke: '#7D95B6',
    strokeWidth: 1,
  },
}));

export default useStyles;
