import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Redirect } from 'react-router';

import { deleteEngelProduct } from '../../../requests/api/engelproducts';

import useStyles from './styles';

function EngelProductEditForm({
  className,
  engelProduct,
  setEngelProductPageEdit,
  newEngelProduct,
  setNewEngelProduct,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [open, setOpen] = React.useState(false);

  if (submitSuccess) {
    return <Redirect to="/engel/engel-products" />;
  }

  const handleDelete = async () => {
    setOpen(false);
    try {
      const data = {
        id: engelProduct.id,
      };
      const response = await deleteEngelProduct(data);
      if (response.success) {
        setSubmitSuccess(true);
        enqueueSnackbar(response.message, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(response.message, {
          variant: 'warning',
          action: <Button href="/engel/engel-products">Volver atrás</Button>,
        });
      }
    } catch (error) {
      setSubmitSuccess(false);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Formik
      initialValues={{
        description: engelProduct.description || '',
        sku: engelProduct.sku || '',
        units_per_product: engelProduct.units_per_product || '',
      }}
      onSubmit={async (values) => {
        const data = {
          id: engelProduct.id,
          description: values.description,
          sku: values.sku,
          units_per_product: values.units_per_product,
        };
        setNewEngelProduct(data);
        setEngelProductPageEdit(1);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={touched.description && errors.description}
                    label="Nombre"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.sku && errors.sku)}
                    fullWidth
                    helperText={touched.sku && errors.sku}
                    label="Sku"
                    name="sku"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.sku}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.units_per_product && errors.units_per_product)}
                    fullWidth
                    helperText={touched.units_per_product && errors.units_per_product}
                    label="Unidades por producto"
                    name="units_per_product"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.units_per_product}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
              >
              <Grid
                  item
                  md={6}
                  xs={2}
              >
              <Box mt={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Siguiente
                </Button>
              </Box>
              </Grid>
              <Grid
                  item
                  md={6}
                  xs={2}
              >
              <Box mt={1}>
                  <Button
                      style={{
                        backgroundColor: '#f44336',
                        color: 'white',
                      }}
                    variant="contained"
                    onClick={handleClickOpen}
                  >
                    Eliminar
                  </Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {'¿Desea eliminar este producto Engel?'}
                    </DialogTitle>
                    <DialogActions>
                      <Button onClick={handleDelete}>Si</Button>
                      <Button onClick={handleClose}>No</Button>
                    </DialogActions>
                  </Dialog>
                </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

EngelProductEditForm.propTypes = {
  className: PropTypes.string,
  engelProduct: PropTypes.object,
  setEngelProductPageEdit: PropTypes.func,
  newEngelProduct: PropTypes.object,
  setNewEngelProduct: PropTypes.func,
};

export default EngelProductEditForm;
