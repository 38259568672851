import React, {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Container,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getProductSnapshotsCount } from '../../../requests/api/productsnapshots';
import { getAllSubcategories, getSubcategoriesObjetivesCount, getSubcategoriesObjetivesPagesCount } from '../../../requests/api/subcategories';
import { getAllCategoriesAndSubCategories } from '../../../requests/api/categories';
import { getAllSupermarkets } from '../../../requests/api/supermarkets';

import alphabeticSort from '../../../utils/alphabeticSort';
import useStyles from './styles';

function ScraperStatistics() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [subcategoriesObjetives, setSubcategoriesObjetives] = useState([]);
  const [subcategoriesObjetivesPages, setSubcategoriesObjectivesPages] = useState([]);
  const [productSnapshots, setProductSnapshots] = useState([]);
  const [products, setProducts] = useState([]);
  const today = new Date();
  const [endDate, setEndDate] = useState(today.setHours(23, 59, 59, 999));
  today.setDate(today.getDate() - 7);
  const [startDate, setStartDate] = useState(today.setHours(0, 0, 0, 0));
  const [subcategory, setSubcategory] = useState(0);
  const [subcategories, setSubcategories] = useState([{ id: 0, name: 'Todas' }]);
  const [category, setCategory] = useState(0);
  const [categories, setCategories] = useState([{ id: 0, name: 'Todas' }]);
  const [supermarket, setSupermarket] = useState(0);
  const [supermarkets, setSupermarkets] = useState([]);
  const [originalCategories, setOriginalCategories] = useState([{ id: 0, name: 'Todas' }]);
  const [average, setAverage] = useState(0);

  useEffect(() => {
    try {
      const getSupermarkets = async () => {
        const response = await getAllSupermarkets();
        if (response.success) {
          const data = await response?.data?.data;
          const first = [{ id: 0, name: 'Todos' }];
          setSupermarkets(first.concat(data.sort((a, b) => alphabeticSort(a, b))));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getSupermarkets();
    } catch {
      setSupermarkets([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getCategories = async () => {
        const response = await getAllCategoriesAndSubCategories();
        if (response.success) {
          const data = await response?.data?.data;
          const first = [{ id: 0, name: 'Todas' }];
          setOriginalCategories(first.concat(data.sort((a, b) => alphabeticSort(a, b))));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getCategories();
    } catch {
      setOriginalCategories([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getProductSnapshots = async () => {
        const response = await getProductSnapshotsCount(
          startDate, endDate, supermarket, category, subcategory,
        );
        if (response.success) {
          const data = await response?.data?.data;
          const parsedData = data.map((snap) => [Date.parse(snap.date_trunc), snap.count]);
          setProductSnapshots(parsedData);
        } else {
          enqueueSnackbar('Error al cargar la información de los productos.', {
            variant: 'error',
          });
        }
      };
      getProductSnapshots();
    } catch {
      setProductSnapshots([]);
    }
  }, [startDate, endDate, supermarket, category, subcategory]);

  useEffect(() => {
    try {
      const getSubcategoriesObjetives = async () => {
        const response = await getSubcategoriesObjetivesCount(
          startDate, endDate, supermarket, category, subcategory,
        );
        if (response.success) {
          const data = await response?.data?.data;
          const parsedData = data.map((snap) => [Date.parse(snap.date_trunc),
            parseInt(snap.total_weighted_products, 10)]);
          setSubcategoriesObjetives(parsedData);
        } else {
          enqueueSnackbar('Error al cargar la información de los objetivos de subcategorias.', {
            variant: 'error',
          });
        }
      };
      getSubcategoriesObjetives();
    } catch {
      setSubcategoriesObjetives([]);
    }
  }, [startDate, endDate, supermarket, category, subcategory]);

  useEffect(() => {
    try {
      const getSubcategoriesObjectivesPages = async () => {
        const response = await getSubcategoriesObjetivesPagesCount(
          startDate, endDate, supermarket, category, subcategory,
        );
        if (response.success) {
          const data = await response?.data?.data;
          const parsedData = data.map((snap) => [Date.parse(snap.date_trunc),
            parseInt(snap.total_weighted_products, 10)]);
          setSubcategoriesObjectivesPages(parsedData);
        } else {
          enqueueSnackbar('Error al cargar la información de los objetivos de subcategorias.', {
            variant: 'error',
          });
        }
      };
      getSubcategoriesObjectivesPages();
    } catch {
      setSubcategoriesObjectivesPages([]);
    }
  }, [startDate, endDate, supermarket, category, subcategory]);

  useEffect(() => {
    try {
      const getProducts = async () => {
        const response = await getAllSubcategories(
          supermarket, category, subcategory, true,
        );
        if (response.success) {
          let avg = 0;
          const subcats = await response?.data?.data.subcategories;
          for (let i = 0; i < subcats.length; i += 1) {
            avg += Math.round(subcats[i].Products.length / subcats[i].frequency, 0);
          }
          setAverage(avg);
        } else {
          enqueueSnackbar('Error al cargar la información de los productos.', {
            variant: 'error',
          });
        }
      };
      getProducts();
    } catch {
      setProducts([]);
    }
  }, [supermarket, category, subcategory]);

  return (
    <Page
      className={classes.root}
      title="FH Engel | Productos Capturados"
    >
      <Container maxWidth={false}>
        <Header categories={categories} />
          <Box mt={3}>
            <Results
              supermarkets={supermarkets}
              supermarket={supermarket}
              setSupermarket={setSupermarket}
              originalCategories={originalCategories}
              categories={categories}
              setCategories={setCategories}
              setCategory={setCategory}
              category={category}
              subcategories={subcategories}
              setSubcategories={setSubcategories}
              setSubcategory={setSubcategory}
              subcategory={subcategory}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              productSnapshots={productSnapshots}
              products={products}
              average={average}
              subcategoriesObjetives={subcategoriesObjetives}
              subcategoriesObjetivesPages={subcategoriesObjetivesPages}
            />
          </Box>
      </Container>
    </Page>
  );
}

export default ScraperStatistics;
