import axios from '../../utils/axios';

const getAlarmInformation = async () => {
  try {
    const { data } = await axios.get('/api/alarms');

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    return err.response.data;
  }
};

const postAddEmailToAlarmList = async (
  email,
  categories,
  brands,
  supermarkets,
  dayToSend,
) => {
  try {
    const response = await axios.post('/api/alarms', {
      email,
      categories,
      brands,
      supermarkets,
      dayToSend,
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const putEditDayToSendAlarm = async (idAlarm, day) => {
  try {
    const response = await axios.put('/api/alarms', {
      idAlarm,
      day,
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const deleteEmailFromAlarmList = async (idAlarm) => {
  try {
    const response = await axios.delete(`/api/alarms/${idAlarm}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  getAlarmInformation,
  postAddEmailToAlarmList,
  putEditDayToSendAlarm,
  deleteEmailFromAlarmList,
};
