import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { X as XIcon } from 'react-feather';
import { Redirect } from 'react-router';

import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { postCreateEngelProduct } from '../../../requests/api/engelproducts';

import useStyles from './styles';
import CustomTextField from '../../../components/Inputs/TextField/CustomTextField';

import {
  resetEngelProductCreation,
  setEngelProductCreationPage,
  setEngelProductDetails,
} from '../../../actions/engelProductsActions';

function EngelProductsAssociation({
  products, totalProducts, page,
  limit, setPage, setLimit,
  setSearchValueProduct,
  supermarkets,
  searchSupermarkets,
  setEngelProductSupermarket,
  brands,
  searchBrand,
  setEngelProductBrand,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsObjects, setSelectedProductsObjects] = useState([]);
  const [currentProducts, setCurrentProducts] = useState(products);
  const [query, setQuery] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const { engelProductInformation } = useSelector((state) => state.engelProduct);

  const handleSearchValue = () => {
    if (query.trim()) {
      setSearchValueProduct(query);
    }
  };

  const handleSetBrandValue = (value) => {
    setEngelProductBrand(value);
  };

  const handleSetSupermarketValue = (value) => {
    setEngelProductSupermarket(value);
  };

  const handleSelectOneProduct = (event, product) => {
    if (!selectedProducts.includes(product.id)) {
      setSelectedProducts((prevSelected) => [...prevSelected, product.id]);
      setSelectedProductsObjects((prevSelected) => [...prevSelected, product]);
      dispatch(setEngelProductDetails({ productIds: [...selectedProducts, product.id] }));
      dispatch(setEngelProductDetails({ productObjects: [...selectedProductsObjects, product] }));
    } else {
      setSelectedProducts((prevSelected) => prevSelected.filter((id) => id !== product.id));
      // eslint-disable-next-line max-len
      setSelectedProductsObjects((prevSelected) => prevSelected.filter((productSelected) => productSelected.id !== product.id));
      dispatch(setEngelProductDetails({
        productIds: selectedProducts.filter((id) => id !== product.id),
      }));
      dispatch(setEngelProductDetails({
        // eslint-disable-next-line max-len
        productObjects: selectedProductsObjects.filter((productSelected) => productSelected.id !== product.id),
      }));
    }
  };

  const selectedSomeProducts = selectedProducts.length > 0
    && selectedProducts.length < currentProducts.length;

  const handlePreviousStep = () => {
    // setSearchValue('');
    setSearchValueProduct('');
    dispatch(setEngelProductCreationPage(0));
  };

  const handleSubmit = async () => {
    if (selectedProducts.length === 0) {
      enqueueSnackbar('Debes seleccionar por lo menos un producto', {
        variant: 'error',
      });
      return;
    }
    try {
      const response = await postCreateEngelProduct({
        ...engelProductInformation,
        productIds: selectedProducts,
      });
      if (response.success) {
        setSubmitSuccess(true);
        enqueueSnackbar(response.message, {
          variant: 'success',
        });
        dispatch(resetEngelProductCreation());
      } else {
        enqueueSnackbar(response.message, {
          variant: 'warning',
          action: <Button href="/engel/engel-products">Volver atrás</Button>,
        });
      }
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'warning',
      });
    }
  };

  useEffect(() => {
    setCurrentProducts(products);
    setSelectedProducts(engelProductInformation.productIds || []);
    setSelectedProductsObjects(engelProductInformation.productObjects || []);
  }, [products]);
  if (submitSuccess) {
    return <Redirect to="/engel/engel-products" />;
  }
  return (
    <Box>
      <Paper
        elevation={3}
      >
        <Box
          display='flex'
          alignItems='center'
          padding='15px 10px'
          justifyContent='space-around'
        >
          <Grid item xs={6} md={7}>
            <CustomTextField
              fullWidth
              color="secondary"
              placeholder="Nombre, SKU, EAN"
              type="text"
              variant="outlined"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      query && (<IconButton
                        onClick={() => {
                          setSearchValueProduct('');
                          setQuery('');
                        }}
                      >
                        <XIcon />
                      </IconButton>)
                    }
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSearchValue}
            >
              Buscar
            </Button>
          <Grid item xs={2} md={2}>
            {brands.length > 0 && <Autocomplete
              id="brands-autocomplete"
              options={brands}
              multiple
              value={searchBrand}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleSetBrandValue(v)}
              limitTags={1}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Filtrar por Marca'
                  variant='outlined'
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    key={option.id}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
            />
            }
          </Grid>
          <Grid item xs={2} md={2}>
            {supermarkets.length > 0 && <Autocomplete
              id="supermarkets-autocomplete"
              options={supermarkets}
              multiple
              value={searchSupermarkets}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleSetSupermarketValue(v)}
              limitTags={1}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Filtrar por Supermercado'
                  variant='outlined'
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    key={option.id}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
            />
            }
          </Grid>
        </Box>
      </Paper>
      <Box mt={3}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selectedSomeProducts}
                  />
                </TableCell>
                <TableCell>
                  Descripción
                </TableCell>
                <TableCell>
                  Marca
                </TableCell>
                <TableCell>
                  Supermercado
                </TableCell>
                <TableCell>
                  Unidades
                </TableCell>
                <TableCell>
                  SKU
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.length !== 0 && products.map((product) => {
                const isProductSelected = selectedProducts.includes(product.id);
                return (
                  <TableRow
                    key={product.id}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isProductSelected}
                        onChange={(event) => handleSelectOneProduct(event, product)}
                        value={isProductSelected}
                      />
                    </TableCell>
                    <TableCell>
                      {product.description}
                    </TableCell>
                    <TableCell>
                      {product.brand}
                    </TableCell>
                    <TableCell>
                      {product.supermarket_name}
                    </TableCell>
                    <TableCell>
                      {product.units_per_product}
                    </TableCell>
                    <TableCell>
                      {product.sku}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Box
            mt={2}
            className={classes.tablePagination}
          >
            <TablePagination
              component="div"
              count={totalProducts}
              onPageChange={(e, newPage) => setPage(newPage)}
              onRowsPerPageChange={(e) => {
                setLimit(e.target.value);
                setPage(0);
              }}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage="Filas por página"
            />
            <Box display='flex'>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Typography
        variant="h3"
        color="textPrimary"
        className={classes.tittle}
      >
        Productos seleccionados
      </Typography>
      <Box mt={3}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Descripción
                </TableCell>
                <TableCell>
                  Marca
                </TableCell>
                <TableCell>
                  Supermercado
                </TableCell>
                <TableCell>
                  Unidades
                </TableCell>
                <TableCell>
                  SKU
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedProductsObjects.length !== 0 && selectedProductsObjects.map((product) => (
                <TableRow
                  key={product.id}
                >
                  <TableCell>
                    {product.description}
                  </TableCell>
                  <TableCell>
                    {product.brand}
                  </TableCell>
                  <TableCell>
                    {product.supermarket_name}
                  </TableCell>
                  <TableCell>
                    {product.units_per_product}
                  </TableCell>
                  <TableCell>
                    {product.sku}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box
            mt={2}
            className={classes.tableSelectedProduct}
          >
            <Box display='flex' paddingLeft='73%'>
              <Box
                mr={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handlePreviousStep}
                >
                  Atrás
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.orangeButton}
                  onClick={handleSubmit}
                >
                  Crear Producto Engel
                </Button>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>

  );
}

EngelProductsAssociation.propTypes = {
  className: PropTypes.string,
  products: PropTypes.array,
  supermarkets: PropTypes.array,
  searchSupermarkets: PropTypes.array,
  brands: PropTypes.array,
  limit: PropTypes.number,
  searchBrand: PropTypes.array,
  setEngelProductBrand: PropTypes.func,
  setEngelProductSupermarket: PropTypes.func,
  totalProducts: PropTypes.number,
  page: PropTypes.number,
  setPage: PropTypes.func,
  setLimit: PropTypes.func,
  setSearchValue: PropTypes.func,
  setSearchValueProduct: PropTypes.func,
};

export default EngelProductsAssociation;
