import React, {
  useState,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Header from './Header';
import HistoricGraphEngelProduct from './HistoricGraphEngelProduct';

import useStyles from './styles';

import { getEngelProductInfo, getProductsByEngelProductId } from '../../../requests/api/engelproducts';

import { getProductSnapshots, getProductInfo } from '../../../requests/api/products';

const parsePrices = (snap, snapPrice, sale, quantity) => {
  const snapData = { x: Date.parse(snap.created_at) };
  if (!!snap[sale] || sale === 'normal') {
    if (snap.units_for_sale_price > 0 && sale !== 'normal') {
      const units = quantity * snap.units_for_sale_price;
      snapData.y = Math.round(
        parseInt(snap[snapPrice], 10) / units, 2,
      );
      snapData.units = `${snap.units_for_sale_price} x ${quantity}`;
    } else {
      snapData.y = Math.round(snap[snapPrice] / quantity, 2);
      snapData.units = quantity;
    }
    if (!!snap.price && snapData.y < snap.price && sale !== 'normal') {
      snapData.discount = Math.round((1 - snapData.y / (snap.price / quantity)) * 100, 0);
    }
  } else {
    snapData.y = null;
    snapData.units = null;
  }
  return snapData;
};

function EngelProductGraphs(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [engelProduct, setEngelProduct] = useState();
  const [productsAssocciated, setProductsAssociated] = useState([]);
  const [selectedOptionPrices, setSelectedOptionPrices] = useState(0);
  const priceOptions = [
    { id: 0, name: 'Precios normales' },
    { id: 1, name: 'Precios oferta' },
    { id: 2, name: 'Precios club' },
    { id: 3, name: 'Precios prime' },
  ];
  const account = useSelector((state) => state.account);

  useEffect(() => {
    const getEngelProduct = async () => {
      // eslint-disable-next-line max-len
      const resP = await getEngelProductInfo(props.match.params.id, account.user.id, account.user.role);
      const responseP = resP.data;
      if (isMountedRef.current) {
        setEngelProduct(responseP.data);
      }
    };
    getEngelProduct();
    setProductsAssociated([]);
  }, [props]);

  useEffect(() => {
    const getProductsAssociated = async () => {
      // eslint-disable-next-line max-len
      const engelProductInfo = await getEngelProductInfo(props.match.params.id, account.user.id, account.user.role);
      setEngelProduct(engelProductInfo.data.data);
      // eslint-disable-next-line max-len
      const resP = await getProductsByEngelProductId(engelProductInfo.data.data.id, account.user.id, account.user.role);
      const idProductsAssociated = resP.data.data;
      for (let i = 0; i < idProductsAssociated.length; i += 1) {
        // eslint-disable-next-line max-len, no-await-in-loop
        const infoProductAssociated = await getProductInfo(idProductsAssociated[i], account.user.id, account.user.role);
        // eslint-disable-next-line no-await-in-loop
        const snapshotsProductAssociated = await getProductSnapshots(idProductsAssociated[i]);
        const units = infoProductAssociated.data.data.units_per_product;
        if (isMountedRef.current) {
          if (engelProductInfo.data) {
            const normalPriceList = snapshotsProductAssociated.data.data.map(
              (snap) => parsePrices(snap, 'price', 'normal', units),
            );
            const salePriceList = snapshotsProductAssociated.data.data.map(
              (snap) => parsePrices(snap, 'price', 'sale', units),
            );
            const clubPriceList = snapshotsProductAssociated.data.data.map(
              (snap) => parsePrices(snap, 'club_price', 'club', units),
            );
            const primePriceList = snapshotsProductAssociated.data.data.map(
              (snap) => parsePrices(snap, 'prime_price', 'prime', units),
            );
            const objectProductAssociated = {
              productInfo: infoProductAssociated.data.data,
              productSnapshots: normalPriceList,
              productSnapshotsSale: salePriceList,
              productSnapshotsClub: clubPriceList,
              productSnapshotsPrime: primePriceList,
              productSnapshotsSelected: normalPriceList,
            };
            if (selectedOptionPrices === 1) {
              objectProductAssociated.productSnapshotsSelected = salePriceList;
            }
            if (selectedOptionPrices === 2) {
              objectProductAssociated.productSnapshotsSelected = clubPriceList;
            }
            if (selectedOptionPrices === 3) {
              objectProductAssociated.productSnapshotsSelected = primePriceList;
            }
            setProductsAssociated((prevSelected) => [...prevSelected, objectProductAssociated]);
          }
        }
      }
    };
    getProductsAssociated();
    setProductsAssociated([]);
  }, [props, selectedOptionPrices]);

  const handleChangeSelectedPrices = (type, newVal) => {
    setSelectedOptionPrices(newVal.id);
  };

  if (!engelProduct) {
    return null;
  }
  return (
    <Page
      className={classes.root}
      title="FH Engel | Gráfico producto"
    >
      <Container maxWidth="lg" key={engelProduct.id}>
        <Header
          supermarket={engelProduct.supermarket_name}
          productDescription={engelProduct.description}
          sku={engelProduct.sku}
        />
      <Box>
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
        spacing={2}
      >
        <Grid container spacing={1}>
        <Grid item xs={2} md={2}>
            <Autocomplete
              id="priceOptions-autocomplete"
              options={priceOptions}
              disableClearable
              defaultValue={{ id: 0, name: 'Precios normales' }}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleChangeSelectedPrices('prices', v)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Tipo de precio'
                  variant='outlined'
                />
              )}
            />
          </Grid>
          </Grid>
          </Box>
          <HistoricGraphEngelProduct
            productsAssocciated={productsAssocciated}
          />
        </Box>
      </Container>
    </Page>
  );
}

EngelProductGraphs.propTypes = {
  match: PropTypes.any,
};

export default withRouter(EngelProductGraphs);
