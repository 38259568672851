import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../constants/http';

const getProductSnapshotsCount = async (
  startDate,
  endDate,
  supermarket,
  category,
  subcategory,
) => {
  try {
    const { data } = await axios.get(`/api/productsnapshots/count?startDate=${startDate}&endDate=${endDate}&supermarket=${supermarket}&category=${category}&subcategory=${subcategory}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getProductSnapshotsPerPage = async (
  page, limit, searchValue, order,
  startDate, endDate, supermarket,
  category, subcategory, brand, userRole, userId,
) => {
  try {
    const { data } = await axios.get(`/api/productsnapshots?page=${page}&limit=${limit}&searchValue=${searchValue}&order=${order}&startDate=${startDate}&endDate=${endDate}&supermarket=${supermarket}&category=${category}&subcategory=${subcategory}&brand=${brand}&userRole=${userRole}&userId=${userId}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    return err.response.data;
  }
};

const exportProductSnapshots = async (
  searchValue,
  order,
  startDate, endDate,
  category,
  subcategory,
  supermarket,
  brand,
  user,
  timeframe,
  isEngelProduct,
) => {
  try {
    const response = await axios.get(`/api/productsnapshots/export?searchValue=${searchValue}&order=${order}&startDate=${startDate}&endDate=${endDate}&category=${category}&subcategory=${subcategory}&supermarket=${supermarket}&brand=${brand}&userId=${user.id}&userRole=${user.role}&timeframe=${timeframe}&isEngelProduct=${isEngelProduct}`);
    const { data } = response;
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: err.response?.data?.message || 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getProductSnapshotsPrices = async (
  startDate, endDate, category, subcategory, supermarket,
) => {
  try {
    const { data } = await axios.get(`/api/productsnapshots/prices?startDate=${startDate}&endDate=${endDate}&category=${category}&subcategory=${subcategory}&supermarket=${supermarket}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    return err.response.data;
  }
};

export {
  getProductSnapshotsCount,
  getProductSnapshotsPerPage,
  exportProductSnapshots,
  getProductSnapshotsPrices,
};
