const SET_SUBCATEGORY_QUERY = 'SET_SUBCATEGORY_QUERY';
const SET_SUBCATEGORY_SUPERMARKET = 'SET_SUBCATEGORY_SUPERMARKET';
const SET_SUBCATEGORY_CATEGORY = 'SET_SUBCATEGORY_CATEGORY';
const SET_SUBCATEGORY_PAGE = 'SET_SUBCATEGORY_PAGE';
const SET_SUBCATEGORY_LIMIT = 'SET_SUBCATEGORY_LIMIT';
const LOGOUT = 'LOGOUT';

function setSubcategoryQuery(subcategoryQuery) {
  return async (dispatch) => dispatch({
    type: SET_SUBCATEGORY_QUERY,
    payload: {
      subcategoryQuery,
    },
  });
}

function setSubcategorySupermarket(subcategorySupermarket) {
  return async (dispatch) => dispatch({
    type: SET_SUBCATEGORY_SUPERMARKET,
    payload: {
      subcategorySupermarket,
    },
  });
}

function setSubcategoryCategory(subcategoryCategory) {
  return async (dispatch) => dispatch({
    type: SET_SUBCATEGORY_CATEGORY,
    payload: {
      subcategoryCategory,
    },
  });
}

function setSubcategoryPage(subcategoryPage) {
  return async (dispatch) => dispatch({
    type: SET_SUBCATEGORY_PAGE,
    payload: {
      subcategoryPage,
    },
  });
}

function setSubcategoryLimit(subcategoryLimit) {
  return async (dispatch) => dispatch({
    type: SET_SUBCATEGORY_LIMIT,
    payload: {
      subcategoryLimit,
    },
  });
}

function logoutSubcategory() {
  return async (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
}

export {
  SET_SUBCATEGORY_QUERY,
  SET_SUBCATEGORY_SUPERMARKET,
  SET_SUBCATEGORY_CATEGORY,
  SET_SUBCATEGORY_PAGE,
  SET_SUBCATEGORY_LIMIT,
  LOGOUT,
  setSubcategoryQuery,
  setSubcategorySupermarket,
  setSubcategoryCategory,
  setSubcategoryPage,
  setSubcategoryLimit,
  logoutSubcategory,
};
