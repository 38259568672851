/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Divider,
} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import HistoricGraph from './HistoricGraph';

import alphabeticSort from '../../../utils/alphabeticSort';
import useStyles from './styles';

function Results({
  className,
  originalCategories,
  categories,
  setCategories,
  category,
  setCategory,
  subcategories,
  setSubcategories,
  subcategory,
  setSubcategory,
  supermarket,
  setSupermarket,
  supermarkets,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  normalPrices,
  salePrices,
  clubPrices,
  primePrices,
  ...rest
}) {
  const classes = useStyles();

  const handleChange = (type, value) => {
    if (!!value) {
      const ide = parseInt(value.id, 10);
      const first = [{ id: 0, name: 'Todas' }];
      if (type === 'supermarket') {
        setSupermarket(ide);
        setCategory(0);
        setSubcategory(0);
        setSubcategories(first);
        if (ide === 0) {
          setCategories(first);
        } else {
          setCategories(
            first.concat(
              originalCategories.filter(
                (item) => item.supermarket_id === ide,
              ).sort((a, b) => alphabeticSort(a, b)),
            ),
          );
        }
      } else if (type === 'category') {
        setCategory(ide);
        setSubcategory(0);
        if (ide === 0) {
          setSubcategories(first);
        } else {
          setSubcategories(
            first.concat(
              categories.find((item) => item.id === ide)
                .Subcategories.sort((a, b) => alphabeticSort(a, b)),
            ),
          );
        }
      } else if (type === 'subcategory') {
        setSubcategory(ide);
      }
    }
  };

  return (
    <Card
      className={clsx(classes.rootResults, className)}
      {...rest}
    >
      <Divider />
      <Box p={2}>
        <Grid container spacing={1} >
          <Grid item xs={2} md={2}>
            <Autocomplete
              id="supermarkets-autocomplete"
              options={supermarkets}
              disableClearable
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 1, name: 'Jumbo' }}
              onChange={(e, v) => handleChange('supermarket', v)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Supermercado'
                  variant='outlined'
                  value={supermarket}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} md={3}>
            <Autocomplete
              id="categories-autocomplete"
              options={categories}
              disableClearable
              defaultValue={{ id: 0, name: 'Todas' }}
              value={categories.find((cat) => cat.id === category)}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleChange('category', v)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Categoría'
                  variant='outlined'
                />
              )}
            />
          </Grid>
          <Grid item xs={2} md={3}>
            <Autocomplete
              id="subcategories-autocomplete"
              options={subcategories}
              disableClearable
              getOptionLabel={(option) => option.name}
              defaultValue={{ name: 'Todas', id: 0 }}
              value={subcategories.find((subcat) => subcat.id === subcategory)}
              onChange={(e, v) => handleChange('subcategory', v)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Subcategoría'
                  variant='outlined'
                />
              )}
            />
          </Grid>
          <Grid
            item
            md={2}
            xs={2}
            align='right'
            justifyContent="end"
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Desde"
                inputFormat="MM/dd/yyyy"
                value={startDate}
                onChange={(e) => setStartDate(e.valueOf())}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            md={2}
            xs={2}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Hasta"
                inputFormat="MM/dd/yyyy"
                value={endDate}
                onChange={(e) => setEndDate(e.valueOf())}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <HistoricGraph
        normalPrices={normalPrices}
        salePrices={salePrices}
        clubPrices={clubPrices}
        primePrices={primePrices}
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  originalCategories: PropTypes.array,
  categories: PropTypes.array,
  setCategories: PropTypes.func,
  category: PropTypes.number,
  setCategory: PropTypes.func,
  subcategories: PropTypes.array,
  setSubcategories: PropTypes.func,
  subcategory: PropTypes.number,
  setSubcategory: PropTypes.func,
  supermarket: PropTypes.number,
  setSupermarket: PropTypes.func,
  startDate: PropTypes.number,
  setStartDate: PropTypes.func,
  endDate: PropTypes.number,
  setEndDate: PropTypes.func,
  supermarkets: PropTypes.array,
  normalPrices: PropTypes.array,
  salePrices: PropTypes.array,
  clubPrices: PropTypes.array,
  primePrices: PropTypes.array,
};

export default Results;
