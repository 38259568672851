import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../constants/http';

const exportReportComparation = async (
  reportId,
  downloadFrom,
  downloadTo,
  downloadPeriod,
  rawReport,
) => {
  try {
    const response = await axios.get(`/api/reportinstances/comparation/export?reportId=${reportId}&from=${downloadFrom}&to=${downloadTo}&period=${downloadPeriod}&type=${rawReport || ''}`);
    const { data } = response;
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const exportReportIndex = async (
  reportId,
  downloadFrom,
  downloadTo,
  downloadPeriod,
  rawReport,
) => {
  try {
    const response = await axios.get(`/api/reportinstances/index/export?reportId=${reportId}&from=${downloadFrom}&to=${downloadTo}&period=${downloadPeriod}&type=${rawReport || ''}`);
    const { data } = response;
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const exportReportComparationMassive = async (
  downloadFrom,
  downloadTo,
  supermarket,
) => {
  try {
    const response = await axios.get(`/api/reportinstances/comparation/massive/export?from=${downloadFrom}&to=${downloadTo}&supermarket=${supermarket}`);
    const { data } = response;
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data: data.data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: err.response?.data?.message || 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export {
  exportReportComparation,
  exportReportIndex,
  exportReportComparationMassive,
};
