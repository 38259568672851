import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Grid,
  SvgIcon,
  Typography,
  Input,
} from '@material-ui/core';
import {
  X as XIcon,
  PlusCircle as PlusCircleIcon,
  Upload as UploadIcon,
} from 'react-feather';

import { useSnackbar } from 'notistack';
import useStyles from './styles';
import { uploadEngelProductsFile } from '../../../requests/api/engelProducts';

function Header({
  className,
  cleanFilters,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingUpload, setLoadingUpload] = useState(false);

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoadingUpload(true);
    enqueueSnackbar('Subiendo archivo...', {
      variant: 'info',
      autoHideDuration: 5000,
    });

    try {
      const response = await uploadEngelProductsFile(file);
      if (response.success) {
        enqueueSnackbar('Archivo subido exitosamente', {
          variant: 'success',
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar(response?.message || 'Error desconocido', {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('Error al subir el archivo', {
        variant: 'error',
      });
    } finally {
      setLoadingUpload(false);
    }
  };

  return (
    <Grid
      className={clsx(classes.rootHeader, className)}
      container
      justifyContent="space-between"
      spacing={3}
      alignItems="center"
      {...rest}
    >
      <Grid item xs={2} md={7}>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          Productos Engel
        </Typography>
      </Grid>
      <Grid item xs={2} md={2} >
        <Button
          color="secondary"
          variant="contained"
          className={classes.action}
          onClick={() => cleanFilters()}
        >
          <SvgIcon
            fontSize="small"
            className={classes.actionIcon}
          >
            <XIcon />
          </SvgIcon>
          Limpiar filtros
        </Button>
      </Grid>
      <Grid item xs={2} md={3}>
        <Button
          color="secondary"
          variant="contained"
          className={classes.action}
          href="/engel/engel-products/crear"
        >
          <SvgIcon
            fontSize="small"
            className={classes.actionIcon}
          >
            <PlusCircleIcon />
          </SvgIcon>
          Crear producto
        </Button>
      </Grid>
      <Grid item xs={3} md={3} className={classes.actionContainer}>
        <Input
          type="file"
          style={{ display: 'none' }}
          id="upload-file"
          onChange={handleUpload}
        />
        <label htmlFor="upload-file">
          <Button
            color="primary"
            variant="contained"
            className={classes.action}
            component="span"
            disabled={loadingUpload}
          >
            <SvgIcon
              fontSize="small"
              className={classes.actionIcon}
            >
              <UploadIcon />
            </SvgIcon>
            Subir archivo
          </Button>
        </label>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  cleanFilters: PropTypes.func,
};

export default Header;
