import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rootHeader: {},
  rootResults: {},
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  tabSelectionPaper: {
    display: 'flex',
    marginTop: '24px',
    flex: 1,
  },
  tabSelectionItem: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0px',
  },
  tabSelectionItemSelected: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.tertiary,
  },
  tabSelectionItemSelectedText: {
    color: theme.palette.primary.white,
  },
  tabSelectionItemDisabled: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0px',
    backgroundColor: '#BDBDBD',
    color: theme.palette.primary.main,
  },
  tablePagination: {
    display: 'flex',
    backgroundColor: '#f0f1f0',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '10px',
  },
  tableSelectedProduct: {
    display: 'flex',
    backgroundColor: '#f0f1f0',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '10px',
    height: '60px',
    verticalAlign: 'middle',
  },
  orangeButton: {
    backgroundColor: '#F18F01',
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
  },
  tittle: {
    display: 'flex',
    flex: 1,
    paddingTop: '40px',
  },
}));

export default useStyles;
