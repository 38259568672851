import produce from 'immer';
import {
  SET_REPORT_QUERY,
  SET_REPORT_PAGE,
  SET_REPORT_LIMIT,
  LOGOUT,
} from '../actions/reportsList';

const initialState = {
  reportQuery: '',
  reportPage: 0,
  reportLimit: 10,
};

const reportsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORT_QUERY: {
      const { reportQuery } = action.payload;
      return produce(state, (draft) => {
        draft.reportQuery = reportQuery;
      });
    }
    case SET_REPORT_PAGE: {
      const { reportPage } = action.payload;
      return produce(state, (draft) => {
        draft.reportPage = reportPage;
      });
    }
    case SET_REPORT_LIMIT: {
      const { reportLimit } = action.payload;
      return produce(state, (draft) => {
        draft.reportLimit = reportLimit;
      });
    }
    case LOGOUT: {
      return produce(state, (draft) => {
        draft.reportQuery = initialState.reportQuery;
        draft.reportPage = initialState.reportPage;
        draft.reportLimit = initialState.reportLimit;
      });
    }
    default: {
      return state;
    }
  }
};

export default reportsListReducer;
