import axios from 'axios';

async function uploadEngelProductsFile(file) {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.put('/api/engel/products/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    return { success: false, message: error.response?.data?.message || error.message };
  }
}

export {
  // eslint-disable-next-line import/prefer-default-export
  uploadEngelProductsFile,
};
