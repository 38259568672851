import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rootHeader: {},
  rootResults: {},
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  upsertInfoCardContainer: {
    marginTop: '25px',
    marginBottom: '25px',
  },
  upsertInfoCardContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  upsertInfoCardTextfieldContainer: {
    width: '40%',
    marginLeft: '10px',
  },
}));

export default useStyles;
