import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
  CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import alphabeticSort from '../../../utils/alphabeticSort';
import useStyles from './styles';

import { getAllCategoriesAndSubCategories } from '../../../requests/api/categories';
import { getAllSupermarkets } from '../../../requests/api/supermarkets';
import { getProductSnapshotsPrices } from '../../../requests/api/productsnapshots';

const parsePrices = (data) => {
  const parsedPrice = data.map(
    (snap) => ({ x: Date.parse(snap.created_at), y: snap.average_price, count: snap.normal_count }),
  );
  const parsedSalePrice = data.map(
    (snap) => ({
      x: Date.parse(snap.created_at),
      y: snap.average_sale_price,
      count: snap.sale_count,
    }),
  );
  const parsedClubPrice = data.map(
    (snap) => ({
      x: Date.parse(snap.created_at),
      y: snap.average_club_price,
      count: snap.club_count,
    }),
  );
  const parsedPrimePrice = data.map(
    (snap) => ({
      x: Date.parse(snap.created_at),
      y: snap.average_prime_price,
      count: snap.prime_count,
    }),
  );
  return {
    parsedPrice, parsedSalePrice, parsedClubPrice, parsedPrimePrice,
  };
};
function AveragePriceGraphs() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [normalPrices, setNormalPrices] = useState([]);
  const [salePrices, setSalePrices] = useState([]);
  const [clubPrices, setClubPrices] = useState([]);
  const [primePrices, setPrimePrices] = useState([]);
  const today = new Date();
  const [endDate, setEndDate] = useState(today.setHours(23, 59, 59, 999));
  today.setDate(today.getDate() - 7);
  const [supermarket, setSupermarket] = useState(1);
  const [supermarkets, setSupermarkets] = useState([]);
  const [startDate, setStartDate] = useState(today.setHours(0, 0, 0, 0));
  const [subcategory, setSubcategory] = useState(0);
  const [subcategories, setSubcategories] = useState([{ id: 0, name: 'Todas' }]);
  const [category, setCategory] = useState(0);
  const [categories, setCategories] = useState([{ id: 0, name: 'Todas' }]);
  const [originalCategories, setOriginalCategories] = useState([{ id: 0, name: 'Todas' }]);
  const [loading, setLoading] = useState();

  useEffect(() => {
    try {
      const getSupermarkets = async () => {
        const response = await getAllSupermarkets();
        if (response.success) {
          const data = await response?.data?.data;
          setSupermarkets(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getSupermarkets();
    } catch {
      setSupermarkets([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getCategories = async () => {
        const response = await getAllCategoriesAndSubCategories();
        if (response.success) {
          const data = await response?.data?.data;
          const first = [{ id: 0, name: 'Todas' }];
          setCategories(
            first.concat(
              data.filter((cat) => cat.supermarket_id === 1).sort((a, b) => alphabeticSort(a, b)),
            ),
          );
          setOriginalCategories(first.concat(data.sort((a, b) => alphabeticSort(a, b))));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getCategories();
    } catch {
      setOriginalCategories([]);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const getPrices = async () => {
      const response = await getProductSnapshotsPrices(
        startDate, endDate, category, subcategory, supermarket,
      );
      if (response.success) {
        const data = response?.data?.data?.prices;
        const {
          parsedPrice,
          parsedSalePrice,
          parsedClubPrice,
          parsedPrimePrice,
        } = parsePrices(data);
        setNormalPrices(parsedPrice);
        setSalePrices(parsedSalePrice);
        setClubPrices(parsedClubPrice);
        setPrimePrices(parsedPrimePrice);
      } else {
        enqueueSnackbar('Error al cargar la información de los productos.', {
          variant: 'error',
        });
      }
    };
    getPrices();
    setLoading(false);
  }, [startDate, endDate, category, subcategory, supermarket]);

  if (loading) {
    return (<CircularProgress style={{ color: 'var(--brand-blue' }} />);
  }
  if (!normalPrices) {
    return null;
  }
  return (
    <Page
      className={classes.root}
      title="FH Engel | Gráfico Precios"
    >
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
          <Results
            supermarket={supermarket}
            setSupermarket={setSupermarket}
            supermarkets={supermarkets}
            originalCategories={originalCategories}
            categories={categories}
            setCategories={setCategories}
            setCategory={setCategory}
            category={category}
            subcategories={subcategories}
            setSubcategories={setSubcategories}
            setSubcategory={setSubcategory}
            subcategory={subcategory}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            normalPrices={normalPrices}
            salePrices={salePrices}
            clubPrices={clubPrices}
            primePrices={primePrices}
          />
        </Box>
      </Container>
    </Page>
  );
}

AveragePriceGraphs.propTypes = {
  match: PropTypes.any,
};

export default withRouter(AveragePriceGraphs);
