import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
// import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
// import { Redirect } from 'react-router';
// import { postCreateEngelProduct } from '../../../requests/api/engelproducts';

import useStyles from './styles';

import {
  // setEngelProductAllProductsCheck, setEngelProductCreationPage, setEngelProductDetails,
  setEngelProductDetails,
  setEngelProductCreationPage,
} from '../../../actions/engelProductsActions';

function EngelProductCreateForm({
  className,
  ...rest
}) {
  const classes = useStyles();
  // const { enqueueSnackbar } = useSnackbar();
  // const [submitSuccess, setSubmitSuccess] = useState(false);
  const dispatch = useDispatch();
  const {
    engelProductInformation,
  } = useSelector((state) => state.engelProduct);

  return (
    <Formik
      initialValues={{
        description: engelProductInformation.description || '',
        sku: engelProductInformation.sku || '',
        unitsPerProduct: engelProductInformation.unitsPerProduct || '',
      }}
      onSubmit={async (values) => {
        const data = {
          description: values.description,
          sku: values.sku,
          unitsPerProduct: values.unitsPerProduct,
        };
        dispatch(setEngelProductDetails(data));
        dispatch(setEngelProductCreationPage(1));
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
          >
            <Card>
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      helperText={touched.description && errors.description}
                      label="Nombre"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.sku && errors.sku)}
                      fullWidth
                      helperText={touched.sku && errors.sku}
                      label="Sku"
                      name="sku"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.sku}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      error={Boolean(touched.unitsPerProduct && errors.unitsPerProduct)}
                      fullWidth
                      helperText={touched.unitsPerProduct && errors.unitsPerProduct}
                      label="Unidades por producto"
                      name="unitsPerProduct"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.unitsPerProduct}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={3}
                >
                <Grid
                    item
                    md={6}
                    xs={2}
                >
                <Box mt={1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Siguiente
                  </Button>
                </Box>
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={2}
                >
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
      )}
    </Formik>
  );
}

EngelProductCreateForm.propTypes = {
  className: PropTypes.string,
};

export default EngelProductCreateForm;
