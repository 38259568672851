import { useSelector } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

function AuthGuardExternal({ children }) {
  const account = useSelector((state) => state.account);

  if (!account.user) {
    return <Redirect to="/ingreso" />;
  }
  if (account.user.role === 'external') {
    return <Redirect to="/config/products" />;
  }
  return children;
}

AuthGuardExternal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthGuardExternal;
