import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
  SvgIcon,
  Grid,
  TextField,
  Typography,
  MenuItem,
} from '@material-ui/core';

import {
  Trash as TrashIcon,
} from 'react-feather';

import { getProductsByEngelProductId } from '../../../requests/api/engelproducts';
import { getProductInfo } from '../../../requests/api/products';

import useStyles from './styles';

function ComparativeTarget({
  id,
  dataCompProducts,
  setDataCompProducts,
  engelProducts,
  engelProductSelected,
  productBaseSelected,
  setEngelProductSelected,
  setSelected,
  handleDeleteComparation,
}) {
  const classes = useStyles();

  const account = useSelector((state) => state.account);

  const [selectedProductEngel,
    setSelectedProductEngel] = useState(dataCompProducts[id]?.engel_product || []);

  const [selectedBaseAssociated,
    setSelectedBaseAssociated] = useState(dataCompProducts[id]?.base_product || []);

  const [selectedProducts,
    setSelectedProducts] = useState(dataCompProducts[id]?.comp_products || []);

  const [selectedProductsAssociated,
    setSelectedProductsAssociated] = useState([]);

  const [selectedFilteredProductsAssociated,
    setSelectedFilteredProductAssociated] = useState([]);

  const getEngelProductSelectedAssociate = async () => {
    if (selectedProductEngel) {
      const productEngelSelectedId = selectedProductEngel;
      const productsId = await getProductsByEngelProductId(productEngelSelectedId);
      const data = await productsId?.data?.data;
      if (data) {
        const productInfo = [];
        for (let i = 0; i < data.length; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          const resP = await getProductInfo(data[i],
            account.user.id, account.user.role);
          const product = resP?.data?.data;
          if (!productInfo.includes(product)) {
            productInfo.push(product);
          }
        }
        // Associated products diferents than the base
        const productInfoFiltered = productInfo.filter((p) => selectedBaseAssociated !== p.id);
        setSelectedProductsAssociated(productInfo);
        setSelectedFilteredProductAssociated(productInfoFiltered);
      }
    }
  };

  const handleChangeEngelProduct = (event) => {
    const baseEngelSelected = engelProductSelected;
    baseEngelSelected[id] = event.target.value;
    setEngelProductSelected(baseEngelSelected);
    setSelectedProductEngel(event.target.value);
    setSelectedBaseAssociated('');
    setSelected(false);
  };

  const handleChangeBaseProductAssociated = (event) => {
    const baseId = event.target.value;
    setSelectedBaseAssociated(event.target.value);
    setSelectedFilteredProductAssociated(selectedProductsAssociated);
    setSelectedFilteredProductAssociated(
      (prevSelected) => prevSelected.filter((s) => s.id !== baseId),
    );
    setSelectedProducts(selectedProductsAssociated.map((p) => p.id));
    setSelectedProducts((prevSelected) => prevSelected.filter((s) => s !== baseId));
  };

  const handleSelectOneProduct = (event, product) => {
    if (!selectedProducts.includes(product)) {
      setSelectedProducts((prevSelected) => [...prevSelected, product]);
    } else {
      setSelectedProducts((prevSelected) => prevSelected.filter((s) => s !== product));
    }
  };

  const handleSetDataObject = () => {
    if (selectedBaseAssociated !== '') {
      setSelected(true);
    }
    if (selectedProductEngel) {
      const object = {
        engel_product: selectedProductEngel,
        base_product: selectedBaseAssociated,
        comp_products: selectedProducts.map((product) => product),
        // comp_supermarkets: selectedSupermarkets,
      };
      const baseCompProducts = dataCompProducts;
      baseCompProducts[id] = object;
      setDataCompProducts(baseCompProducts);
    }
  };

  useEffect(() => {
    try {
      if (selectedProductEngel && productBaseSelected && selectedFilteredProductsAssociated) {
        getEngelProductSelectedAssociate();
      }
    } catch {
      setSelectedProductEngel();
    }
  }, []);

  useEffect(() => {
    try {
      getEngelProductSelectedAssociate();
    } catch {
      setSelectedProductsAssociated([]);
    }
  }, [selectedProductEngel]);

  useEffect(() => {
    handleSetDataObject();
  }, [selectedProducts]);

  // useEffect(() => {
  //   handleSetDataObject();
  // }, [selectedSupermarkets]);

  // if (!selectedProductEngel) {
  //   return null;
  // }

  return (
    <Box>
      <Box>
      <Typography
        variant="h5"
        className={classes.title}
      >
        Comparación {id + 1}
      </Typography>
      <Card className={classes.card}>
        <CardContent>
        <Grid
          item
          md={12}
          xs={6}
          className={classes.gridSelectEngelProducts}
        >
          <TextField
            fullWidth
            name="engelProducts"
            value={selectedProductEngel}
            onChange={handleChangeEngelProduct}
            select
            variant="outlined"
            label='Producto Engel'
            InputLabelProps={{ shrink: true }}
          >
            {engelProducts.map((product) => (
              <MenuItem
                value={product.id}
                key={product.id}
              >
                { product.description }
              </MenuItem>
            ))}
          </TextField>
          </Grid>
          <Grid
          item
          md={12}
          xs={6}
          className={classes.gridSelectEngelProducts}
        >
          <TextField
            fullWidth
            name="baseProduct"
            value={selectedBaseAssociated}
            onChange={handleChangeBaseProductAssociated}
            select
            variant="outlined"
            label='Producto Homologado Base'
            InputLabelProps={{ shrink: true }}
          >
            {selectedProductsAssociated.map((product) => (
              <MenuItem
                value={product.id}
                key={product.id}
              >
                { product.description } - { product.brand } - { product.supermarket_name }
              </MenuItem>
            ))}
          </TextField>
          </Grid>
        {/* <Typography
          variant="h5"
          className={classes.subtitle}
        >
        Supermercados
        </Typography>
        <FormGroup aria-label="position" row>
        {supermarkets.map((supermarket) => (
          <FormControlLabel
            key = {supermarket.id}
            value={supermarket.id}
            control={<Checkbox checked={selectedSupermarkets.includes(supermarket.id)} />}
            label={supermarket.name}
            labelPlacement="end"
            className={classes.checkbox}
            onChange={(event) => handleSelectOneSupermarket(event, supermarket.id)}
          />
        ))}
        </FormGroup> */}
        <Typography
          variant="h5"
          className={classes.subtitle}
        >
        Productos Asociados
        </Typography>
        <FormGroup aria-label="position" row>
          {selectedFilteredProductsAssociated.map((product) => (
            <FormControlLabel
              key={product.id}
              value={product.id}
              control={<Checkbox checked={selectedProducts.includes(product.id)}/>}
              label={`${product.description} - ${product.supermarket_name}`}
              labelPlacement="end"
              className={classes.checkbox}
              onChange={(event) => handleSelectOneProduct(event, product.id)}
            />
          ))}
        </FormGroup>
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="left"
            display='inline-block'
          >
        <IconButton
            color="inherit"
            onClick={() => handleDeleteComparation(id)}
            className={classes.deleteComparationButton}
          >
            <SvgIcon fontSize="medium">
              <TrashIcon className={classes.menuIcon} />
            </SvgIcon>
        </IconButton>
        </Grid>
        </CardContent>
      </Card>
      </Box>
    </Box>

  );
}

ComparativeTarget.propTypes = {
  id: PropTypes.number,
  report: PropTypes.object,
  dataCompProducts: PropTypes.array,
  setDataCompProducts: PropTypes.func,
  engelProducts: PropTypes.array,
  engelProductSelected: PropTypes.array,
  productBaseSelected: PropTypes.string,
  setEngelProductSelected: PropTypes.func,
  setSelected: PropTypes.func,
  handleDeleteComparation: PropTypes.func,
};

export default ComparativeTarget;
