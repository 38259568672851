/* eslint-disable react/display-name */
import React, { Fragment, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthGuard from './components/Auth/AuthGuard';
import AuthGuardExternal from './components/Auth/AuthRoles';
import DashboardLayout from './components/Navigation';
import LoadingScreen from './components/Loading/LoadingScreen';

import Login from './views/Login';
import Verification from './views/Verification';
import PasswordRecovery from './views/PasswordRecovery';

import UserListView from './views/Admin/Users/UserListView';
import UserCreateView from './views/Admin/Users/UserCreateView';
import UserEditView from './views/Admin/Users/UserEditView';

import ProductGraphs from './views/Products/ProductGraphs';
import ProductListView from './views/Products/ProductList';
import ProductEditView from './views/Products/ProductEdit';

import EngelProductConfigView from './views/EngelProducts/EngelProductConfig';
import EngelProductEditView from './views/EngelProducts/EngelProductEdit';
import EngelProductCreateView from './views/EngelProducts/EngelProductCreate';
import EngelProductGraphs from './views/EngelProducts/EngelProductGraphs';

import AveragePriceGraphs from './views/ProductSnapshots/AveragePriceGraph';
import ProductsnapshotListView from './views/ProductSnapshots/SnapsListView';

import CategoriesListView from './views/Categories/CategoriesListView';
import CategoryEditView from './views/Categories/CategoryEdit';
import CategoryCreateView from './views/Categories/CategoryCreate';

import SubcategoriesListView from './views/Subcategories/SubcategoriesListView';
import SubcategoryEditView from './views/Subcategories/SubcategoryEdit';
import SubcategoryCreateView from './views/Subcategories/SubcategoryCreate';

import ScraperStatistics from './views/Scraper/ScraperStatistics';
import SendEmailView from './views/PasswordRecovery/SendEmail';

import ReportListView from './views/Reports/ReportList';
import ReportCreateComparativeView from './views/Reports/ReportCreateComparative';
import ReportEditComparativeView from './views/Reports/ReportEditComparative';
import ReportCopyComparativeView from './views/Reports/ReportCopyComparative';
import ReportCreateHistoricalView from './views/Reports/ReportCreateHistorical';
import ReportCreateIndexView from './views/Reports/ReportCreateIndex';
import ReportEditIndexView from './views/Reports/ReportEditIndex';
import AlarmsView from './views/Alarms';

const routesConfig = [
  {
    id: 1,
    exact: true,
    path: '/',
    component: () => <Redirect to="/administracion/users" />,
  },
  {
    id: 2,
    exact: true,
    path: '/ingreso',
    component: () => <Login />,
  },
  {
    id: 3,
    exact: true,
    path: '/user-verification/:token',
    component: () => <Verification />,
  },
  {
    id: 4,
    exact: true,
    path: '/reset-password/:token',
    component: () => <PasswordRecovery />,
  },
  {
    id: 5,
    exact: true,
    path: '/reset-password?token=:token',
    component: () => <Redirect to="/reset-password/:token" />,
  },
  {
    id: 6,
    exact: true,
    path: '/recover-password',
    component: () => <SendEmailView />,
  },
  {
    path: '/administracion',
    guard: AuthGuardExternal,
    layout: DashboardLayout,
    routes: [
      {
        id: 11,
        exact: true,
        path: '/administracion',
        component: () => <Redirect to="/administracion/users" />,
      },
      {
        id: 12,
        exact: true,
        path: '/administracion/users',
        component: () => <UserListView />,
      },
      {
        id: 13,
        exact: true,
        path: '/administracion/users/crear',
        component: () => <UserCreateView />,
      },
      {
        id: 14,
        exact: true,
        path: '/administracion/users/:id/editar',
        component: () => <UserEditView />,
      },
      {
        id: 70,
        exact: true,
        path: '/administracion/reportes',
        component: () => <ReportListView />,
      },
      {
        id: 71,
        exact: true,
        path: '/administracion/reportes/crear/comparativo',
        component: () => <ReportCreateComparativeView />,
      },
      {
        id: 72,
        exact: true,
        path: '/administracion/reportes/crear/index',
        component: () => <ReportCreateIndexView />,
      },
      {
        id: 73,
        exact: true,
        path: '/administracion/reportes/crear/historico',
        component: () => <ReportCreateHistoricalView />,
      },
      {
        id: 74,
        exact: true,
        path: '/administracion/reportes/:id/editar/comparativo',
        component: () => <ReportEditComparativeView />,
      },
      {
        id: 75,
        exact: true,
        path: '/administracion/reportes/:id/editar/index',
        component: () => <ReportEditIndexView />,
      },
      {
        id: 76,
        exact: true,
        path: '/administracion/reportes/:id/copiar/comparativo',
        component: () => <ReportCopyComparativeView />,
      },
      {
        id: 15,
        component: () => <Redirect to="/administracion/users" />,
      },
    ],
  },
  {
    path: '/datos',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        id: 16,
        exact: true,
        path: '/datos/productsnapshots',
        component: () => <ProductsnapshotListView />,
      },
      {
        id: 17,
        exact: true,
        path: '/datos/products/:id/graphs',
        component: () => <ProductGraphs />,
      },
      {
        id: 18,
        exact: true,
        path: '/datos/products/:id/editar',
        component: () => <ProductEditView />,
      },
      {
        id: 20,
        exact: true,
        path: '/datos/productos',
        component: () => <ProductListView />,
      },
    ],
  },
  {
    path: '/graphs',
    guard: AuthGuardExternal,
    layout: DashboardLayout,
    routes: [
      {
        id: 19,
        exact: true,
        path: '/graphs/avg-price',
        component: () => <AveragePriceGraphs />,
      },
    ],
  },
  {
    path: '/config',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        id: 21,
        exact: true,
        path: '/config/categories',
        component: () => <CategoriesListView />,
      },
      {
        id: 22,
        exact: true,
        path: '/config/categories/crear',
        component: () => <CategoryCreateView />,
      },
      {
        id: 23,
        exact: true,
        path: '/config/category/:id/edit',
        component: () => <CategoryEditView />,
      },
      {
        id: 24,
        exact: true,
        path: '/config/subcategories',
        component: () => <SubcategoriesListView />,
      },
      {
        id: 25,
        exact: true,
        path: '/config/subcategories/crear',
        component: () => <SubcategoryCreateView />,
      },
      {
        id: 26,
        exact: true,
        path: '/config/subcategory/:id/edit',
        component: () => <SubcategoryEditView />,
      },
      {
        id: 27,
        exact: true,
        path: '/config/alarms',
        component: () => <AlarmsView />,
      },
    ],
  },
  {
    path: '/engel',
    guard: AuthGuardExternal,
    layout: DashboardLayout,
    routes: [
      {
        id: 27,
        exact: true,
        path: '/engel/engel-products/',
        component: () => <EngelProductConfigView />,
      },
      {
        id: 28,
        exact: true,
        path: '/engel/engel-products/:id/edit',
        component: () => <EngelProductEditView />,
      },
      {
        id: 29,
        exact: true,
        path: '/engel/engel-products/crear',
        component: () => <EngelProductCreateView />,
      },
      {
        id: 30,
        exact: true,
        path: '/engel/engel-products/:id/graphs',
        component: () => <EngelProductGraphs />,
      },
      {
        id: 31,
        exact: true,
        path: '/engel/scraper',
        component: () => <ScraperStatistics />,
      },
    ],
  },
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
