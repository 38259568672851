import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  textField: {
    '& .MuiInputBase-input': {
      height: '20px',
    },
  },
}));

export default useStyles;
