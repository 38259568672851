import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Typography,
  Grid,
} from '@material-ui/core';

import useStyles from './styles';

const numberFormat = new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' });

function Indicators({
  className,
  product,
  normalMin,
  normalMax,
  saleMin,
  saleMax,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justifyContent="space-between"
      spacing={0}
      align="center"
      {...rest}
    >
      <Grid item xs={3}>
        <Typography
          variant="h6"
          color="textSecondary"
        >
          Normal más alto
        </Typography>
        <Typography
          variant="h4"
          color="textPrimary"
        >
          {parseInt(normalMax, 10) > 0 ? numberFormat.format(normalMax) : '-'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          variant="h6"
          color="textSecondary"
        >
          Normal más bajo
        </Typography>
        <Typography
          variant="h4"
          color="textPrimary"
        >
          {parseInt(normalMin, 10) < 9999999 ? numberFormat.format(normalMin) : '-'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          variant="h6"
          color="textSecondary"
        >
          Oferta más alto
        </Typography>
        <Typography
          variant="h4"
          color="textPrimary"
        >
          {parseInt(saleMax, 10) > 0 ? numberFormat.format(saleMax) : '-'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          variant="h6"
          color="textSecondary"
        >
          Oferta más bajo
        </Typography>
        <Typography
          variant="h4"
          color="textPrimary"
        >
          {parseInt(saleMin, 10) < 9999999 ? numberFormat.format(saleMin) : '-'}
        </Typography>
      </Grid>
    </Grid>
  );
}

Indicators.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object,
  normalMin: PropTypes.number,
  normalMax: PropTypes.number,
  saleMin: PropTypes.number,
  saleMax: PropTypes.number,
};

export default Indicators;
