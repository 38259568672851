/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Tooltip from '@mui/material/Tooltip';
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Settings as SettingsIcon,
  XCircle as XCircleIcon,
  ArrowRightCircle as ArrowIcon,
} from 'react-feather';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';

import {
  setCategoryQuery,
  setCategoryPage,
  setCategoryLimit,
  setCategorySupermarket,
} from '../../../actions/categoriesList';

import {
  setSubcategoryQuery,
  setSubcategoryCategory,
  setSubcategoryPage,
  setSubcategoryLimit,
  setSubcategorySupermarket,
} from '../../../actions/subcategoriesList';

import useStyles from './styles';

function Results({
  className,
  categories,
  supermarkets,
  count,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentCategories, setCurrentCategories] = useState(categories);

  const {
    categorySupermarket,
    categoryPage,
    categoryLimit,
    categoryQuery,
  } = useSelector((state) => state.categoriesList);

  const [currentQuery, setCurrentQuery] = useState(categoryQuery);

  useEffect(() => { setCurrentCategories(categories); }, [categories]);

  const handleQueryChange = (event) => {
    event.persist();
    setCurrentQuery(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    dispatch(setCategoryPage(newPage));
  };

  const handleLimitChange = (event) => {
    dispatch(setCategoryLimit(event.target.value));
  };

  const handleChange = (type, newVal) => {
    if (!!newVal) {
      dispatch(setCategoryPage(0));
      if (type === 'supermarket') {
        dispatch(setCategorySupermarket(newVal));
      }
    }
  };

  const pageChange = (cat) => {
    const category = { id: cat.id, name: cat.name, supermarket_id: cat.Supermarket.id };
    const supermarket = cat.Supermarket;
    dispatch(setSubcategoryQuery(''));
    dispatch(setSubcategoryCategory([category]));
    dispatch(setSubcategoryPage(0));
    dispatch(setSubcategoryLimit(10));
    dispatch(setSubcategorySupermarket([supermarket]));
  };

  if (categories.length === 0) {
    return null;
  }

  return (
    <Card
      className={clsx(classes.rootResults, className)}
      {...rest}
    >
      <Divider />
      <Box
        p={2}
        minHeight={56}
      >
        <Grid container spacing={1} >
          <Grid item xs={2} md={4}>
            <TextField
              className={classes.queryField}
              onChange={handleQueryChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
                endAdornment: currentQuery ? (
                  <IconButton size="small" onClick={() => {
                    setCurrentQuery('');
                    dispatch(setCategoryQuery(''));
                    dispatch(setCategoryPage(0));
                  }}>
                    <XCircleIcon />
                  </IconButton>
                ) : null,
              }}
              placeholder="Buscar por Categoría"
              value={currentQuery}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={0.5} md={0.5}>
            <Button
              color="secondary"
              variant="contained"
              className={classes.searchAction}
              onClick={() => {
                dispatch(setCategoryQuery(currentQuery));
                dispatch(setCategoryPage(0));
              }}
            >
              <SvgIcon >
                <SearchIcon />
              </SvgIcon>
            </Button>
          </Grid>
          <Grid item xs={2} md={7.5} align='right'>
            {supermarkets.length > 0 && <Autocomplete
              id="supermarkets-autocomplete"
              className={classes.options}
              options={supermarkets}
              multiple
              value={categorySupermarket}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleChange('supermarket', v)}
              limitTags={1}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Supermercados'
                  variant='outlined'
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
            />
            }
          </Grid>
        </Grid>
      </Box>

      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                  Categoría
                </TableCell>
                <TableCell align='center'>
                  Supermercado
                </TableCell>
                <TableCell align='center'>
                  Subcategorías asociadas
                </TableCell>
                <TableCell align='center'>
                  Productos asociados
                </TableCell>
                <TableCell align='center'>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentCategories.map((category) => (
                  <TableRow
                    hover
                    key={category.id}
                  >
                    <TableCell>{category.name}</TableCell>
                    <TableCell align='center'>{category.Supermarket.name}</TableCell>
                    <TableCell align='center'>{category.Subcategories.length}</TableCell>
                    <TableCell align='center'>{category.Products.length}</TableCell>
                    <TableCell align='center'>
                      <Tooltip title="Editar Categoría">
                        <IconButton
                          component={RouterLink}
                          to={`/config/category/${category.id}/edit`}
                        >
                          <SvgIcon fontSize="small">
                            <SettingsIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Ver subcategorías asociadas">
                        <IconButton
                          component={RouterLink}
                          to={'/config/subcategories'}
                          onClick={() => pageChange(category)}
                        >
                          <SvgIcon fontSize="small">
                            <ArrowIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={(categoryPage > 0 && currentCategories.length < categoryLimit) ? 0 : categoryPage}
        rowsPerPage={categoryLimit}
        rowsPerPageOptions={[5, 10, 25, 50]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array,
  supermarkets: PropTypes.array,
  count: PropTypes.number,
};

Results.defaultProps = {
  categories: [],
};

export default Results;
