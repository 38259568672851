/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Typography,
  SvgIcon,
  MenuItem,
} from '@material-ui/core';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import _ from 'lodash';

import {
  PlusCircle as PlusCircleIcon,
} from 'react-feather';
import ComparativeTarget from './ComparativeTarget';
import { getAllEngelProducts } from '../../../requests/api/engelproducts';
import { postCreateComparativeReport } from '../../../requests/api/reports';
import { postCreateEmailReport } from '../../../requests/api/emailreports';

import useStyles from './styles';

function ReportCopyComparativeForm({
  report,
  dataCompProducts,
  engelProductSelected,
  productBaseSelected,
  totalComparation,
  comparations,
  setComparations,
  setDataCompProducts,
  setEngelProductSelected,
  setTotalComparation,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const account = useSelector((state) => state.account);

  const [openPeriodicSend, setOpenPeriodicSend] = useState(false);
  const [openSaveReport, setOpenSaveReport] = useState(false);

  const [engelProducts, setEngelProducts] = useState([]);

  const [selected, setSelected] = useState(false);

  const getEngelProducts = async () => {
    const userRole = account.user.role;
    const userId = account.user.id;
    const response = await getAllEngelProducts(userRole,
      userId, false);
    const data = await response?.data?.data;
    if (data.totalProducts > 0) {
      setEngelProducts(data.products);
    }
  };

  const handleAddComparation = () => {
    const object = {
      engel_product: '',
      base_product: '',
      comp_products: [],
      comp_supermarkets: [1, 2, 3],
    };
    setDataCompProducts((prevSelected) => [...prevSelected, object]);
    setEngelProductSelected((prevSelected) => [...prevSelected, []]);
    setTotalComparation([...totalComparation, comparations]);
    setComparations(totalComparation + 1);
    setSelected(false);
  };

  const handleOpenPeriodicSend = () => {
    setOpenPeriodicSend(true);
  };

  const handleClosePeriodicSend = () => {
    setOpenPeriodicSend(false);
  };

  const handleSaveReport = () => {
    setOpenSaveReport(true);
  };
  const handleCloseSaveReport = () => {
    setOpenSaveReport(false);
  };

  const handleDeleteComparation = (index) => {
    const newDataComps = dataCompProducts.filter((data, idx) => idx !== index);
    const newProductSelected = engelProductSelected.filter((data, idx) => idx !== index);
    setDataCompProducts(newDataComps);
    setEngelProductSelected(newProductSelected);
    setTotalComparation(totalComparation.filter((data, idx) => data !== index));
  };

  const handleSubmitUpdateReport = async (values) => {
    try {
      const contentBase = [];
      for (let i = 0; i < engelProductSelected.length; i += 1) {
        contentBase.push(dataCompProducts[i]);
      }
      const objectReport = {
        id: report.id,
        title: values.title,
        dateFrom: values.from,
        dateTo: values.to,
        dateToSend: values.dateToSend,
        // emails: values.emails,
        type: values.type,
        userId: account.user.id,
        frequency: values.frequency,
        range: values.range,
        content: JSON.stringify(contentBase),
      };
      const response = await postCreateComparativeReport(objectReport);
      if (response.success) {
        if (values.emails !== '') {
          const objectEmailsReports = {
            emails: values.emails.split(','),
            reportId: response.data.id,
          };
          const responseEmails = await postCreateEmailReport(objectEmailsReports);
          if (responseEmails.success) {
            enqueueSnackbar(responseEmails.message, {
              variant: 'success',
            });
            setSubmitSuccess(true);
          } else {
            enqueueSnackbar(responseEmails.message, {
              variant: 'warning',
              action: <Button href="/administracion/reportes">Volver atrás</Button>,
            });
          }
        } else {
          setSubmitSuccess(true);
          enqueueSnackbar(response.message, {
            variant: 'success',
          });
        }
      } else {
        enqueueSnackbar(response.message, {
          variant: 'warning',
          action: <Button href="/administracion/reportes">Volver atrás</Button>,
        });
      }
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'warning',
      });
    }
  };

  // const handleSubmitProgramSaveReport = async (values) => {
  //   try {
  //     const contentBase = [];
  //     for (let i = 0; i < engelProductSelected.length; i += 1) {
  //       contentBase.push(dataCompProducts[i]);
  //     }
  //     const objectReport = {
  //       title: values.title,
  //       dateFrom: values.from,
  //       dateTo: values.to,
  //       dateToSend: values.dateToSend,
  //       type: values.type,
  //       frequency: values.frequency,
  //       range: values.range,
  //       content: JSON.stringify(contentBase),
  //     };
  //     const response = await postCreateComparativeReport(objectReport);
  //     if (response.success) {
  //       setSubmitSuccess(true);
  //       enqueueSnackbar(response.message, {
  //         variant: 'success',
  //       });
  //     } else {
  //       enqueueSnackbar(response.message, {
  //         variant: 'warning',
  //         action: <Button href="/administracion/reportes">Volver atrás</Button>,
  //       });
  //     }
  //   } catch (err) {
  //     enqueueSnackbar(err.message, {
  //       variant: 'warning',
  //     });
  //   }
  //   // ACA DEBERIAMOS ADEMAS CREAR LOS EMAIL-REPORTS
  // };

  useEffect(() => {
    try {
      getEngelProducts();
      if (report) {
        setDataCompProducts(JSON.parse(report.content));
      }
    } catch {
      setEngelProducts([]);
    }
  }, []);

  if (submitSuccess) {
    return <Redirect to="/administracion/reportes" />;
  }
  if (account.user.id !== report.user_id) {
    return <Redirect to="/administracion/reportes" />;
  }

  return (
    <Formik
      initialValues={{
        title: report.title || '',
        frequency: report.frequency || '',
        emails: report.emails || '',
        range: report.range || '7',
        dateToSend: report.date_to_send?.split('T')[0] || '',
        type: 'Comparativo',
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(255).required('Debe ingresar un título'),
        emails: Yup.lazy(() => {
          if (openPeriodicSend === true) {
            return Yup.string().max(255).required('Debe ingresar emails separados por coma');
          }
          return Yup.string().max(255);
        }),
        frequency: Yup.lazy(() => {
          if (openPeriodicSend === true) {
            return Yup.string().max(255).required('Debe seleccionar una frequencia');
          }
          return Yup.string().max(255);
        }),
        range: Yup.lazy(() => {
          if (openPeriodicSend === true) {
            return Yup.string().max(255).required('Debe seleccionar un rango');
          }
          return Yup.string().max(255);
        }),
        dateToSend: Yup.lazy(() => {
          if (openPeriodicSend === true) {
            return Yup.date().required('Debe seleccionar una fecha');
          }
          return Yup.date().max(255);
        }),
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // if (openSaveReport) {
          //   handleSubmitUpdateReport(values);
          // } else {
          //   handleSubmitUpdateReport(values);
          // }
          handleSubmitUpdateReport(values);
          handleSaveReport(values);
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
    {({
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      touched,
      values,
    }) => (
    <form
      onSubmit={handleSubmit}
    >
    <Box>
      <Typography
        variant="h5"
        className={classes.title}
      >
        Título del reporte
      </Typography>
      <Card className={classes.card}>
        <CardContent>
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              fullWidth
              name="title"
              // onChange={handleChangeTitleReport}
              value={values.title}
              required
              // value={reportTitle}
              variant="outlined"
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Grid>
          <Grid item />
        </CardContent>
      </Card>
      {totalComparation.map((i) => (
      <ComparativeTarget
        id={i}
        key={i}
        report={report}
        dataCompProducts={dataCompProducts}
        setDataCompProducts={setDataCompProducts}
        setEngelProductSelected={setEngelProductSelected}
        productBaseSelected={productBaseSelected}
        engelProducts={engelProducts}
        engelProductSelected={engelProductSelected}
        setSelected={setSelected}
        handleDeleteComparation={handleDeleteComparation}
      />
      ))}
      <Card className={classes.card}>
        <CardContent
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
          >
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={handleAddComparation}
          >
            <SvgIcon fontSize="large">
              <PlusCircleIcon className={classes.menuIcon} />
            </SvgIcon>
          </IconButton>
          <Typography
            variant="h5"
          >
            Crear nueva comparación
          </Typography>
          </Grid>
        </CardContent>
      </Card>
      <Box display='flex' paddingLeft='65%'>
        <Box
          mr={2}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenPeriodicSend}
            disabled={!selected}
          >
            Programar envío periódico
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            className={classes.orangeButton}
            onClick={handleSubmit}
            disabled={!selected}
          >
            Crear reporte
          </Button>
        </Box>
      </Box>
      <Dialog
        open={openPeriodicSend}
        onClose={handleClosePeriodicSend}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.createDialog }}
        disableTypography
      >
        <DialogTitle disableTypography>
          <Typography className={classes.createDialogTitle}>Programar envío periódico</Typography>
        </DialogTitle>
        <Grid
            item
          >
            <TextField
              fullWidth
              label="Frecuencia de envío"
              name="frequency"
              value={values.frequency}
              // onChange={handleChangeFrequency}
              select
              variant="outlined"
              required={openPeriodicSend}
              className={classes.createDialogTexfield}
              InputLabelProps={{ shrink: true }}
              error={Boolean(touched.frequency && errors.frequency)}
              helperText={touched.frequency && errors.frequency}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <MenuItem
                value="1"
              >
                Diaria
              </MenuItem>
              <MenuItem
                value="7"
              >
                Semanal
              </MenuItem>
              <MenuItem
                value="30"
              >
                Mensual
              </MenuItem>
            </TextField>
            <TextField
              fullWidth
              label="Correos electrónicos"
              name="emails"
              value={values.emails}
              // onChange={(e, v) => handleSelectReportType(v)}
              variant="outlined"
              required
              className={classes.createDialogTexfield}
              InputLabelProps={{ shrink: true }}
              error={Boolean(touched.emails && errors.emails)}
              helperText={touched.emails && errors.emails}
              onBlur={handleBlur}
              onChange={handleChange}
            >
            </TextField>
            <TextField
              fullWidth
              label="Tiempo considerado en el reporte"
              name="range"
              value={values.range}
              select
              variant="outlined"
              required={openPeriodicSend}
              className={classes.createDialogTexfield}
              InputLabelProps={{ shrink: true }}
              error={Boolean(touched.range && errors.range)}
              helperText={touched.range && errors.range}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled
            >
              <MenuItem
                value="7"
              >
                Última semana
              </MenuItem>
            </TextField>
            <Grid>
              <TextField
                fullWidth
                name="dateToSend"
                label='Enviar a partir del'
                value={values.dateToSend}
                onChange={handleChange}
                type='date'
                variant="outlined"
                required
                className={classes.createDialogTexfield}
                InputLabelProps={{ shrink: true }}
              >
              </TextField>
            </Grid>
          </Grid>
        <DialogActions>
          <Button
            className={classes.createDialogButtons}
            onClick={handleClosePeriodicSend}>Cancelar
          </Button>
          <Button
            className={classes.createDialogButtons}
            type='submit'
            onClick={handleSubmit}
          >
            Programar y crear reporte
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </form>
    )}
    </Formik>
  );
}

ReportCopyComparativeForm.propTypes = {
  className: PropTypes.string,
  report: PropTypes.object,
  dataCompProducts: PropTypes.object,
  engelProductSelected: PropTypes.array,
  productBaseSelected: PropTypes.array,
  totalComparation: PropTypes.array,
  setDataCompProducts: PropTypes.func,
  setEngelProductSelected: PropTypes.func,
  setTotalComparation: PropTypes.func,
  comparations: PropTypes.number,
  setComparations: PropTypes.func,
};

export default ReportCopyComparativeForm;
