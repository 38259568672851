import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import SubcategoryCreateForm from './SubcategoryCreateForm';

import { getAllCategories } from '../../../requests/api/categories';
import { getAllSupermarkets } from '../../../requests/api/supermarkets';

import alphabeticSort from '../../../utils/alphabeticSort';
import useStyles from './styles';

function SubcategoryCreateView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [supermarkets, setSupermarkets] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    try {
      const getSupermarkets = async () => {
        const response = await getAllSupermarkets();
        if (response.success) {
          const data = await response?.data?.data;
          setSupermarkets(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getSupermarkets();
    } catch {
      setSupermarkets([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getCategories = async () => {
        const response = await getAllCategories();
        if (response.success) {
          const data = await response?.data?.data;
          setCategories(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getCategories();
    } catch {
      setCategories([]);
    }
  }, []);

  return (
    <Page
      className={classes.root}
      title="FH Engel | Agregar Subcategoría"
    >
      <Container maxWidth={false}>
        <Header />
        <SubcategoryCreateForm
          supermarkets={supermarkets}
          categories={categories}
        />
      </Container>
    </Page>
  );
}

export default SubcategoryCreateView;
