import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Grid,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import {
  PlusCircle as PlusCircleIcon,
  X as XIcon,
} from 'react-feather';

import useStyles from './styles';

function Header({
  className,
  cleanFilters,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.rootHeader, className)}
      container
      justifyContent="space-between"
      spacing={3}
      alignItems="center"
      {...rest}
    >
      <Grid item xs={2} md={7}>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          Categorías
        </Typography>
      </Grid>
      <Grid item xs={2} md={2} >
        <Button
          color="secondary"
          variant="contained"
          className={classes.action}
          onClick={() => cleanFilters()}
        >
          <SvgIcon
            fontSize="small"
            className={classes.actionIcon}
          >
            <XIcon />
          </SvgIcon>
          Limpiar filtros
        </Button>
      </Grid>
      <Grid item xs={2} md={3}>
        <Button
          color="secondary"
          variant="contained"
          className={classes.action}
          href="/config/categories/crear"
        >
          <SvgIcon
            fontSize="small"
            className={classes.actionIcon}
          >
            <PlusCircleIcon />
          </SvgIcon>
          Agregar Categoría
        </Button>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  cleanFilters: PropTypes.func,
};

export default Header;
