import produce from 'immer';
import {
  SET_SUBCATEGORY_QUERY,
  SET_SUBCATEGORY_CATEGORY,
  SET_SUBCATEGORY_PAGE,
  SET_SUBCATEGORY_LIMIT,
  SET_SUBCATEGORY_SUPERMARKET,
  LOGOUT,
} from '../actions/subcategoriesList';

const initialState = {
  subcategoryQuery: '',
  subcategoryCategory: [],
  subcategorySupermarket: [],
  subcategoryPage: 0,
  subcategoryLimit: 10,
};

const subcategoriesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUBCATEGORY_QUERY: {
      const { subcategoryQuery } = action.payload;
      return produce(state, (draft) => {
        draft.subcategoryQuery = subcategoryQuery;
      });
    }
    case SET_SUBCATEGORY_SUPERMARKET: {
      const { subcategorySupermarket } = action.payload;
      return produce(state, (draft) => {
        draft.subcategorySupermarket = subcategorySupermarket;
      });
    }
    case SET_SUBCATEGORY_CATEGORY: {
      const { subcategoryCategory } = action.payload;
      return produce(state, (draft) => {
        draft.subcategoryCategory = subcategoryCategory;
      });
    }
    case SET_SUBCATEGORY_PAGE: {
      const { subcategoryPage } = action.payload;
      return produce(state, (draft) => {
        draft.subcategoryPage = subcategoryPage;
      });
    }
    case SET_SUBCATEGORY_LIMIT: {
      const { subcategoryLimit } = action.payload;
      return produce(state, (draft) => {
        draft.subcategoryLimit = subcategoryLimit;
      });
    }
    case LOGOUT: {
      return produce(state, (draft) => {
        draft.subcategoryQuery = initialState.subcategoryQuery;
        draft.subcategorySupermarket = initialState.subcategorySupermarket;
        draft.subcategoryCategory = initialState.subcategoryCategory;
        draft.subcategoryPage = initialState.subcategoryPage;
        draft.subcategoryLimit = initialState.subcategoryLimit;
      });
    }
    default: {
      return state;
    }
  }
};

export default subcategoriesListReducer;
