import React, {
  useRef,
  useState,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';

import useStyles from './styles';

const { logout } = require('../../../actions/accountActions');
const { logoutCategory } = require('../../../actions/categoriesList');
const { logoutSnapshots } = require('../../../actions/snapsList');
const { logoutSubcategory } = require('../../../actions/subcategoriesList');
const { logoutProduct } = require('../../../actions/productsList');
const { logoutEngelProduct } = require('../../../actions/engelProductsList');

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      await dispatch(logoutCategory());
      await dispatch(logoutSnapshots());
      await dispatch(logoutSubcategory());
      await dispatch(logoutProduct());
      await dispatch(logoutEngelProduct());
      history.push('/');
    } catch (error) {
      enqueueSnackbar('No se pudo cerrar sesión.', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
        />
        <Hidden smDown>
          <Typography
            variant="h6"
            className={classes.textColor}
          >
            {`${account.user.name}`}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          component={RouterLink}
          to="/"
        >
          Cuenta
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          Cerrar Sesión
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
