import React, {
  useState,
  useEffect,
  useRef,
} from 'react';

import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { CircularProgress } from '@material-ui/core';
import alphabeticSort from '../../../utils/alphabeticSort';

function CustomAutoComplete({
  label,
  options,
  selectedOptions,
  onOptionsChange,
  dataLoad,
  setBrands,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const searchTimeoutRef = useRef(null);

  useEffect(() => {
    if (dataLoad) {
      dataLoad({
        searchTerm,
        page,
        setLoading,
      });
    }
  }, [dataLoad, page, searchTerm]);

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setPage(0);
    setBrands([]);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      setSearchTerm(newSearchTerm);
    }, 500);
  };

  const handleScroll = (event) => {
    const bottom = event.target.scrollHeight / 2
     <= event.target.scrollTop + event.target.clientHeight;
    if (bottom) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => () => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
  }, []);

  return (
      <>
        <Autocomplete
          onBlur={() => {
            setPage(0);
            setSearchTerm('');
          }}
          options={options.sort(alphabeticSort)}
          multiple
          value={selectedOptions}
          getOptionLabel={(option) => option.name}
          onChange={onOptionsChange}
          limitTags={1}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              key={params.id}
              {...params}
              label={label}
              variant='outlined'
              onChange={handleSearchChange}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                key={option.id}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          ListboxProps={{
            onScroll: handleScroll,
          }}
          disablePortal
        />
      </>
  );
}

CustomAutoComplete.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  onOptionsChange: PropTypes.func.isRequired,
  dataLoad: PropTypes.func,
  setBrands: PropTypes.func,
};

export default CustomAutoComplete;
