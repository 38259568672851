import axios from '../../utils/axios';

const postLogin = async ({
  email, password,
}) => {
  try {
    const response = await axios.post('/api/login', {
      email,
      password,
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const postRegister = async ({
  name, lastname, email, role,
}) => {
  try {
    const response = await axios.post('/api/register', {
      name, lastname, email, role,
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

// RECOVER PASSWORD
const postRecoverPassword = async (email) => {
  try {
    const response = await axios.post('/api/recover-password', {
      email,
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

// UPDATE PASSWORD
const updatePassword = async ({ token, password, confirmPassword }) => {
  try {
    const response = await axios.post('/api/reset-password', {
      token,
      password,
      confirmPassword,
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

// USER VERIFICATION
const userVerification = async ({ token, password, confirmPassword }) => {
  try {
    const response = await axios.post('/api/user-verification', {
      token,
      password,
      confirmPassword,
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

// OBTAINING ACTIVE USER DATA
const getUserById = async (userId) => {
  try {
    const response = await axios.get(`/api/users/${userId}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getUsersPerPage = async (page, limit, searchValue) => {
  try {
    const { data } = await axios.get(`/api/users?page=${page}&limit=${limit}&searchValue=${searchValue}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    return err.response.data;
  }
};

export {
  postLogin,
  postRegister,
  postRecoverPassword,
  updatePassword,
  userVerification,
  getUserById,
  getUsersPerPage,
};
