import produce from 'immer';
import {
  SET_QUERY,
  SET_CATEGORY,
  SET_SUBCATEGORY,
  SET_ORDER,
  SET_START_DATE,
  SET_END_DATE,
  SET_PAGE,
  SET_LIMIT,
  SET_SUPERMARKET,
  SET_BRAND,
  LOGOUT,
  SET_TIMEFRAME,
  SET_IS_ENGEL_PRODUCT,
} from '../actions/snapsList';

const today = new Date();
const lastWeek = new Date();

const initialState = {
  query: '',
  category: [],
  subcategory: [],
  supermarket: [],
  brand: [],
  page: 0,
  limit: 10,
  order: 'DESC',
  startDate: lastWeek.setHours(0, 0, 0, 0),
  endDate: today.setHours(23, 59, 59, 999),
  timeframe: 'weekly',
  isEngelProduct: 'false',
};

const snapsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUERY: {
      const { query } = action.payload;
      return produce(state, (draft) => {
        draft.query = query;
      });
    }
    case SET_SUPERMARKET: {
      const { supermarket } = action.payload;
      return produce(state, (draft) => {
        draft.supermarket = supermarket;
      });
    }
    case SET_CATEGORY: {
      const { category } = action.payload;
      return produce(state, (draft) => {
        draft.category = category;
      });
    }
    case SET_SUBCATEGORY: {
      const { subcategory } = action.payload;
      return produce(state, (draft) => {
        draft.subcategory = subcategory;
      });
    }
    case SET_ORDER: {
      const { order } = action.payload;
      return produce(state, (draft) => {
        draft.order = order;
      });
    }
    case SET_START_DATE: {
      const { startDate } = action.payload;
      return produce(state, (draft) => {
        draft.startDate = startDate;
      });
    }
    case SET_END_DATE: {
      const { endDate } = action.payload;
      return produce(state, (draft) => {
        draft.endDate = endDate;
      });
    }
    case SET_PAGE: {
      const { page } = action.payload;
      return produce(state, (draft) => {
        draft.page = page;
      });
    }
    case SET_LIMIT: {
      const { limit } = action.payload;
      return produce(state, (draft) => {
        draft.limit = limit;
      });
    }
    case SET_BRAND: {
      const { brand } = action.payload;
      return produce(state, (draft) => {
        draft.brand = brand;
      });
    }
    case SET_TIMEFRAME: {
      const { timeframe } = action.payload;
      return produce(state, (draft) => {
        draft.timeframe = timeframe;
      });
    }
    case SET_IS_ENGEL_PRODUCT: {
      const { isEngelProduct } = action.payload;
      return produce(state, (draft) => {
        draft.isEngelProduct = isEngelProduct;
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.query = initialState.query;
        draft.supermarket = initialState.supermarket;
        draft.category = initialState.category;
        draft.subcategory = initialState.subcategory;
        draft.order = initialState.order;
        draft.startDate = initialState.startDate;
        draft.endDate = initialState.endDate;
        draft.page = initialState.page;
        draft.limit = initialState.limit;
        draft.brand = initialState.brand;
        draft.timeframe = initialState.timeframe;
        draft.isEngelProduct = initialState.isEngelProduct;
      });
    }
    default: {
      return state;
    }
  }
};

export default snapsListReducer;
