import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

function DataGraph({ data, subcategoriesObjectives, subcategoriesObjectivesPages }) {
  const options = {
    title: {
      text: 'Productos Scrapeados',
    },
    series: [
      {
        data,
        name: 'Productos scrapeados',
      },
      {
        data: subcategoriesObjectives,
        name: 'Productos objetivos calculados',
        color: 'red',
      },
      {
        data: subcategoriesObjectivesPages,
        name: 'Productos objetivos teóricos',
        color: 'green',
      },
    ],
    plotOptions: {
      series: {
        type: 'spline',
        marker: {
          enabled: null,
          radius: 3,
        },
        tooltip: {
          valueDecimals: 0,
          dateTimeLabelFormats: {
            millisecond: '%b %e',
          },
        },
      },
    },

    yAxis: [{
      offset: 20,
      resize: {
        enabled: true,
      },
      labels: {
        x: -15,
        style: {
          color: '#000',
          position: 'absolute',
        },
        align: 'left',
      },
    }],
    chart: {
      height: 500,
      zoomType: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      minRange: 1,
      labels: {
        format: '{value:%d %b}',
        rotation: -50,
      },
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={'stockChart'}
      options={options}
    />
  );
}

DataGraph.propTypes = {
  data: PropTypes.array,
  subcategoriesObjectives: PropTypes.array,
  subcategoriesObjectivesPages: PropTypes.array,
};

export default DataGraph;
