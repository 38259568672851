import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box,
  Container,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import Page from '../../../../components/Layout/Page';
import Header from './Header';
import Results from './Results';

import { getUsersPerPage } from '../../../../requests/api/auth';

import useStyles from './styles';

function UserListView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [count, setCount] = useState(0);

  useEffect(() => {
    const getUsers = async () => {
      const response = await getUsersPerPage(page, limit, query);
      if (response.success) {
        const data = await response.data.data.users;
        const arrayData = Object.values(data);
        setUsers(arrayData);
        setCount(response.data.data.totalUsers);
      } else {
        enqueueSnackbar('Error al cargar la información de usuarios.', {
          variant: 'error',
        });
      }
    };
    getUsers();
  }, [page, limit, query]);

  return (
    <Page
      className={classes.root}
      title="FH Engel | Usuarios"
    >
      <Container maxWidth={false}>
        <Header />
        {users && (
          <Box mt={3}>
            <Results
              users={users}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              query={query}
              setQuery={setQuery}
              count={count}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}

export default UserListView;
