import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import CategoryCreateForm from './CategoryCreateForm';

import { getAllSupermarkets } from '../../../requests/api/supermarkets';

import alphabeticSort from '../../../utils/alphabeticSort';
import useStyles from './styles';

function CategoryCreateView() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [supermarkets, setSupermarkets] = useState([]);

  useEffect(() => {
    try {
      const getSupermarkets = async () => {
        const response = await getAllSupermarkets();
        if (response.success) {
          const data = await response?.data?.data;
          setSupermarkets(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getSupermarkets();
    } catch {
      setSupermarkets([]);
    }
  }, []);

  return (
    <Page
      className={classes.root}
      title="FH Engel | Agregar Subcategoría"
    >
      <Container maxWidth={false}>
        <Header />
        <CategoryCreateForm
          supermarkets={supermarkets}
        />
      </Container>
    </Page>
  );
}

export default CategoryCreateView;
