import React from 'react';
import { Container } from '@material-ui/core';
import Page from '../../../../components/Layout/Page';
import Header from './Header';
import UserCreateForm from './UserCreateForm';

import useStyles from './styles';

function CustomerCreateView() {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}

      title="FH Engel | Agregar Usuario"
    >
      <Container maxWidth={false}>
        <Header />
        <UserCreateForm />
      </Container>
    </Page>
  );
}

export default CustomerCreateView;
