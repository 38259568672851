export const RESET_PRODUCT_ENGEL_CREATION = '@engelproduct/reset-engelproduct-creation';
export const RESET_PRODUCT_ENGEL_EDIT = '@engelproduct/reset-engelproduct-edit';
export const SET_PRODUCT_ENGEL_DETAILS = '@engelproduct/set-engelproduct-details';
export const SET_PRODUCT_ENGEL_CREATION_PAGE = '@engelproduct/set-engelproduct-creation-page';
export const SET_PRODUCT_ENGEL_ALL_STORES_CHECK = '@engelproduct/set-engelproduct-all-stores-check';
export const SET_PRODUCT_ENGEL_ALL_PRODUCTS_CHECK = '@engelproduct/set-engelproduct-all-products-check';

export function resetEngelProductEdit() {
  return {
    type: RESET_PRODUCT_ENGEL_EDIT,
  };
}

export function resetEngelProductCreation() {
  return {
    type: RESET_PRODUCT_ENGEL_CREATION,
  };
}

export function setEngelProductDetails(payload) {
  return {
    type: SET_PRODUCT_ENGEL_DETAILS,
    payload,
  };
}

export function setEngelProductCreationPage(payload) {
  return {
    type: SET_PRODUCT_ENGEL_CREATION_PAGE,
    payload,
  };
}

export function setEngelProductAllProductsCheck(payload) {
  return {
    type: SET_PRODUCT_ENGEL_ALL_PRODUCTS_CHECK,
    payload,
  };
}
