const SET_REPORT_QUERY = 'SET_REPORT_QUERY';
const SET_REPORT_PAGE = 'SET_REPORT_PAGE';
const SET_REPORT_LIMIT = 'SET_REPORT_LIMIT';
const LOGOUT = 'LOGOUT';

function setReportQuery(reportQuery) {
  return async (dispatch) => dispatch({
    type: SET_REPORT_QUERY,
    payload: {
      reportQuery,
    },
  });
}

function setReportPage(reportPage) {
  return async (dispatch) => dispatch({
    type: SET_REPORT_PAGE,
    payload: {
      reportPage,
    },
  });
}

function setReportLimit(reportLimit) {
  return async (dispatch) => dispatch({
    type: SET_REPORT_LIMIT,
    payload: {
      reportLimit,
    },
  });
}

function logoutReport() {
  return async (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
}

export {
  SET_REPORT_QUERY,
  SET_REPORT_PAGE,
  SET_REPORT_LIMIT,
  LOGOUT,
  setReportQuery,
  setReportPage,
  setReportLimit,
  logoutReport,
};
