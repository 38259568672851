import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box, Card, IconButton, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Delete } from '@material-ui/icons';
import useStyles from './styles';

const ALARM_NAME_MAP = {
  priceChangeAlarm: 'Notificación cambio precio',
};

const dayOfWeek = {
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miercoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sabado',
  sunday: 'Domingo',
};

function Results({
  className,
  notifications,
  handleRemoveEmail,
  ...rest
}) {
  const classes = useStyles();
  return (
    <Card
      className={clsx(classes.rootResults, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                  Nombre Notificacion
                </TableCell>
                <TableCell align='center'>
                  Día de alarma
                </TableCell>
                <TableCell align='center'>
                  Email
                </TableCell>
                <TableCell align='center'>
                  Categorías
                </TableCell>
                <TableCell align='center'>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications && notifications.map((notification, index) => (
                <TableRow
                  key={index}
                >
                  <TableCell align='center'>{ALARM_NAME_MAP[notification.name] || '-'}</TableCell>
                  <TableCell align='center'>{dayOfWeek[notification.dayToSend]}</TableCell>
                  <TableCell align='center'>{notification.email}</TableCell>
                  <TableCell align='center'>{notification?.categories?.map((c) => c.name).join(', ')}</TableCell>
                  <TableCell align='center'>
                    <IconButton onClick={() => handleRemoveEmail(notification.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array,
  handleRemoveEmail: PropTypes.func,
};

export default Results;
