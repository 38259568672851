import produce from 'immer';
import {
  SET_PRODUCT_QUERY,
  SET_PRODUCT_CATEGORY,
  SET_PRODUCT_SUBCATEGORY,
  SET_PRODUCT_BRAND,
  SET_PRODUCT_PAGE,
  SET_PRODUCT_LIMIT,
  SET_PRODUCT_SUPERMARKET,
  LOGOUT,
} from '../actions/productsList';

const initialState = {
  productQuery: '',
  productCategory: [],
  productSubcategory: [],
  productSupermarket: [],
  productBrand: [],
  productPage: 0,
  productLimit: 10,
};

const productsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_QUERY: {
      const { productQuery } = action.payload;
      return produce(state, (draft) => {
        draft.productQuery = productQuery;
      });
    }
    case SET_PRODUCT_SUPERMARKET: {
      const { productSupermarket } = action.payload;
      return produce(state, (draft) => {
        draft.productSupermarket = productSupermarket;
      });
    }
    case SET_PRODUCT_CATEGORY: {
      const { productCategory } = action.payload;
      return produce(state, (draft) => {
        draft.productCategory = productCategory;
      });
    }
    case SET_PRODUCT_SUBCATEGORY: {
      const { productSubcategory } = action.payload;
      return produce(state, (draft) => {
        draft.productSubcategory = productSubcategory;
      });
    }
    case SET_PRODUCT_BRAND: {
      const { productBrand } = action.payload;
      return produce(state, (draft) => {
        draft.productBrand = productBrand;
      });
    }
    case SET_PRODUCT_PAGE: {
      const { productPage } = action.payload;
      return produce(state, (draft) => {
        draft.productPage = productPage;
      });
    }
    case SET_PRODUCT_LIMIT: {
      const { productLimit } = action.payload;
      return produce(state, (draft) => {
        draft.productLimit = productLimit;
      });
    }
    case LOGOUT: {
      return produce(state, (draft) => {
        draft.productQuery = initialState.productQuery;
        draft.productSupermarket = initialState.productSupermarket;
        draft.productCategory = initialState.productCategory;
        draft.productSubcategory = initialState.productSubcategory;
        draft.productBrand = initialState.productBrand;
        draft.productPage = initialState.productPage;
        draft.productLimit = initialState.productLimit;
      });
    }
    default: {
      return state;
    }
  }
};

export default productsListReducer;
