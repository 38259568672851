/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Header from './Header';
import HistoricGraph from './HistoricGraph';
import Indicators from './Indicators';

import useStyles from './styles';

import { getProductInfo, getProductSnapshots } from '../../../requests/api/products';

const getMaxMins = (snaps, quantity) => {
  let normalMax = 0;
  let normalMin = 9999999;
  let saleMax = 0;
  let saleMin = 9999999;
  for (let i = 0; i < snaps.length; i += 1) {
    if (snaps[i].price > 0 && Math.round(snaps[i].price / quantity, 2) > normalMax) {
      normalMax = Math.round(snaps[i].price / quantity, 2);
    }
    if (snaps[i].price > 0 && Math.round(snaps[i].price / quantity, 2) < normalMin) {
      normalMin = Math.round(snaps[i].price / quantity, 2);
    }
    let units = quantity;
    if (snaps[i].units_for_sale_price > 0) {
      units = snaps[i].units_for_sale_price * quantity;
    }
    if (snaps[i].sale === true && Math.round(snaps[i].sale_price / units, 2) > saleMax) {
      saleMax = Math.round(snaps[i].sale_price / units, 2);
    }
    if (snaps[i].sale === true && Math.round(snaps[i].sale_price / units, 2) < saleMin) {
      saleMin = Math.round(snaps[i].sale_price / units, 2);
    }
    if (snaps[i].club === true && Math.round(snaps[i].club_price / units, 2) > saleMax) {
      saleMax = Math.round(snaps[i].club_price / units, 2);
    }
    if (snaps[i].club === true && Math.round(snaps[i].club_price / units, 2) < saleMin) {
      saleMax = Math.round(snaps[i].club_price / units, 2);
    }
    if (snaps[i].prime === true && Math.round(snaps[i].prime_price / units, 2) > saleMax) {
      saleMax = Math.round(snaps[i].prime_price / units, 2);
    }
    if (snaps[i].prime === true && Math.round(snaps[i].prime_price / units, 2) < saleMin) {
      saleMax = Math.round(snaps[i].prime_price / units, 2);
    }
  }
  return {
    normalMax, normalMin, saleMax, saleMin,
  };
};

const parsePrices = (snap, snapPrice, sale, quantity) => {
  const snapData = { x: Date.parse(snap.created_at) };
  if (!!snap[sale] || sale === 'normal') {
    if (snap.units_for_sale_price > 0 && sale !== 'normal') {
      const units = quantity * snap.units_for_sale_price;
      snapData.y = Math.round(
        parseInt(snap[snapPrice], 10) / units, 2,
      );
      snapData.units = `${snap.units_for_sale_price} x ${quantity}`;
    } else {
      snapData.y = Math.round(snap[snapPrice] / quantity, 2);
      snapData.units = quantity;
    }
    if (!!snap.price && snapData.y < snap.price && sale !== 'normal') {
      snapData.discount = Math.round((1 - snapData.y / (snap.price / quantity)) * 100, 0);
    }
  } else {
    snapData.y = null;
    snapData.units = null;
  }
  return snapData;
};

function ProductGraphs(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [product, setProduct] = useState();
  const [normalPrices, setNormalPrices] = useState([]);
  const [salePrices, setSalePrices] = useState([]);
  const [clubPrices, setClubPrices] = useState([]);
  const [primePrices, setPrimePrices] = useState([]);
  const [normalMinPrice, setNormalMinPrice] = useState(0);
  const [normalMaxPrice, setNormalMaxPrice] = useState(0);
  const [saleMinPrice, setSaleMinPrice] = useState(0);
  const [saleMaxPrice, setSaleMaxPrice] = useState(0);
  const account = useSelector((state) => state.account);

  useEffect(() => {
    const getProducts = async () => {
      let units;
      let responsePS;
      let responseP;
      try {
        const resP = await getProductInfo(
          props.match.params.id, account.user.id, account.user.role,
        );
        responseP = resP.data;
        units = responseP.data.units_per_product;
        const resPS = await getProductSnapshots(props.match.params.id);
        responsePS = resPS.data;
      } catch (err) {
        return null;
      }

      if (isMountedRef.current) {
        setProduct(responseP.data);
        if (responsePS.data) {
          const normalPriceList = responsePS.data.map(
            (snap) => parsePrices(snap, 'price', 'normal', units),
          );
          const salePriceList = responsePS.data.map(
            (snap) => parsePrices(snap, 'sale_price', 'sale', units),
          );
          const clubPriceList = responsePS.data.map(
            (snap) => parsePrices(snap, 'club_price', 'club', units),
          );
          const primePriceList = responsePS.data.map(
            (snap) => parsePrices(snap, 'prime_price', 'prime', units),
          );
          const {
            normalMax, normalMin, saleMax, saleMin,
          } = getMaxMins(responsePS.data, units);
          setNormalPrices(normalPriceList);
          setSalePrices(salePriceList);
          setClubPrices(clubPriceList);
          setPrimePrices(primePriceList);
          setNormalMaxPrice(normalMax);
          setNormalMinPrice(normalMin);
          setSaleMaxPrice(saleMax);
          setSaleMinPrice(saleMin);
        }
      }
      return null;
    };
    getProducts();
    return null;
  }, [props]);

  if (!product) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="FH Engel | Gráfico producto"
    >
      <Container maxWidth="lg">
        <Header
          supermarket={product.supermarket_name}
          productDescription={product.description}
          sku={product.sku}
          productBrand={product.brand}
        />
        <Indicators
          product={product}
          normalMax={normalMaxPrice}
          normalMin={normalMinPrice}
          saleMin={saleMinPrice}
          saleMax={saleMaxPrice}
        />
        <Box mt={3}>
          <HistoricGraph
            normalPrices={normalPrices}
            salePrices={salePrices}
            clubPrices={clubPrices}
            primePrices={primePrices}
          />
        </Box>
      </Container>
    </Page>
  );
}

ProductGraphs.propTypes = {
  match: PropTypes.any,
};

export default withRouter(ProductGraphs);
