/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import {
  Search as SearchIcon,
  ArrowRightCircle as ArrowIcon,
  Star as StarIcon,
  XCircle as XCircleIcon,
  Settings as SettingsIcon,
} from 'react-feather';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import {
  setEngelProductQuery,
  setEngelProductPage,
  setEngelProductLimit,
} from '../../../actions/engelProductsList';

import useStyles from './styles';

function Results({
  className,
  engelProducts,
  brands,
  count,
  sort,
  setSort,
  sortOptions,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentQuery, setCurrentQuery] = useState('');
  const [currentEngelProducts, setCurrentEngelProducts] = useState(engelProducts);

  const {
    engelProductPage,
    engelProductLimit,
  } = useSelector((state) => state.engelProductsList);

  useEffect(() => { setCurrentEngelProducts(engelProducts); }, [engelProducts]);

  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);

  const handleQueryChange = (event) => {
    event.persist();
    setCurrentQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    dispatch(setEngelProductPage(newPage));
  };

  const handleLimitChange = (event) => {
    dispatch(setEngelProductLimit(event.target.value));
  };

  return (
    <Card
      className={clsx(classes.rootResults, className)}
      {...rest}
    >
      <Divider />
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
      >
        <Grid container spacing={1} >
          <Grid item xs={2} md={4}>
            <TextField
              className={classes.queryField}
              onChange={handleQueryChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
                endAdornment: currentQuery ? (
                  <IconButton size="small" onClick={() => {
                    setCurrentQuery('');
                    dispatch(setEngelProductQuery(''));
                  }}>
                    <XCircleIcon />
                  </IconButton>
                ) : null,
              }}
              placeholder="Buscar por sku o por descripción"
              value={currentQuery}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={0.5} md={6} >
            <Button
              color="secondary"
              variant="contained"
              className={classes.searchAction}
              onClick={() => dispatch(setEngelProductQuery(currentQuery))}
            >
              <SvgIcon >
                <SearchIcon />
              </SvgIcon>
            </Button>
          </Grid>
          <Grid item xs={3} md={2}>
            <TextField
              className={classes.queryField}
              label="Orden"
              name="order"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={sort}
              variant="outlined"
            >
              {sortOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Box>

      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                  Nuevo
                </TableCell>
                <TableCell align='center'>
                  Sku
                </TableCell>
                <TableCell align='center'>
                  Descripcion
                </TableCell>
                <TableCell align='center'>
                  Unidades
                </TableCell>
                <TableCell align='center'>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentEngelProducts.map((product) => (
                  <TableRow
                    hover
                    key={product.id}
                  >
                    <TableCell align='center'>{new Date(product.created_at) > lastMonth ? <StarIcon/> : ''}</TableCell>
                    <TableCell align='center'>{product.sku}</TableCell>
                    <TableCell align='center'>{product.description}</TableCell>
                    <TableCell align='center'>{product.units_per_product}</TableCell>
                    <TableCell align='center' width={'10%'}>
                      <Tooltip title="Editar Producto">
                        <IconButton
                          component={RouterLink}
                          to={`/engel/engel-products/${product.id}/edit`}
                        >
                          <SvgIcon fontSize="small">
                            <SettingsIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Ver Gráficos">
                        <IconButton
                          component={RouterLink}
                          to={`/engel/engel-products/${product.id}/graphs`}
                        >
                          <SvgIcon fontSize="small">
                            <ArrowIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={(engelProductPage > 0 && currentEngelProducts.length < engelProductLimit) ? 0 : engelProductPage}
        rowsPerPage={engelProductLimit}
        rowsPerPageOptions={[5, 10, 25, 50]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  engelProducts: PropTypes.array,
  originalProducts: PropTypes.array,
  brands: PropTypes.array,
  count: PropTypes.number,
  sort: PropTypes.string,
  setSort: PropTypes.func,
  sortOptions: PropTypes.array,
};

Results.defaultProps = {
  products: [],
};

export default Results;
