/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer';
import {
  RESET_PRODUCT_ENGEL_CREATION,
  SET_PRODUCT_ENGEL_DETAILS,
  SET_PRODUCT_ENGEL_ALL_PRODUCTS_CHECK,
  SET_PRODUCT_ENGEL_CREATION_PAGE,
} from '../actions/engelProductsActions';

const initialState = {
  engelProductInformation: {},
  engelProductPage: 0,
  engelProductAllProductsCheck: false,
};

// eslint-disable-next-line default-param-last
const engelProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PRODUCT_ENGEL_CREATION: {
      return produce(state, (draft) => {
        draft.engelProductInformation = {};
        draft.engelProductPage = 0;
        draft.engelProductAllProductsCheck = false;
      });
    }

    case SET_PRODUCT_ENGEL_DETAILS: {
      return produce(state, (draft) => {
        const auxEngelProductInfo = { ...state.engelProductInformation };
        draft.engelProductInformation = { ...auxEngelProductInfo, ...action.payload };
      });
    }

    case SET_PRODUCT_ENGEL_CREATION_PAGE: {
      return produce(state, (draft) => {
        draft.engelProductPage = action.payload;
      });
    }

    case SET_PRODUCT_ENGEL_ALL_PRODUCTS_CHECK: {
      return produce(state, (draft) => {
        draft.engelProductAllProductsCheck = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default engelProductReducer;
