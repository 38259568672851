import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import ReportEditIndexForm from './ReportEditIndexForm';
import { getReportDetail } from '../../../requests/api/reports';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useStyles from './styles';

function ReportEditIndexView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [report, setReport] = useState();
  const [dataCompProducts, setDataCompProducts] = useState([{}]);
  const [engelProductSelected, setEngelProductSelected] = useState();
  const [totalComparation, setTotalComparation] = useState([]);
  const [comparations, setComparations] = useState(-1);

  useEffect(() => {
    const getReport = async () => {
      const res = await getReportDetail(props.match.params.id);
      const response = res.data;
      if (isMountedRef.current) {
        const reportObject = response.data;
        const reportContent = reportObject.content;
        const contentObject = JSON.parse(reportContent);
        const compArray = [];
        const engelSelected = contentObject.map((product, index) => {
          compArray.push(index);
          return (product.engel_product);
        });
        const comps = contentObject.length;
        setEngelProductSelected(engelSelected);
        setReport(reportObject);
        setDataCompProducts(contentObject);
        setTotalComparation(compArray);
        setComparations(comps);
      }
    };
    getReport();
  }, []);

  if (!report
    || totalComparation.length !== dataCompProducts.length
    || comparations === -1) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="FH Engel | Crear Reporte"
    >
      <Container maxWidth={false}>
        <Header />
        <ReportEditIndexForm
          report={report}
          dataCompProducts={dataCompProducts}
          engelProductSelected={engelProductSelected}
          totalComparation={totalComparation}
          setDataCompProducts={setDataCompProducts}
          setEngelProductSelected={setEngelProductSelected}
          setTotalComparation={setTotalComparation}
          comparations={comparations}
          setComparations={setComparations}
        />
      </Container>
    </Page>
  );
}
ReportEditIndexView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(ReportEditIndexView);
