/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
  MenuItem,
} from '@material-ui/core';

import {
  Trash as TrashIcon,
} from 'react-feather';
import { getProductsByEngelProductId } from '../../../requests/api/engelproducts';
import { getProductInfo } from '../../../requests/api/products';

import useStyles from './styles';

function ComparativeTarget({
  id,
  dataCompProducts,
  setDataCompProducts,
  engelProducts,
  engelProductSelected,
  setEngelProductSelected,
  setSelected,
  handleDeleteComparation,
}) {
  const classes = useStyles();

  const account = useSelector((state) => state.account);

  const [selectedProductEngel, setSelectedProductEngel] = useState({});

  const [engelProductSelectedAssociate,
    setEngelProductSelectedAssociate] = useState([]);

  const [engelFilteredProductAssociate,
    setEngelFilteredProductAssociate] = useState([]);

  const [selectedBaseAssociated, setSelectedBaseAssociated] = useState('');
  const [selectedIndexAssociated, setSelectedIndexAssociated] = useState('');

  const [selectedProducts, setSelectedProducts] = useState([]);
  // const [selectedSupermarkets, setSelectedSupermarkets] = useState([]);

  const [dataObject, setDataObject] = useState({});

  const getEngelProductSelectedAssociate = async () => {
    if (selectedProductEngel.id) {
      const productEngelSelectedId = selectedProductEngel.id;
      const productsId = await getProductsByEngelProductId(productEngelSelectedId);
      const supermarketsArray = [];
      const data = await productsId?.data?.data;
      if (data) {
        const productInfo = [];
        for (let i = 0; i < data.length; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          const resP = await getProductInfo(data[i],
            account.user.id, account.user.role);
          const product = resP?.data?.data;
          if (!productInfo.includes(product)) {
            productInfo.push(product);
          }
          if (!supermarketsArray.includes(product.supermarket_id)) {
            supermarketsArray.push(product.supermarket_id);
          }
        }
        // setSelectedSupermarkets(supermarketsArray);
        setEngelProductSelectedAssociate(productInfo);
        setSelectedProducts(productInfo);
        setEngelFilteredProductAssociate(productInfo);
      }
    }
  };

  const handleChangeEngelProduct = (event) => {
    const baseEngelSelected = engelProductSelected;
    baseEngelSelected[id] = event.target.value.id;
    setEngelProductSelected(baseEngelSelected);
    setSelectedProductEngel(event.target.value);
  };

  const handleChangeBaseProductAssociated = (event) => {
    const baseId = event.target.value.id;
    setSelectedBaseAssociated(event.target.value);
    setEngelFilteredProductAssociate(engelProductSelectedAssociate);
    setEngelFilteredProductAssociate(
      (prevSelected) => prevSelected.filter(
        (s) => s.id !== baseId && s.id !== selectedIndexAssociated.id,
      ),
    );
    setSelectedProducts(engelProductSelectedAssociate);
    setSelectedProducts(
      (prevSelected) => prevSelected.filter(
        (s) => s.id !== baseId && s.id !== selectedIndexAssociated.id,
      ),
    );
  };

  const handleChangeIndexProductAssociated = (event) => {
    const indexId = event.target.value.id;
    setSelectedIndexAssociated(event.target.value);
    setEngelFilteredProductAssociate(engelProductSelectedAssociate);
    setEngelFilteredProductAssociate(
      (prevSelected) => prevSelected.filter(
        (s) => s.id !== indexId && s.id !== selectedBaseAssociated.id
          && s.id !== selectedBaseAssociated.id,
      ),
    );
    setSelectedProducts(engelProductSelectedAssociate);
    setSelectedProducts(
      (prevSelected) => prevSelected.filter(
        (s) => s.id !== indexId && s.id !== selectedBaseAssociated.id,
      ),
    );
  };

  const handleSelectOneProduct = (event, product) => {
    if (!selectedProducts.includes(product)) {
      setSelectedProducts((prevSelected) => [...prevSelected, product]);
    } else {
      setSelectedProducts((prevSelected) => prevSelected.filter((s) => s !== product));
    }
  };

  const handleSetDataObject = () => {
    if (selectedBaseAssociated !== '' && selectedIndexAssociated !== '' && selectedProducts.length > 0) {
      setSelected(true);
    }
    const object = {
      engel_product: selectedProductEngel.id,
      base_product: selectedBaseAssociated.id,
      index_product: selectedIndexAssociated.id,
      comp_products: selectedProducts.map((product) => product.id),
    };
    setDataObject(object);
    const baseCompProducts = dataCompProducts;
    baseCompProducts[id] = object;
    setDataCompProducts(baseCompProducts);
  };

  useEffect(() => {
    try {
      getEngelProductSelectedAssociate();
      handleSetDataObject();
    } catch {
      setEngelProductSelectedAssociate([]);
    }
  }, [selectedProductEngel]);

  useEffect(() => {
    handleSetDataObject();
  }, [selectedProducts]);

  return (
    <Box>
      <Box>
        <Typography
          variant="h5"
          className={classes.title}
        >
          Comparación {id + 1}
        </Typography>
        <Card className={classes.card}>
          <CardContent>
            <Grid
              item
              md={12}
              xs={6}
              className={classes.gridSelectEngelProducts}
            >
              <TextField
                fullWidth
                name="engelProducts"
                value={selectedProductEngel}
                onChange={handleChangeEngelProduct}
                select
                variant="outlined"
                label='Producto Engel'
                InputLabelProps={{ shrink: true }}
              >
                {engelProducts.map((product) => (
                  <MenuItem
                    value={product}
                    key={product.id}
                  >
                    {product.description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={12}
              xs={6}
              className={classes.gridSelectEngelProducts}
            >
              <TextField
                fullWidth
                name="engelProducts"
                value={selectedBaseAssociated}
                onChange={handleChangeBaseProductAssociated}
                select
                variant="outlined"
                label='Producto Homologado Base'
                InputLabelProps={{ shrink: true }}
              >
                {engelProductSelectedAssociate.map((product) => (
                  <MenuItem
                    value={product}
                    key={product.id}
                  >
                    {product.description} - {product.brand} - {product.supermarket_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              md={12}
              xs={6}
              className={classes.gridSelectEngelProducts}
            >
              <TextField
                fullWidth
                name="engelProducts"
                value={selectedIndexAssociated}
                onChange={handleChangeIndexProductAssociated}
                select
                variant="outlined"
                label='Producto Indexado Base'
                InputLabelProps={{ shrink: true }}
              >
                {engelProductSelectedAssociate.map((product) => (
                  <MenuItem
                    value={product}
                    key={product.id}
                  >
                    {product.description} - {product.brand} - {product.supermarket_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* <Typography
          variant="h5"
          className={classes.subtitle}
        >
        Supermercados
        </Typography>
        <FormGroup aria-label="position" row>
        {supermarkets.map((supermarket) => (
          <FormControlLabel
            key = {supermarket.id}
            value={supermarket.name}
            control={<Checkbox checked={selectedSupermarkets.includes(supermarket.id)} />}
            label={supermarket.name}
            labelPlacement="end"
            className={classes.checkbox}
            onChange={(event) => handleSelectOneSupermarket(event, supermarket.id)}
          />
        ))}
        </FormGroup> */}
            <Typography
              variant="h5"
              className={classes.subtitle}
            >
              Productos Asociados
            </Typography>
            <FormGroup aria-label="position" row>
              {engelFilteredProductAssociate.map((product) => (
                <FormControlLabel
                  key={product.id}
                  value={`${product.description} - ${product.brand} - ${product.supermarket_name}`}
                  control={<Checkbox checked={selectedProducts.includes(product)} />}
                  label={`${product.description} - ${product.brand} - ${product.supermarket_name}`}
                  labelPlacement="end"
                  className={classes.checkbox}
                  onChange={(event) => handleSelectOneProduct(event, product)}
                />
              ))}
            </FormGroup>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="left"
              display='inline-block'
            >
              <IconButton
                color="inherit"
                onClick={() => handleDeleteComparation(id)}
                className={classes.deleteComparationButton}
              >
                <SvgIcon fontSize="medium">
                  <TrashIcon className={classes.menuIcon} />
                </SvgIcon>
              </IconButton>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>

  );
}

ComparativeTarget.propTypes = {
  id: PropTypes.number,
  dataCompProducts: PropTypes.array,
  setDataCompProducts: PropTypes.func,
  engelProducts: PropTypes.array,
  engelProductSelected: PropTypes.array,
  setEngelProductSelected: PropTypes.func,
  setSelected: PropTypes.func,
  handleDeleteComparation: PropTypes.func,
};

export default ComparativeTarget;
