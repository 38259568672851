/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

const numberFormat = new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' });

function HistoricGraph({
  normalPrices,
  salePrices,
  clubPrices,
  primePrices,
}) {
  const options = {
    title: {
      text: 'Gráfico de precio en el tiempo',
    },
    legend: {
      enabled: true,
    },
    series: [{
      data: normalPrices,
      name: 'Precio normal',
      color: '#5DADE2',
      boostThreshold: 1, // Fuerza a usar el boost incluso con pocos puntos
      turboThreshold: 0, // Desactiva el límite de puntos para mejorar el rendimiento
    }, {
      data: salePrices,
      name: 'Precio oferta',
      color: '#F4D03F',
      boostThreshold: 1, // Fuerza a usar el boost incluso con pocos puntos
      turboThreshold: 0, // Desactiva el límite de puntos para mejorar el rendimiento
    }, {
      data: clubPrices,
      name: 'Precio club',
      color: '#A569BD',
      boostThreshold: 1, // Fuerza a usar el boost incluso con pocos puntos
      turboThreshold: 0, // Desactiva el límite de puntos para mejorar el rendimiento
    }, {
      data: primePrices,
      name: 'Precio prime',
      color: '#85929E',
      boostThreshold: 1, // Fuerza a usar el boost incluso con pocos puntos
      turboThreshold: 0, // Desactiva el límite de puntos para mejorar el rendimiento
    }],
    plotOptions: {
      series: {
        marker: {
          enabled: 'auto',
          radius: 3,
        },
      },
    },
    tooltip: {
      split: false,
      formatter() {
        const s = `${Highcharts.dateFormat('%B %d', this.x)}<br />Precio: ${this.y}<br />Unidades: ${this.point.units || 1} <br />(-${this.point.discount || 0}%)`;
        if (this.point.discount !== null) {
          s.concat(`<br />Descuento: ${this.point.discount}`);
        }
        return s;
      },
    },
    yAxis: [{
      offset: 20,
      labels: {
        formatter() {
          return numberFormat.format(this.value);
        },
        x: -15,
        style: {
          color: '#000',
          position: 'absolute',
        },
        align: 'left',
      },
    }],
    xAxis: {
      minRange: 1,
      labels: {
        format: '{value:%d %b}',
        rotation: -50,
      },
    },
    chart: {
      height: 500,
      zoomType: '',
    },
    credits: {
      enabled: false,
    },
    rangeSelector: {
      inputDateFormat: '%b %e, %Y',
      allButtonsEnabled: false,
      buttons: [{
        type: 'month',
        title: 'Muestra los datos en una ventana de un mes',
        count: 1,
        text: '1m',
      },
      {
        type: 'month',
        title: 'Muestra los datos en una ventana de tres meses',
        count: 3,
        text: '3m',
      },
      {
        type: 'month',
        title: 'Muestra los datos en una ventana de seis meses',
        count: 6,
        text: '6m',
      },
      {
        type: 'all',
        title: 'Muestra todos los datos disponibles',
        text: 'Todo',
      }],
      buttonTheme: {
        width: 60,
      },
      selected: 1,
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={'stockChart'}
      options={options}
    />
  );
}

HistoricGraph.propTypes = {
  normalPrices: PropTypes.array,
  salePrices: PropTypes.array,
  clubPrices: PropTypes.array,
  primePrices: PropTypes.array,
};

export default HistoricGraph;
