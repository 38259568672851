import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  // MenuItem,
  // CircularProgress,
} from '@material-ui/core';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogTitle from '@mui/material/DialogTitle';
import { Redirect } from 'react-router';
import { Autocomplete } from '@mui/material';
import { putEditProduct } from '../../../requests/api/products';

import useStyles from './styles';

function ProductEditForm({
  className,
  product,
  subcategories,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  if (submitSuccess) {
    return <Redirect to="/config/products" />;
  }

  return (
    <Formik
      initialValues={{
        description: product.description || '',
        brand: product.brand || '',
        subcategory_name: product.subcategory_name || '',
        category_name: product.category_name || '',
        supermarket_name: product.supermarket_name || '',
        subcategory_id: product.subcategory_id || '',
        category_id: product.category_id || '',
        sku: product.sku || '',
        units_per_product: product.units_per_product || '',
        url: product.url || '',
      }}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }) => {
        try {
          // Make API request
          const data = {
            id: product.id,
            description: values.description,
            brand: values.brand,
            subcategory_name: values.subcategory_name,
            category_name: values.category_name,
            supermarket_name: values.supermarket_name,
            subcategory_id: values.subcategory_id,
            category_id: values.category_id,
            sku: values.sku,
            units_per_product: values.units_per_product,
            url: values.url,
          };

          const response = await putEditProduct(data);

          if (response.success) {
            setSubmitSuccess(true);
            setStatus({ success: true });
            setSubmitting(false);
            enqueueSnackbar(response.message, {
              variant: 'success',
            });
          } else {
            setStatus({ success: false });
            setErrors({ submit: response.message });
            enqueueSnackbar(response.message, {
              variant: 'warning',
              action: <Button href="/config/products">Volver atrás</Button>,
            });
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <Card>
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={touched.description && errors.description}
                    label="Nombre"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.description}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.url && errors.url)}
                    fullWidth
                    helperText={touched.url && errors.url}
                    label="Url"
                    name="url"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.url}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    // onClick={() => alert('Clicked!')}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.sku && errors.sku)}
                    fullWidth
                    helperText={touched.sku && errors.sku}
                    label="Sku"
                    name="sku"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.sku}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.supermarket_name && errors.supermarket_name)}
                    fullWidth
                    helperText={touched.supermarket_name && errors.supermarket_name}
                    label="Nombre supermercado"
                    name="supermarket_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.supermarket_name}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.category_name && errors.category_name)}
                    fullWidth
                    helperText={touched.category_name && errors.category_name}
                    label="Nombre categoría"
                    name="category_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    defaultValue={values.category_name}
                    variant="outlined"
                    disabled={true}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Autocomplete
                    id="subcategory-autocomplete"
                    options={subcategories}
                    disableClearable
                    defaultValue={{ name: values.subcategory_name }}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    onChange={(e, v) => {
                      values.subcategory_id = v.id;
                      values.subcategory_name = v.name;
                      values.category_name = v.Category.name;
                      values.category_id = v.Category.id;
                    }}
                    required
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Subcategoría"
                        onBlur={handleBlur}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.brand && errors.brand)}
                    fullWidth
                    helperText={touched.brand && errors.brand}
                    label="Marca"
                    name="brand"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.brand}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.units_per_product && errors.units_per_product)}
                    fullWidth
                    helperText={touched.units_per_product && errors.units_per_product}
                    label="Unidades por producto"
                    name="units_per_product"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.units_per_product}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
              >
              <Grid
                  item
                  md={6}
                  xs={2}
              >
              <Box mt={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Guardar
                </Button>
              </Box>
              </Grid>
              <Grid
                  item
                  md={6}
                  xs={2}
              >
              {/* <Box mt={1}>
                  <Button
                      style={{
                        backgroundColor: '#f44336',
                        color: 'white',
                      }}
                    variant="contained"
                    onClick={handleClickOpen}
                    disabled='true'
                  >
                    Eliminar
                  </Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {'¿Desea eliminar este producto?'}
                    </DialogTitle>
                    <DialogActions>
                      <Button onClick={handleDelete}>Si</Button>
                      <Button onClick={handleClose}>No</Button>
                    </DialogActions>
                  </Dialog>
                </Box> */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
}

ProductEditForm.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object,
  subcategories: PropTypes.array,
};

export default ProductEditForm;
