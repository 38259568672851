import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Typography,
} from '@material-ui/core';

// import { useSelector } from 'react-redux';

// import { useSelector } from 'react-redux';
import useStyles from './styles';

function TabSelection({ engelProductPageEdit }) {
  const classes = useStyles();

  return (
    <Paper
      elevation={3}
      className={classes.tabSelectionPaper}
    >
      <Box
          // eslint-disable-next-line max-len
          className={engelProductPageEdit === 0 ? classes.tabSelectionItemSelected : classes.tabSelectionItem}
      >
        <Typography
          variant="h4"
          className={classes.tabSelectionItemSelectedText}
        >
          Información general
        </Typography>
      </Box>
      <Box
        // eslint-disable-next-line max-len
        className={engelProductPageEdit === 1 ? classes.tabSelectionItemSelected : classes.tabSelectionItem}
      >
        <Typography
          variant="h4"
        >
          Asociar productos
        </Typography>
      </Box>
    </Paper>
  );
}

TabSelection.propTypes = {
  className: PropTypes.string,
  engelProductPageEdit: PropTypes.number,
};

export default TabSelection;
