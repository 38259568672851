/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import {
  Search as SearchIcon,
  Settings as SettingsIcon,
  XCircle as XCircleIcon,
  ArrowRightCircle as ArrowIcon,
} from 'react-feather';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';

import {
  setSubcategoryQuery,
  setSubcategoryCategory,
  setSubcategoryPage,
  setSubcategoryLimit,
  setSubcategorySupermarket,
} from '../../../actions/subcategoriesList';

import {
  setProductQuery,
  setProductSupermarket,
  setProductCategory,
  setProductSubcategory,
  setProductPage,
  setProductLimit,
  setProductBrand,
} from '../../../actions/productsList';

import useStyles from './styles';
import alphabeticSort from '../../../utils/alphabeticSort';

function Results({
  className,
  subcategories,
  supermarkets,
  categories,
  setCategories,
  originalCategories,
  count,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentQuery, setCurrentQuery] = useState();
  const [currentCategories, setCurrentCategories] = useState();
  const [currentSubcategories, setCurrentSubcategories] = useState(subcategories);

  const {
    subcategoryCategory,
    subcategorySupermarket,
    subcategoryPage,
    subcategoryLimit,
  } = useSelector((state) => state.subcategoriesList);

  useEffect(() => { setCurrentSubcategories(subcategories); }, [subcategories]);

  const handleQueryChange = (event) => {
    event.persist();
    setCurrentQuery(event.target.value);
  };

  useEffect(() => {
    setCurrentCategories(categories);
  }, [categories]);

  const handlePageChange = (event, newPage) => {
    dispatch(setSubcategoryPage(newPage));
  };

  const handleLimitChange = (event) => {
    dispatch(setSubcategoryLimit(event.target.value));
  };

  const handleChange = (type, newVal) => {
    if (!!newVal) {
      const ides = newVal.map((val) => val.id);
      dispatch(setSubcategoryPage(0));
      if (type === 'supermarket') {
        dispatch(setSubcategorySupermarket(newVal));
        dispatch(setSubcategoryCategory([]));
        if (ides.length === 0) {
          setCategories(originalCategories);
        } else {
          setCategories(
            originalCategories.filter((item) => ides.includes(item.supermarket_id)).sort((a, b) => alphabeticSort(a, b)),
          );
        }
      } else if (type === 'category') {
        dispatch(setSubcategoryCategory(newVal));
      }
    }
  };

  const pageChange = (subcat) => {
    const subcategory = { id: subcat.id, name: subcat.name, supermarket_id: subcat.Supermarket.id };
    const supermarket = subcat.Supermarket;
    dispatch(setProductQuery(''));
    dispatch(setProductBrand([]));
    dispatch(setProductCategory([]));
    dispatch(setProductSubcategory([subcategory]));
    dispatch(setProductPage(0));
    dispatch(setProductLimit(10));
    dispatch(setProductSupermarket([supermarket]));
  };

  if (subcategories.length === 0) {
    return null;
  }

  return (
    <Card
      className={clsx(classes.rootResults, className)}
      {...rest}
    >
      <Divider />
      <Box
        p={2}
        minHeight={56}
      >
        <Grid container spacing={1} >
          <Grid item xs={2} md={4}>
            <TextField
              className={classes.queryField}
              onChange={handleQueryChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
                endAdornment: currentQuery ? (
                  <IconButton size="small" onClick={() => {
                    setCurrentQuery('');
                    dispatch(setSubcategoryQuery(''));
                    dispatch(setSubcategoryPage(0));
                  }}>
                    <XCircleIcon />
                  </IconButton>
                ) : null,
              }}
              placeholder="Buscar por Subcategoría"
              value={currentQuery}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={0.5} md={0.5}>
            <Button
              color="secondary"
              variant="contained"
              className={classes.searchAction}
              onClick={() => {
                dispatch(setSubcategoryQuery(currentQuery));
                dispatch(setSubcategoryPage(0));
              }}
            >
              <SvgIcon >
                <SearchIcon />
              </SvgIcon>
            </Button>
          </Grid>
          <Grid item xs={2} md={4.5} align='right'>
            {supermarkets.length > 0 && <Autocomplete
              id="supermarkets-autocomplete"
              className={classes.options}
              options={supermarkets}
              multiple
              value={subcategorySupermarket}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleChange('supermarket', v)}
              limitTags={1}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Supermercados'
                  variant='outlined'
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
            />
            }
          </Grid>
          <Grid item xs={3} justifySelf="end">
            {currentCategories.length > 0 && <Autocomplete
              id="categories-autocomplete"
              options={currentCategories}
              multiple
              value={subcategoryCategory}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleChange('category', v)}
              limitTags={1}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Categoría'
                  variant='outlined'
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name} ({supermarkets.filter((sup) => sup.id === option.supermarket_id)[0].name})
                </li>
              )}
            />
            }
          </Grid>
        </Grid>
      </Box>

      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                  Subcategoría
                </TableCell>
                <TableCell align='center'>
                  Categoría asociada
                </TableCell>
                <TableCell align='center'>
                  Supermercado
                </TableCell>
                <TableCell align='center'>
                  Productos asociados
                </TableCell>
                <TableCell align='center'>
                  Frecuencia
                </TableCell>
                <TableCell align='center'>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentSubcategories.map((subcategory) => (
                  <TableRow
                    hover
                    key={subcategory.id}
                  >
                    <TableCell>{subcategory.name}</TableCell>
                    <TableCell>{subcategory.Category.name}</TableCell>
                    <TableCell align='center'>{subcategory.Supermarket.name}</TableCell>
                    <TableCell align='center'>{subcategory.Products.length}</TableCell>
                    <TableCell align='center'>{subcategory.frequency}</TableCell>
                    <TableCell align='center'>
                      <Tooltip title="Editar Subcategoría">
                        <IconButton
                          component={RouterLink}
                          to={`/config/subcategory/${subcategory.id}/edit`}
                        >
                          <SvgIcon fontSize="small">
                            <SettingsIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Ver productos asociados">
                        <IconButton
                          component={RouterLink}
                          to={'/datos/productos'}
                          onClick={() => pageChange(subcategory)}
                        >
                          <SvgIcon fontSize="small">
                            <ArrowIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={(subcategoryPage > 0 && currentSubcategories.length < subcategoryLimit) ? 0 : subcategoryPage}
        rowsPerPage={subcategoryLimit}
        rowsPerPageOptions={[5, 10, 25, 50]}
        labelRowsPerPage="Filas por página"
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  subcategories: PropTypes.array,
  count: PropTypes.number,
  supermarkets: PropTypes.array,
  originalCategories: PropTypes.array,
  categories: PropTypes.array,
  setCategories: PropTypes.func,
};

Results.defaultProps = {
  subcategories: [],
};

export default Results;
