import React, {
  useState, useCallback,
} from 'react';
import { useSnackbar } from 'notistack';

import PropTypes from 'prop-types';
import CustomAutoComplete from './CustomAutoComplete';
import { getPaginatedBrands } from '../../../requests/api/brands';
import alphabeticSort from '../../../utils/alphabeticSort';

function BrandSelector({ productBrand, onBrandChange }) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedBrands, setSelectedBrands] = useState(productBrand);
  const [brands, setBrands] = useState([]);

  const limit = 20;

  const getBrands = useCallback(async ({ searchTerm, page, setLoading }) => {
    try {
      setLoading(true);
      const response = await getPaginatedBrands(limit, page, searchTerm);
      if (response.success) {
        const data = response?.data?.data || [];
        setBrands((prevBrands) => {
          const allBrands = [...prevBrands, ...data];
          const uniqueBrands = Array
            .from(new Map(allBrands.map((brand) => [brand.id, brand])).values());
          return uniqueBrands.sort(alphabeticSort);
        });
      } else {
        enqueueSnackbar('Error al cargar la información de las marcas', {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('Error al cargar las marcas.', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  const handleBrandChange = (e, value) => {
    setSelectedBrands(value);
    onBrandChange(value);
  };
  return (
    <CustomAutoComplete
      label="Marcas"
      options={brands}
      selectedOptions={selectedBrands}
      onOptionsChange={handleBrandChange}
      dataLoad={getBrands}
      setBrands={setBrands}
    />
  );
}

BrandSelector.propTypes = {
  productBrand: PropTypes.array.isRequired,
  onBrandChange: PropTypes.func.isRequired,
};

export default BrandSelector;
