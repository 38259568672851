/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Divider,
} from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { setOptions } from 'highcharts';

import DataGraph from './Graph';

import alphabeticSort from '../../../utils/alphabeticSort';
import useStyles from './styles';

function Results({
  className,
  originalCategories,
  supermarkets,
  supermarket,
  setSupermarket,
  categories,
  setCategories,
  category,
  setCategory,
  subcategories,
  setSubcategories,
  subcategory,
  setSubcategory,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  productSnapshots,
  average,
  subcategoriesObjetives,
  subcategoriesObjetivesPages,
  ...rest
}) {
  const classes = useStyles();
  const handleChange = (type, value) => {
    if (!!value) {
      const ide = parseInt(value.id, 10);
      const first = [{ id: 0, name: 'Todas' }];
      if (type === 'supermarket') {
        setSupermarket(ide);
        setCategory(0);
        setSubcategory(0);
        setSubcategories(first);
        if (ide === 0) {
          setCategories(first);
        } else {
          setCategories(
            first.concat(
              originalCategories.filter(
                (item) => item.supermarket_id === ide,
              ).sort((a, b) => alphabeticSort(a, b)),
            ),
          );
        }
      } else if (type === 'category') {
        setCategory(ide);
        setSubcategory(0);
        if (ide === 0) {
          setSubcategories(first);
        } else {
          setSubcategories(
            first.concat(
              categories.find((item) => item.id === ide)
                .Subcategories.sort((a, b) => alphabeticSort(a, b)),
            ),
          );
        }
      } else if (type === 'subcategory') {
        setSubcategory(ide);
      }
    }
  };

  return (
    <Card
      className={clsx(classes.rootResults, className)}
      {...rest}
    >
      <Divider />
      <Box
        p={2}
        minHeight={56}
        display="flex"
        alignItems="center"
        spacing={2}
      >
        <Grid container spacing={1}>
          <Grid item xs={2} md={2}>
            <Autocomplete
              id="supermarkets-autocomplete"
              options={supermarkets}
              disableClearable
              defaultValue={{ id: 0, name: 'Todos' }}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleChange('supermarket', v)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Supermercado'
                  variant='outlined'
                  value={supermarket}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} md={2}>
            <Autocomplete
              id="categories-autocomplete"
              options={categories}
              disableClearable
              defaultValue={{ id: 0, name: 'Todas' }}
              value={categories.find((cat) => cat.id === category)}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => handleChange('category', v)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Categoría'
                  variant='outlined'
                />
              )}
            />
          </Grid>
          <Grid item xs={2} md={2}>
            <Autocomplete
              id="subcategories-autocomplete"
              options={subcategories}
              disableClearable
              getOptionLabel={(option) => option.name}
              defaultValue={{ id: 0, name: 'Todas' }}
              value={subcategories.find((subcat) => subcat.id === subcategory)}
              onChange={(e, v) => handleChange('subcategory', v)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.queryField}
                  label='Subcategoría'
                  variant='outlined'
                />
              )}
            />
          </Grid>
          <Grid
            item
            md={4}
            xs={3}
            align='right'
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Desde"
                inputFormat="MM/dd/yyyy"
                value={startDate}
                onChange={(e) => setStartDate(e.valueOf())}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            md={2}
            xs={2}
            align='right'
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Hasta"
                inputFormat="MM/dd/yyyy"
                value={endDate}
                onChange={(e) => setEndDate(e.valueOf())}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <DataGraph
        data={productSnapshots}
        subcategoriesObjectives={subcategoriesObjetives}
        subcategoriesObjectivesPages={subcategoriesObjetivesPages}
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  originalCategories: PropTypes.array,
  supermarkets: PropTypes.array,
  supermarket: PropTypes.number,
  setSupermarket: PropTypes.func,
  categories: PropTypes.array,
  category: PropTypes.number,
  setCategory: PropTypes.func,
  setCategories: PropTypes.func,
  subcategories: PropTypes.array,
  setSubcategories: PropTypes.func,
  subcategory: PropTypes.number,
  setSubcategory: PropTypes.func,
  startDate: PropTypes.number,
  setStartDate: PropTypes.func,
  endDate: PropTypes.number,
  setEndDate: PropTypes.func,
  productSnapshots: PropTypes.array,
  average: PropTypes.number,
  subcategoriesObjetives: PropTypes.array,
  subcategoriesObjetivesPages: PropTypes.array,
};

Results.defaultProps = {
  productSnapshots: [],
  subcategoriesObjetives: [],
  subcategoriesObjetivesPages: [],
};

export default Results;
