import produce from 'immer';
import {
  SET_PRODUCT_ENGEL_QUERY,
  SET_PRODUCT_ENGEL_PAGE,
  SET_PRODUCT_ENGEL_LIMIT,
  LOGOUT,
} from '../actions/engelProductsList';

const initialState = {
  engelProductQuery: '',
  engelProductPage: 0,
  engelProductLimit: 10,
};

const engelProductsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_ENGEL_QUERY: {
      const { engelProductQuery } = action.payload;
      return produce(state, (draft) => {
        draft.engelProductQuery = engelProductQuery;
      });
    }
    case SET_PRODUCT_ENGEL_PAGE: {
      const { engelProductPage } = action.payload;
      return produce(state, (draft) => {
        draft.engelProductPage = engelProductPage;
      });
    }
    case SET_PRODUCT_ENGEL_LIMIT: {
      const { engelProductLimit } = action.payload;
      return produce(state, (draft) => {
        draft.engelProductLimit = engelProductLimit;
      });
    }
    case LOGOUT: {
      return produce(state, (draft) => {
        draft.engelProductQuery = initialState.engelProductQuery;
        draft.engelProductPage = initialState.engelProductPage;
        draft.engelProductLimit = initialState.engelProductLimit;
      });
    }
    default: {
      return state;
    }
  }
};

export default engelProductsListReducer;
