import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Grid,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import {
  Download as DownloadIcon,
  X as XIcon,
} from 'react-feather';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import useStyles from './styles';

import { exportProductSnapshots } from '../../../requests/api/productsnapshots';

function Header({
  className,
  cleanFilters,
  ...rest
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingExport, setLoadingExport] = useState(false);
  const {
    query,
    order,
    supermarket,
    category,
    subcategory,
    startDate,
    endDate,
    brand,
    timeframe,
    isEngelProduct,
  } = useSelector((state) => state.snapsList);

  const account = useSelector((state) => state.account);

  const handleExport = async (format) => {
    setLoadingExport(true);
    enqueueSnackbar('Los datos se están preparando para ser descargados', {
      variant: 'success',
      autoHideDuration: 5000,
    });

    const brandIdes = brand.map((val) => val.id);
    const subIdes = subcategory.map((val) => val.id);
    const catIdes = category.map((val) => val.id);
    const supIdes = supermarket.map((val) => val.id);
    const { data } = await exportProductSnapshots(
      query,
      order,
      startDate,
      endDate,
      catIdes,
      subIdes,
      supIdes,
      brandIdes,
      account.user,
      timeframe,
      isEngelProduct,
    );

    if (data?.data?.count > 40000) {
      enqueueSnackbar('Se descargará el límite de 40.000 productos', {
        variant: 'info',
        autoHideDuration: 5000,
      });
    }
    if (data?.data?.file) {
      const fileData = data.data.file;
      const { fileName } = data.data;
      const uri = `data:${format === 'csv' ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'};charset=utf-8${format === 'xlsx' ? ';base64' : ''},${fileData}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = uri;
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      enqueueSnackbar(data?.errorMessage || 'Error desconocido', {
        variant: 'error',
      });
    }
    setLoadingExport(false);
  };

  return (
    <Grid
      className={clsx(classes.rootHeader, className)}
      container
      justifyContent="flex-start"
      spacing={3}
      alignItems="center"
      {...rest}
    >
      <Grid item xs={2} md={8} >
        <Typography
          variant="h3"
          color="textPrimary"
        >
          Capturas de productos
        </Typography>
      </Grid>
      <Grid item xs={2} md={2} >
        <Button
          disabled={loadingExport}
          color="secondary"
          variant="contained"
          className={classes.action}
          onClick={() => cleanFilters()}
        >
          <SvgIcon
            fontSize="small"
            className={classes.actionIcon}
          >
            <XIcon />
          </SvgIcon>
          Limpiar filtros
        </Button>
      </Grid>
      <Grid item xs={2} md={2}>
        <Button
          disabled={loadingExport}
          color="secondary"
          variant="contained"
          className={classes.action}
          onClick={() => handleExport('xlsx')}
        >
          <SvgIcon
            fontSize="small"
            className={classes.actionIcon}
          >
            <DownloadIcon />
          </SvgIcon>
          Exportar
        </Button>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  cleanFilters: PropTypes.func,
};

export default Header;
