import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Grid,
  SvgIcon,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  // PlusCircle as PlusCircleIcon,
  X as XIcon,
} from 'react-feather';
import { useSnackbar } from 'notistack';

import useStyles from './styles';
import { exportReportComparationMassive } from '../../../requests/api/reportinstances';

function Header({
  className,
  cleanFilters,
  ...rest
}) {
  const classes = useStyles();
  // const [ loadingExport, setLoadingExport ] = useState(false);
  const [fromDate, setFromDate] = useState('2023-01-01');
  const [toDate, setToDate] = useState('2023-12-31');
  const [supermarket, setSupermarket] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const sortOptions = [
    {
      label: 'Todos',
      value: '',
    },
    {
      label: 'Jumbo',
      value: 'Jumbo',
    },
    {
      label: 'Lider',
      value: 'Lider',
    },
    {
      label: 'Unimarc',
      value: 'Unimarc',
    },
  ];

  const downloadMassive = async () => {
    const dataExport = await exportReportComparationMassive(
      fromDate,
      toDate,
      supermarket,
    );
    const { data } = dataExport;
    if (data?.file) {
      const { fileName, file } = data;
      const uri = `data:${'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'};charset=utf-8${';base64'},${file}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = uri;
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      enqueueSnackbar(data?.errorMessage || 'Error desconocido', {
        variant: 'error',
      });
    }
  };

  return (
    <Grid
      className={clsx(classes.rootHeader, className)}
      container
      justifyContent="space-between"
      spacing={3}
      alignItems="center"
      {...rest}
    >
      <Grid container>
        <Grid item xs={2} md={12}>
          <Typography
            variant="h3"
            color="textPrimary"
          >
            Reportes
          </Typography>
        </Grid>
        <Grid className={classes.controlButtons} item xs={2} md={12}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item xs={2} md={2}>
              <TextField
                className={classes.controlReportText}
                name="supermercado"
                onChange={(e) => setSupermarket(e.target.value)}
                select
                SelectProps={{ native: true }}
                value={supermarket}
                variant="outlined"
              >
                {sortOptions.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2} md={2}>
              <TextField
                name="type"
                label='Considerar desde'
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                type='date'
                variant="outlined"
                required
                className={classes.controlReportText}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={2} md={2}>
              <TextField
                fullWidth
                name="type"
                label='Hasta'
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                type='date'
                variant="outlined"
                required
                className={classes.controlReportText}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={2} md={2}>
              <Button
                color="secondary"
                variant="contained"
                className={classes.action}
                onClick={() => downloadMassive()}
              >
                Informe masivo
              </Button>
            </Grid>
            <Grid item xs={2} md={2} >
              <Button
                color="secondary"
                variant="contained"
                className={classes.action}
                onClick={() => cleanFilters()}
              >
                <SvgIcon
                  fontSize="small"
                  className={classes.actionIcon}
                >
                  <XIcon />
                </SvgIcon>
                Limpiar filtros
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item xs={2} md={3}>
        <Button
          color="secondary"
          variant="contained"
          className={classes.action}
          href="/administracion/products/crear"
          disabled={true}
        >
          <SvgIcon
            fontSize="small"
            className={classes.actionIcon}
          >
            <PlusCircleIcon />
          </SvgIcon>
          Importar Productos
        </Button>
      </Grid> */}
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  cleanFilters: PropTypes.func,
};

export default Header;
