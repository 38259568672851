import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { getProductsPerPage } from '../../../requests/api/products';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import EngelProductCreateForm from './EngelProductCreateForm';
import EngelProductsAssociation from './ProductsAssociationCreate';
import TabSelection from './TabSelection';
import useStyles from './styles';
import alphabeticSort from '../../../utils/alphabeticSort';
import { getAllBrands } from '../../../requests/api/brands';
import { getAllSupermarkets } from '../../../requests/api/supermarkets';

import {
  setEngelProductCreationPage,
  resetEngelProductCreation,
} from '../../../actions/engelProductsActions';

const sortOptions = [
  {
    value: 'ALPH',
    label: 'Alfabético',
  },
  {
    value: 'DESC',
    label: 'Más nuevo primero',
  },
  {
    value: 'ASC',
    label: 'Más antiguo primero',
  },
];

function EngelProductCreateView() {
  const classes = useStyles();
  const account = useSelector((state) => state.account);
  const { engelProductPage } = useSelector((state) => state.engelProduct);
  const [products, setProducts] = useState([]);
  const [numberProducts, setNumberProducts] = useState(0);
  const [limit, setLimitProduct] = useState(5);
  const [page, setPageProduct] = useState(0);
  const [brands, setBrands] = useState([]);
  const [supermarkets, setSupermarkets] = useState([]);
  const [searchValue, setSearchValueProduct] = useState('');
  const [searchBrand, setBrandProduct] = useState([]);
  const [searchSupermarkets, setSupermarketProduct] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const sort = sortOptions[0].value;
  const dispatch = useDispatch();

  const getProducts = async () => {
    const userRole = account.user.role;
    const userId = account.user.id;
    const brandIdes = searchBrand.map((val) => val.id);
    const supermarketsIdes = searchSupermarkets.map((val) => val.id);
    const response = await getProductsPerPage(
      page,
      limit,
      searchValue,
      sort,
      supermarketsIdes, '',
      '', brandIdes,
      userRole,
      userId,
      false,
    );
    const data = await response?.data?.data;
    setProducts(data.products);
    setNumberProducts(data.totalProducts);
  };

  useEffect(() => {
    dispatch(setEngelProductCreationPage(0));
    dispatch(resetEngelProductCreation());
    try {
      getProducts();
    } catch {
      setProducts([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getBrands = async () => {
        const response = await getAllBrands();
        if (response.success) {
          const data = await response?.data?.data;
          setBrands(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las marcas.', {
            variant: 'error',
          });
        }
      };
      getBrands();
    } catch {
      setBrands([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getSupermarkets = async () => {
        const response = await getAllSupermarkets();
        if (response.success) {
          const data = await response?.data?.data;
          setSupermarkets(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las marcas.', {
            variant: 'error',
          });
        }
      };
      getSupermarkets();
    } catch {
      setSupermarkets([]);
    }
  }, []);

  useEffect(() => {
    getProducts();
  }, [page, limit, searchValue, searchBrand, searchSupermarkets]);

  return (
    <Page
      className={classes.root}
      title="FH Engel | Crear Producto"
    >
      <Container maxWidth={false}>
      <Header/>
        <TabSelection />
        {engelProductPage === 0 && (
          <EngelProductCreateForm />
        )}
        {engelProductPage === 1 && (
          <EngelProductsAssociation
            products={products}
            brands={brands}
            supermarkets={supermarkets}
            setEngelProductSupermarket={setSupermarketProduct}
            totalProducts={numberProducts}
            searchBrand={searchBrand}
            setEngelProductBrand={setBrandProduct}
            page={page}
            limit={limit}
            setPage={setPageProduct}
            setLimit={setLimitProduct}
            setSearchValueProduct={setSearchValueProduct}
          />
        )}
      </Container>
    </Page>
  );
}

EngelProductCreateView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(EngelProductCreateView);
