import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../constants/http';

const getSubcategoriesPerPage = async (page, limit, searchValue, supermarket, category) => {
  try {
    const { data } = await axios.get(`/api/subcategories?page=${page}&limit=${limit}&searchValue=${searchValue}&supermarket=${supermarket}&category=${category}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getSubcategoriesWithCategories = async (supermarket, category, subcategory) => {
  try {
    const { data } = await axios.get(`/api/subcategories/get/categories?supermarket=${supermarket}&category=${category}&subcategory=${subcategory}}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getAllSubcategories = async (supermarket, category, subcategory, scrape) => {
  try {
    const { data } = await axios.get(`/api/subcategories/get/all?supermarket=${supermarket}&category=${category}&subcategory=${subcategory}&scrape=${scrape}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const updateSubcategoryFrequency = async (subcategoryId, frequency) => {
  try {
    const { data } = await axios.put(`/api/subcategories/${subcategoryId}?frequency=${frequency}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postCreateSubcategory = async ({
  name, path, frequency, supermarket, category,
}) => {
  try {
    const response = await axios.post('/api/subcategories/create', {
      name, path, frequency, supermarket_id: supermarket, category_id: category,
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getSubcategoryDetail = async (id) => {
  try {
    const { data } = await axios.get(`/api/subcategories/${id}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putEditSubcategory = async (newData) => {
  try {
    const { data } = await axios.put(`/api/subcategories/${newData.id}`, newData);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const deleteSubcategory = async (newData) => {
  try {
    const { data } = await axios.delete(`/api/subcategories/${newData.id}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getSubcategoriesObjetivesCount = async (startDate,
  endDate, supermarket, category, subcategory) => {
  try {
    const { data } = await axios.get(`/api/subcategoriesobjectives/count?startDate=${startDate}&endDate=${endDate}&supermarket=${supermarket}&category=${category}&subcategory=${subcategory}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getSubcategoriesObjetivesPagesCount = async (startDate,
  endDate, supermarket, category, subcategory) => {
  try {
    const { data } = await axios.get(`/api/subcategoriesobjectivespages/count?startDate=${startDate}&endDate=${endDate}&supermarket=${supermarket}&category=${category}&subcategory=${subcategory}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const getSubcategoriesExport = async () => {
  try {
    const { data } = await axios.get('/api/subcategories/export');
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export {
  getSubcategoriesPerPage,
  getSubcategoriesWithCategories,
  updateSubcategoryFrequency,
  postCreateSubcategory,
  getSubcategoryDetail,
  putEditSubcategory,
  deleteSubcategory,
  getAllSubcategories,
  getSubcategoriesObjetivesCount,
  getSubcategoriesObjetivesPagesCount,
  getSubcategoriesExport,
};
