const SET_QUERY = 'SET_QUERY';
const SET_SUPERMARKET = 'SET_SUPERMARKET';
const SET_CATEGORY = 'SET_CATEGORY';
const SET_SUBCATEGORY = 'SET_SUBCATEGORY';
const SET_ORDER = 'SET_ORDER';
const SET_START_DATE = 'SET_START_DATE';
const SET_END_DATE = 'SET_END_DATE';
const SET_PAGE = 'SET_PAGE';
const SET_LIMIT = 'SET_LIMIT';
const SET_BRAND = 'SET_BRAND';
const SET_TIMEFRAME = 'SET_TIMEFRAME';
const SET_IS_ENGEL_PRODUCT = 'SET_IS_ENGEL_PRODUCT';
const LOGOUT = 'LOGOUT';

function setQuery(query) {
  return async (dispatch) => dispatch({
    type: SET_QUERY,
    payload: {
      query,
    },
  });
}

function setSupermarket(supermarket) {
  return async (dispatch) => dispatch({
    type: SET_SUPERMARKET,
    payload: {
      supermarket,
    },
  });
}

function setCategory(category) {
  return async (dispatch) => dispatch({
    type: SET_CATEGORY,
    payload: {
      category,
    },
  });
}

function setSubcategory(subcategory) {
  return async (dispatch) => dispatch({
    type: SET_SUBCATEGORY,
    payload: {
      subcategory,
    },
  });
}

function setOrder(order) {
  return async (dispatch) => dispatch({
    type: SET_ORDER,
    payload: {
      order,
    },
  });
}

function setStartDate(startDate) {
  return async (dispatch) => dispatch({
    type: SET_START_DATE,
    payload: {
      startDate,
    },
  });
}

function setEndDate(endDate) {
  return async (dispatch) => dispatch({
    type: SET_END_DATE,
    payload: {
      endDate,
    },
  });
}

function setPage(page) {
  return async (dispatch) => dispatch({
    type: SET_PAGE,
    payload: {
      page,
    },
  });
}

function setLimit(limit) {
  return async (dispatch) => dispatch({
    type: SET_LIMIT,
    payload: {
      limit,
    },
  });
}

function setBrand(brand) {
  return async (dispatch) => dispatch({
    type: SET_BRAND,
    payload: {
      brand,
    },
  });
}

function logoutSnapshots() {
  return async (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
}

function setTimeframe(timeframe) {
  return async (dispatch) => dispatch({
    type: SET_TIMEFRAME,
    payload: {
      timeframe,
    },
  });
}
function setIsEngelProduct(isEngelProduct) {
  return async (dispatch) => dispatch({
    type: SET_IS_ENGEL_PRODUCT,
    payload: {
      isEngelProduct,
    },
  });
}

export {
  SET_QUERY,
  SET_SUPERMARKET,
  SET_CATEGORY,
  SET_SUBCATEGORY,
  SET_ORDER,
  SET_START_DATE,
  SET_END_DATE,
  SET_PAGE,
  SET_LIMIT,
  SET_BRAND,
  SET_TIMEFRAME,
  SET_IS_ENGEL_PRODUCT,
  LOGOUT,
  setQuery,
  setSupermarket,
  setCategory,
  setSubcategory,
  setOrder,
  setStartDate,
  setEndDate,
  setPage,
  setLimit,
  setBrand,
  setTimeframe,
  logoutSnapshots,
  setIsEngelProduct,
};
