import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Header from './Header';
import Page from '../../components/Layout/Page';
import useStyles from './styles';
import Results from './Results';
import {
  deleteEmailFromAlarmList,
  getAlarmInformation,
  postAddEmailToAlarmList,
} from '../../requests/api/alarms';
import UpsertInformationHeader from './UpsertInformationHeader';
import { getAllCategories } from '../../requests/api/categories';
import { getAllBrands } from '../../requests/api/brands';
import { getAllSupermarkets } from '../../requests/api/supermarkets';

function AlarmsView() {
  const classes = useStyles();
  const [alarmInformations, setAlarmInformations] = useState([]);
  const [selectedDay, setSelectedDay] = useState('monday');
  const [updateTable, setUpdateTable] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedSupermarkets, setSelectedSupermarkets] = useState([]);
  const [brands, setBrands] = useState([]);
  const [superMarkets, setSuperMarkets] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getAllAlarmInformation = async () => {
      try {
        const { data } = await getAlarmInformation();
        setAlarmInformations(data.data.alarmInformation);
      } catch (err) {
        setAlarmInformations([]);
      }
    };

    const getCategories = async () => {
      try {
        const res = await getAllCategories();
        const response = res.data;
        setCategories((prev) => {
          const newCategories = prev.concat(response.data);
          return newCategories;
        });
      } catch (err) {
        setCategories([]);
      }
    };

    const getBrands = async () => {
      try {
        const res = await getAllBrands();
        const response = res.data;
        setBrands((prev) => {
          const newBrands = prev.concat(response.data);
          return newBrands;
        });
      } catch (err) {
        setBrands([]);
      }
    };

    const getSuperMarkets = async () => {
      try {
        const res = await getAllSupermarkets();
        const response = res.data;
        setSuperMarkets((prev) => {
          const newMarkets = prev.concat(response.data);
          return newMarkets;
        });
      } catch (err) {
        setSuperMarkets([]);
      }
    };

    getAllAlarmInformation();
    getCategories();
    getBrands();
    getSuperMarkets();
  }, [updateTable]);

  // const handleSelectedDay = async (e) => {
  //   try {
  //     const { success, message } = await putEditDayToSendAlarm(
  //       alarmInformation.id, e.target.value,
  //     );

  //     if (success) {
  //       setSelectedDay(e.target.value);
  //       enqueueSnackbar(message || 'Correo añadido con éxito', {
  //         variant: 'success',
  //       });
  //     } else {
  //       enqueueSnackbar(message, {
  //         variant: 'error',
  //       });
  //     }
  //   } catch (err) {
  //     enqueueSnackbar(err.message || 'Error al cambiar dia', {
  //       variant: 'error',
  //     });
  //   }
  // };

  const handleAddEmail = async (emailToAdd, setEmailToAdd) => {
    try {
      const { success, message } = await postAddEmailToAlarmList(
        emailToAdd,
        selectedCategories,
        selectedBrands,
        selectedSupermarkets,
        selectedDay,
      );
      if (success) {
        setEmailToAdd('');
        setUpdateTable((prevState) => !prevState);
        enqueueSnackbar(message || 'Correo añadido con éxito', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    } catch (err) {
      enqueueSnackbar(err.message || 'Error al añadir email', {
        variant: 'error',
      });
    }
  };

  const handleRemoveEmail = async (idAlarm) => {
    try {
      const { success, message } = await deleteEmailFromAlarmList(idAlarm);
      if (success) {
        setUpdateTable((prevState) => !prevState);
        enqueueSnackbar(message || 'Correo eliminado con éxito', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    } catch (err) {
      enqueueSnackbar(err.message || 'Error al eliminar email', {
        variant: 'error',
      });
    }
  };

  return <Page
    className={classes.root}
    title="FH Engel | Alarmas"
  >
    <Container maxWidth={false}>
      <Header />
      <UpsertInformationHeader
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        handleAddEmail={handleAddEmail}
        categories={categories}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        brands={brands}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
        superMarkets={superMarkets}
        selectedSuperMarkets={selectedSupermarkets}
        setSelectedSuperMarkets={setSelectedSupermarkets}
      />
      <Results
        notifications={alarmInformations}
        handleRemoveEmail={handleRemoveEmail}
      />
    </Container>
  </Page>;
}

export default AlarmsView;
