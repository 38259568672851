const SET_PRODUCT_QUERY = 'SET_PRODUCT_QUERY';
const SET_PRODUCT_SUPERMARKET = 'SET_PRODUCT_SUPERMARKET';
const SET_PRODUCT_CATEGORY = 'SET_PRODUCT_CATEGORY';
const SET_PRODUCT_SUBCATEGORY = 'SET_PRODUCT_SUBCATEGORY';
const SET_PRODUCT_BRAND = 'SET_PRODUCT_BRAND';
const SET_PRODUCT_PAGE = 'SET_PRODUCT_PAGE';
const SET_PRODUCT_LIMIT = 'SET_PRODUCT_LIMIT';
const LOGOUT = 'LOGOUT';

function setProductQuery(productQuery) {
  return async (dispatch) => dispatch({
    type: SET_PRODUCT_QUERY,
    payload: {
      productQuery,
    },
  });
}

function setProductSupermarket(productSupermarket) {
  return async (dispatch) => dispatch({
    type: SET_PRODUCT_SUPERMARKET,
    payload: {
      productSupermarket,
    },
  });
}

function setProductCategory(productCategory) {
  return async (dispatch) => dispatch({
    type: SET_PRODUCT_CATEGORY,
    payload: {
      productCategory,
    },
  });
}

function setProductSubcategory(productSubcategory) {
  return async (dispatch) => dispatch({
    type: SET_PRODUCT_SUBCATEGORY,
    payload: {
      productSubcategory,
    },
  });
}

function setProductBrand(productBrand) {
  return async (dispatch) => dispatch({
    type: SET_PRODUCT_BRAND,
    payload: {
      productBrand,
    },
  });
}

function setProductPage(productPage) {
  return async (dispatch) => dispatch({
    type: SET_PRODUCT_PAGE,
    payload: {
      productPage,
    },
  });
}

function setProductLimit(productLimit) {
  return async (dispatch) => dispatch({
    type: SET_PRODUCT_LIMIT,
    payload: {
      productLimit,
    },
  });
}

function logoutProduct() {
  return async (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
}

export {
  SET_PRODUCT_QUERY,
  SET_PRODUCT_SUPERMARKET,
  SET_PRODUCT_CATEGORY,
  SET_PRODUCT_SUBCATEGORY,
  SET_PRODUCT_BRAND,
  SET_PRODUCT_PAGE,
  SET_PRODUCT_LIMIT,
  LOGOUT,
  setProductQuery,
  setProductSupermarket,
  setProductCategory,
  setProductSubcategory,
  setProductBrand,
  setProductPage,
  setProductLimit,
  logoutProduct,
};
