import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import CategoryEditForm from './CategoryEditForm';
import { getCategoryDetail } from '../../../requests/api/categories';
import { getAllSupermarkets } from '../../../requests/api/supermarkets';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

import alphabeticSort from '../../../utils/alphabeticSort';
import useStyles from './styles';

function CategoryEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [supermarkets, setSupermarkets] = useState([]);
  const [category, setCategory] = useState();

  useEffect(() => {
    const getCategory = async () => {
      const res = await getCategoryDetail(props.match.params.id);
      const response = res.data;
      if (isMountedRef.current) {
        setCategory(response.data);
      }
    };
    getCategory();
  }, [props]);

  useEffect(() => {
    try {
      const getSupermarkets = async () => {
        const response = await getAllSupermarkets();
        if (response.success) {
          const data = await response?.data?.data;
          setSupermarkets(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getSupermarkets();
    } catch {
      setSupermarkets([]);
    }
  }, []);

  if (!category) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="FH Engel | Editar Categoría"
    >
      <Container maxWidth={false}>
        <Header />
        <CategoryEditForm
          supermarkets={supermarkets}
          category={category}
        />
      </Container>
    </Page>
  );
}

CategoryEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(CategoryEditView);
