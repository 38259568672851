import axios from '../../utils/axios';

const postCreateEmailReport = async ({
  reportId, emails,
}) => {
  try {
    const response = await axios.post('/api/emailreports', {
      reportId, emails,
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const putEditEmailReport = async ({
  reportId, emails,
}) => {
  try {
    const response = await axios.put('/api/emailreports/edit', {
      reportId, emails,
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const delStopEmailReport = async ({
  reportId,
}) => {
  try {
    const response = await axios.delete(`/api/emailreports/delete/${reportId}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  postCreateEmailReport,
  putEditEmailReport,
  delStopEmailReport,
};
