import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rootHeader: {},
  rootResults: {},
  queryField: {
    width: '100%',
    height: '100%',
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
    width: '100%',
  },
  actionIcon: {
    paddingRight: theme.spacing(1),
    height: '100%',
  },
  searchAction: {
    width: '50%',
    height: '100%',
    borderRadius: '80%',
    paddingRight: theme.spacing(2),
  },
  options: {
    justifySelf: 'end',
    width: '60%',
  },
}));

export default useStyles;
