const SET_CATEGORY_QUERY = 'SET_CATEGORY_QUERY';
const SET_CATEGORY_SUPERMARKET = 'SET_CATEGORY_SUPERMARKET';
const SET_CATEGORY_PAGE = 'SET_CATEGORY_PAGE';
const SET_CATEGORY_LIMIT = 'SET_CATEGORY_LIMIT';
const LOGOUT = 'LOGOUT';

function setCategoryQuery(categoryQuery) {
  return async (dispatch) => dispatch({
    type: SET_CATEGORY_QUERY,
    payload: {
      categoryQuery,
    },
  });
}

function setCategorySupermarket(categorySupermarket) {
  return async (dispatch) => dispatch({
    type: SET_CATEGORY_SUPERMARKET,
    payload: {
      categorySupermarket,
    },
  });
}

function setCategoryPage(categoryPage) {
  return async (dispatch) => dispatch({
    type: SET_CATEGORY_PAGE,
    payload: {
      categoryPage,
    },
  });
}

function setCategoryLimit(categoryLimit) {
  return async (dispatch) => dispatch({
    type: SET_CATEGORY_LIMIT,
    payload: {
      categoryLimit,
    },
  });
}

function logoutCategory() {
  return async (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
}

export {
  SET_CATEGORY_QUERY,
  SET_CATEGORY_SUPERMARKET,
  SET_CATEGORY_PAGE,
  SET_CATEGORY_LIMIT,
  LOGOUT,
  setCategoryQuery,
  setCategorySupermarket,
  setCategoryPage,
  setCategoryLimit,
  logoutCategory,
};
