import axios from '../../utils/axios';
import { INTERNAL_SERVER_ERROR } from '../../constants/http';

const getReportsPerPage = async (
  page, limit, searchValue,
  order, userRole, userId, engelFilter,
) => {
  try {
    const { data } = await axios.get(`/api/reports?page=${page}&limit=${limit}&searchValue=${searchValue}&order=${order}&userRole=${userRole}&userId=${userId}&engelFilter=${engelFilter}`);
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    return err.response.data;
  }
};

const getReportDetail = async (productId, userId, userRole, edit) => {
  try {
    const { data } = await axios.get(`/api/report/${productId}/info/${userId}/${userRole}?edit=${edit}`);

    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putEditComparativeReport = async (newData) => {
  try {
    const response = await axios.put(`/api/report/comparative/edit/${newData.id}`, newData);

    return response.data;
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const putEditIndexReport = async (newData) => {
  try {
    const response = await axios.put(`/api/report/index/edit/${newData.id}`, newData);

    return response.data;
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

const postCreateComparativeReport = async ({
  userId, title, content, type, frequency, range, dateToSend,
}) => {
  try {
    const response = await axios.post('/api/report/comparative', {
      userId, title, content, type, frequency, range, dateToSend,
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const deleteComparativeReport = async (arrayId) => {
  try {
    const { data } = await axios.delete('/api/report/comparative', { data: { arrayId } });
    return {
      success: data.success,
      status: data.status,
      message: data.message,
      data,
    };
  } catch (err) {
    const status = err.response ? err.response.status : INTERNAL_SERVER_ERROR.status;
    const message = err.response ? err.response.statusText : INTERNAL_SERVER_ERROR.message;
    return {
      success: false,
      status,
      message,
      data: {
        errorMessage: 'Ha ocurrido un error. Por favor intente nuevamente en unos minutos.',
      },
    };
  }
};

export {
  getReportsPerPage,
  getReportDetail,
  putEditComparativeReport,
  putEditIndexReport,
  postCreateComparativeReport,
  deleteComparativeReport,
};
