import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

const numberFormat = new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' });

function HistoricGraph({
  normalPrices,
  salePrices,
  clubPrices,
  primePrices,
}) {
  const options = {
    title: {
      text: 'Gráfico de precio promedio en el tiempo',
    },
    series: [{
      data: normalPrices,
      name: 'Precio Normal',
      color: '#5DADE2',
    }, {
      data: salePrices,
      name: 'Precio Oferta',
      color: '#F4D03F',
    }, {
      data: clubPrices,
      name: 'Precio Club',
      color: '#A569BD',
    }, {
      data: primePrices,
      name: 'Precio Prime',
      color: '#E37F00',
    }],
    plotOptions: {
      series: {
        type: 'spline',
        marker: {
          enabled: null, // auto
          radius: 3,
        },
      },
    },
    tooltip: {
      split: false,
      formatter() {
        const s = `<b>${this.series.name}</b>: ${this.y}<br /><b># Skus</b>: ${this.point.count} <br />`;
        return s;
      },
    },
    yAxis: [{
      offset: 20,
      resize: {
        enabled: true,
      },
      labels: {
        formatter() {
          return numberFormat.format(this.value);
        },
        x: -15,
        style: {
          color: '#000',
          position: 'absolute',
        },
        align: 'left',
      },
    }],
    chart: {
      height: 500,
      zoomType: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      minRange: 1,
      labels: {
        format: '{value:%d %b}',
        rotation: -50,
      },
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={'stockChart'}
      options={options}
    />
  );
}

HistoricGraph.propTypes = {
  normalPrices: PropTypes.array,
  salePrices: PropTypes.array,
  clubPrices: PropTypes.array,
  primePrices: PropTypes.array,
};

export default HistoricGraph;
