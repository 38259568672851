import React from 'react';
import { Container } from '@material-ui/core';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import ReportCreateFormComparative from './ReportCreateComparativeForm';

import useStyles from './styles';

function ReportCreateComparativeView() {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}

      title="FH Engel | Crear Reporte"
    >
      <Container maxWidth={false}>
        <Header />
        <ReportCreateFormComparative />
      </Container>
    </Page>
  );
}

export default ReportCreateComparativeView;
