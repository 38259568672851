import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import ProductEditForm from './ProductEditForm';
import { getProductInfo } from '../../../requests/api/products';

import useStyles from './styles';
import { getSubcategoriesWithCategories } from '../../../requests/api/subcategories';

function ProductEditView(props) {
  const classes = useStyles();
  const [product, setProduct] = useState();
  const [subcategories, setSubcategories] = useState();
  const account = useSelector((state) => state.account);

  useEffect(() => {
    const getProduct = async () => {
      const res = await getProductInfo(
        props.match.params.id, account.user.id, account.user.role, true,
      );
      const response = res.data;
      setProduct(response.data);
    };
    getProduct();
  }, [props]);

  useEffect(() => {
    if (product) {
      const getSubcats = async () => {
        const res = await getSubcategoriesWithCategories(product.supermarket_id, 0, 0);
        const response = res.data;
        const subcat = response.data.subcategories;
        setSubcategories(subcat);
      };
      getSubcats();
    }
  }, [product]);

  if (!product || !subcategories) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="FH Engel | Editar Producto"
    >
      <Container maxWidth={false}>
        <Header />
        <ProductEditForm
          subcategories={subcategories}
          product={product}
        />
      </Container>
    </Page>
  );
}

ProductEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(ProductEditView);
