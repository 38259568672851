import React, {
  useState,
  useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Container,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Page from '../../../../components/Layout/Page';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import UserEditForm from './UserEditForm';
import CategoryUserRolesForm from './CategoryUserRolesForm';
import Header from './Header';

import useStyles from './styles';
import alphabeticSort from '../../../../utils/alphabeticSort';

import { getUserDetail } from '../../../../requests/api/users';

// import {
//   setSubcategoryQuery,
//   setSubcategoryCategory,
//   setSubcategoryPage,
//   setSubcategoryLimit,
//   setSubcategorySupermarket,
// } from '../../../../actions/subcategoriesList';

import { getSubcategoriesPerPage } from '../../../../requests/api/subcategories';
import { getAllSupermarkets } from '../../../../requests/api/supermarkets';
import { getAllCategories } from '../../../../requests/api/categories';

function AdminEditView(props) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [user, setUser] = useState();
  const [count, setCount] = useState(0);
  const [subcategories, setSubcategories] = useState([]);
  const [supermarkets, setSupermarkets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [originalCategories, setOriginalCategories] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    subcategoryQuery,
    subcategoryCategory,
    subcategorySupermarket,
    subcategoryPage,
    subcategoryLimit,
  } = useSelector((state) => state.subcategoriesList);

  useEffect(() => {
    const getUser = async () => {
      const res = await getUserDetail(props.match.params.id);
      const response = res.data;
      if (isMountedRef.current) {
        setUser(response.data);
      }
    };

    getUser();
    // eslint-disable-next-line
  }, [props]);
  // const dispatch = useDispatch();

  // const cleanFilters = () => {
  //   dispatch(setSubcategoryPage(1));
  //   dispatch(setSubcategoryLimit(10));
  //   dispatch(setSubcategoryQuery(''));
  //   dispatch(setSubcategorySupermarket([]));
  //   dispatch(setSubcategoryCategory([]));
  // };

  useEffect(() => {
    try {
      const getSupermarkets = async () => {
        const response = await getAllSupermarkets();
        if (response.success) {
          const data = await response?.data?.data;
          setSupermarkets(data.sort((a, b) => alphabeticSort(a, b)));
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getSupermarkets();
    } catch {
      setSupermarkets([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getCategories = async () => {
        const response = await getAllCategories();
        if (response.success) {
          const data = await response?.data?.data;
          setOriginalCategories(data.sort((a, b) => alphabeticSort(a, b)));
          if (subcategorySupermarket.length > 0) {
            const supIdes = subcategorySupermarket.map((val) => val.id);
            setCategories(data.filter(
              (item) => supIdes.includes(item.supermarket_id),
            ).sort(
              (a, b) => alphabeticSort(a, b),
            ));
          } else {
            setCategories(data.sort((a, b) => alphabeticSort(a, b)));
          }
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getCategories();
    } catch {
      setCategories([]);
    }
  }, []);

  useEffect(() => {
    try {
      const getSubcategories = async () => {
        const catIdes = subcategoryCategory.map((val) => val.id);
        const supIdes = subcategorySupermarket.map((val) => val.id);
        const response = await getSubcategoriesPerPage(
          subcategoryPage,
          subcategoryLimit,
          subcategoryQuery,
          supIdes,
          catIdes,
        );
        if (response.success) {
          const data = await response?.data?.data;
          const arrayData = data?.subcategories ? Object.values(data?.subcategories) : null;
          setSubcategories(arrayData);
          setCount(data?.totalSubcategories);
        } else {
          enqueueSnackbar('Error al cargar la información de las categorías.', {
            variant: 'error',
          });
        }
      };
      getSubcategories();
    } catch {
      setSubcategories([]);
    }
  }, [
    subcategoryPage,
    subcategoryLimit,
    subcategoryQuery,
    subcategorySupermarket,
    subcategoryCategory,
  ]);

  if (!user) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="FH Engel | Editar Usuario"
    >
      <Container maxWidth="lg">
        <Header />
        <Box mt={3}>
          <UserEditForm user={user} />
          { user.role === 'external' ? (
            <CategoryUserRolesForm
            user={user}
            subcategories={subcategories}
            supermarkets={supermarkets}
            originalCategories={originalCategories}
            categories={categories}
            setCategories={setCategories}
            count={count}
        />
          ) : (<Box></Box>) }
        </Box>
      </Container>
    </Page>
  );
}

AdminEditView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(AdminEditView);
