import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import categoriesListReducer from './categoriesList';
import productsListReducer from './productsList';
import engelProductsListReducer from './engelProductsList';
import snapsListReducer from './snapsList';
import subcategoriesListReducer from './subcategoriesList';
import engelProductReducer from './engelProductsReducer';
import reportsListReducer from './reportsList';

const rootReducer = combineReducers({
  account: accountReducer,
  categoriesList: categoriesListReducer,
  form: formReducer,
  snapsList: snapsListReducer,
  productsList: productsListReducer,
  engelProductsList: engelProductsListReducer,
  subcategoriesList: subcategoriesListReducer,
  engelProduct: engelProductReducer,
  reportsList: reportsListReducer,
});

export default rootReducer;
